body,.sp-preloader {
    background-color: #FFFFFF;
    color: #252525
}

.sp-preloader>div {
    background: #ec430f
}

.sp-preloader>div:after {
    background: #FFFFFF
}

#sp-top-bar {
    background: #333333;
    color: #AAAAAA
}

#sp-top-bar a {
    color: #AAAAAA
}

#sp-header {
    background: #FFFFFF
}

#sp-menu ul.social-icons a:hover,#sp-menu ul.social-icons a:focus {
    color: #ec430f
}

a {
    color: #ec430f
}

a:hover,a:focus,a:active {
    color: #044CD0
}

.tags>li {
    display: inline-block
}

.tags>li a {
    background: rgba(236, 67, 15, 0.1);
    color: #ec430f
}

.tags>li a:hover {
    background: #044CD0
}

.article-social-share .social-share-icon ul li a {
    color: #252525
}

.article-social-share .social-share-icon ul li a:hover,.article-social-share .social-share-icon ul li a:focus {
    background: #ec430f
}

.pager>li a {
    border: 1px solid #ededed;
    color: #252525
}

.sp-reading-progress-bar {
    background-color: #ec430f
}

.sp-megamenu-parent>li>a {
    color: #252525
}

.sp-megamenu-parent>li:hover>a {
    color: #ec430f
}

.sp-megamenu-parent>li.active>a,.sp-megamenu-parent>li.active:hover>a {
    color: #ec430f
}

.sp-megamenu-parent .sp-dropdown .sp-dropdown-inner {
    background: #FFFFFF
}

.sp-megamenu-parent .sp-dropdown li.sp-menu-item>a {
    color: #252525
}

.sp-megamenu-parent .sp-dropdown li.sp-menu-item>a:hover {
    color: #ec430f
}

.sp-megamenu-parent .sp-dropdown li.sp-menu-item.active>a {
    color: #ec430f
}

.sp-megamenu-parent .sp-mega-group>li>a {
    color: #252525
}

#offcanvas-toggler>.fa {
    color: #252525
}

#offcanvas-toggler>.fa:hover,#offcanvas-toggler>.fa:focus,#offcanvas-toggler>.fa:active {
    color: #ec430f
}

#offcanvas-toggler>.fas {
    color: #252525
}

#offcanvas-toggler>.fas:hover,#offcanvas-toggler>.fas:focus,#offcanvas-toggler>.fas:active {
    color: #ec430f
}

#offcanvas-toggler>.far {
    color: #252525
}

#offcanvas-toggler>.far:hover,#offcanvas-toggler>.far:focus,#offcanvas-toggler>.far:active {
    color: #ec430f
}

.offcanvas-menu {
    background-color: #FFFFFF;
    color: #252525
}

.offcanvas-menu .offcanvas-inner a {
    color: #252525
}

.offcanvas-menu .offcanvas-inner a:hover,.offcanvas-menu .offcanvas-inner a:focus,.offcanvas-menu .offcanvas-inner a:active {
    color: #ec430f
}

.offcanvas-menu .offcanvas-inner ul.menu>li a,.offcanvas-menu .offcanvas-inner ul.menu>li span {
    color: #252525
}

.offcanvas-menu .offcanvas-inner ul.menu>li a:hover,.offcanvas-menu .offcanvas-inner ul.menu>li a:focus,.offcanvas-menu .offcanvas-inner ul.menu>li span:hover,.offcanvas-menu .offcanvas-inner ul.menu>li span:focus {
    color: #ec430f
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>a>.menu-toggler,.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>.menu-separator>.menu-toggler {
    color: rgba(37, 37, 37, 0.5)
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent .menu-toggler {
    color: rgba(37, 37, 37, 0.5)
}

.offcanvas-menu .offcanvas-inner ul.menu>li li a {
    color: rgba(37, 37, 37, 0.8)
}

.btn-primary,.sppb-btn-primary {
    border-color: #ec430f;
    background-color: #ec430f
}

.btn-primary:hover,.sppb-btn-primary:hover {
    border-color: #044CD0;
    background-color: #044CD0
}

ul.social-icons>li a:hover {
    color: #ec430f
}

.sp-page-title {
    background: #ec430f
}

.layout-boxed .body-innerwrapper {
    background: #FFFFFF
}

.sp-module ul>li>a {
    color: #252525
}

.sp-module ul>li>a:hover {
    color: #ec430f
}

.sp-module .latestnews>div>a {
    color: #252525
}

.sp-module .latestnews>div>a:hover {
    color: #ec430f
}

.sp-module .tagscloud .tag-name:hover {
    background: #ec430f
}

.search .btn-toolbar button {
    background: #ec430f
}

#sp-footer,#sp-bottom {
    /* background: #171717; */
    color: #FFFFFF;
}

#sp-footer a,#sp-bottom a {
    color: #A2A2A2
}

#sp-footer a:hover,#sp-footer a:active,#sp-footer a:focus,#sp-bottom a:hover,#sp-bottom a:active,#sp-bottom a:focus {
    color: #FFFFFF
}

#sp-bottom .sp-module-content .latestnews>li>a>span {
    color: #FFFFFF
}

.sp-comingsoon body {
    background-color: #ec430f
}

.pagination>li>a,.pagination>li>span {
    color: #252525
}

.pagination>li>a:hover,.pagination>li>a:focus,.pagination>li>span:hover,.pagination>li>span:focus {
    color: #252525
}

.pagination>.active>a,.pagination>.active>span {
    border-color: #ec430f;
    background-color: #ec430f
}

.pagination>.active>a:hover,.pagination>.active>a:focus,.pagination>.active>span:hover,.pagination>.active>span:focus {
    border-color: #ec430f;
    background-color: #ec430f
}

.error-code,.coming-soon-number {
    color: #ec430f
}
