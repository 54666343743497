@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  background-color: #f7f7ff;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 1.4;
  overflow-x: hidden;
}

button.btn.btn-primary {
  border-radius: 100px;
  margin: 10px 0;
  background: #646edf;
  background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: -webkit-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: linear-gradient(to bottom, #646edf 0%, #46509e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#646edf', endColorstr='#46509e',GradientType=0 );
  border: none;
}
button.btn.btn-secondary {
  border-radius: 100px;
  margin: 10px 0;
  border: none;
  background: #3fadfc;
  background: -moz-linear-gradient(top, #3fadfc 0%, #0979ff 100%);
  background: -webkit-linear-gradient(top, #3fadfc 0%, #0979ff 100%);
  background: linear-gradient(to bottom, #3fadfc 0%, #0979ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3fadfc', endColorstr='#0979ff',GradientType=0 );
}

a {
  text-decoration: none;
  color: #626cda;
  cursor: pointer;
}

img {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  image-rendering: -webkit-optimize-contrast;
}

.h1,
h1 {
  font-size: 2.1rem;
}
.h2,
h2 {
  font-size: 1.6rem;
}

/* --------------title--------------- */
#sp-header {
  background-color: #0c2867;
  color: #ffffff;
  height: 70px;
}
.logo-image {
  height: 66px;
}
#sp-menu a {
  color: #ffffff;
  position: relative;
}
#sp-menu .sp-megamenu-wrapper a {
  line-height: 30px;
}
#sp-menu a.upcoming:after {
  content: "upcoming";
  position: absolute;
  top: calc(50% + 10px);
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 500;
  left: 50%;
  transform: translateX(-50%);
  background-color: #c34a75;
  line-height: 1;
  padding: 3px;
  border-radius: 2px;
}
.burger-icon > span {
  background-color: #fff;
}

#sp-logo {
  position: relative;
  padding: 0 !important;
  width: 180px;
}

#sp-logo .logo {
  position: absolute;
  top: 5px;
  left: 0;
  width: 160px;
  height: auto;
  z-index: 10;
  display: flex;
  /* background-color: #0c2867; */
  border-radius: 50%;
}
.header-sticky #sp-logo .logo {
  width: 120px;
  height: 70px;
}
#sp-logo .logo .logo-image {
  height: 100%;
  width: 100%;
  padding: 10px;
}

/* --------------slider-------------- */
#sp-slider .slick-list {
  margin: 25px 0;
  border-radius: 25px;
}

/* --------------position1------------ */
/* --------------position2------------ */
/* --------------position3------------ */

/* --------------left----------------- */
/* --------------Component------------ */
/* --------------right---------------- */

/* --------------feature-------------- */
/* --------------position4------------ */
/* --------------position5------------ */

/* --------------user1---------------- */
/* --------------user2---------------- */

/* --------------bottom1-------------- */
/* --------------bottom2-------------- */
/* --------------bottom3-------------- */

/* --------------footer1-------------- */
/* --------------footer2-------------- */

/* --------------footer & bottom------ */
#sp-footer,
#sp-bottom {
  background: #0d2a6d;
  background: -moz-linear-gradient(-45deg, #0d2a6d 0%, #0b1f68 100%);
  background: -webkit-linear-gradient(-45deg, #0d2a6d 0%, #0b1f68 100%);
  background: linear-gradient(135deg, #0d2a6d 0%, #0b1f68 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d2a6d', endColorstr='#0b1f68',GradientType=1 );
}
#sp-footer a,
#sp-bottom a {
  color: #a0abff;
}
#sp-footer1 {
  text-align: center;
  font-size: 0.95em;
  opacity: 0.6;
}
#sp-copyright {
  background-color: #031659;
  color: #ffffff;
  padding: 10px 10px 0px;
}
.sp-copyright {
  text-align: center;
  font-size: 0.9em;
  opacity: 0.5;
}

/* --------------custom_popup--------- */
.modal {
  z-index: 10000;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
  }
}
.section-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.game-card-list {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 -15px;
}
.game-card-list .game-card-wrap {
  flex: 1 0 25%;
  max-width: 25%;
  display: flex;
  padding-top: 10%;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 25px;
}
.game-card-list .game-card-wrap img {
  width: 100%;
}
.game-card-list .game-card-wrap .game-card {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 30px;
  flex: 1;
}
.game-card-list .game-card-wrap .game-card .game-wrap {
  display: flex;
  position: relative;
  flex: 1;
}
.game-card-list .game-card-wrap .ga-label img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-bottom: 7px;
}
.game-card-list .game-card-wrap .ga-label span {
  display: none;
}
.game-card-list .game-card-wrap .game {
  display: grid;
  padding: 15px;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 26%;
  flex: 1;
}
.game-card-list .game-card-wrap .game .game-img {
  grid-area: 1/1/2/4;
  margin-bottom: 10px;
  position: absolute;
  width: 45%;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
}
.game-card-list .game-card-wrap .game .game-title {
  grid-area: 2/1/3/4;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.2;
}
.game-card-list .game-card-wrap .game .game-desc {
  grid-area: 3/1/4/4;
  margin-bottom: 10px;
  opacity: 0.85;
  line-height: 1.35;
  font-size: 0.9em;
}
.game-card-list .game-card-wrap .game .ball-count,
.game-card-list .game-card-wrap .game .head-count,
.game-card-list .game-card-wrap .game .tkt-prize,
.game-card-list .game-card-wrap .game .max-prize {
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;
  flex-direction: column;
}
.game-card-list .game-card-wrap .game .ball-count {
  grid-area: 4/1/5/2;
}
.game-card-list .game-card-wrap .game .head-count {
  grid-area: 4/2/5/3;
  display: none;
}
.game-card-list .game-card-wrap .game .tkt-prize {
  grid-area: 4/2/5/3;
}
.game-card-list .game-card-wrap .game .max-prize {
  grid-area: 4/3/5/4;
}
.game-card-list .game-card-wrap .game .game-link {
  grid-area: 5/1/6/4;
}
.game-card-list .game-card-wrap .game .game-link .btn {
  border-radius: 100px;
  min-width: 170px;
  margin: 10px 0;
  /*background: #646edf;
    background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
    background: -webkit-linear-gradient(top, #646edf 0%,#46509e 100%);
    background: linear-gradient(to bottom, #646edf 0%,#46509e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#646edf', endColorstr='#46509e',GradientType=0 );*/
  border: none;
}
.game-card-list .game-card-wrap .game .game-img img {
  border-radius: 50%;
}
.game-card-list-wrap {
  padding-bottom: 30px;
  border-radius: 30px;
}
.game-card-list-wrap.hori-list .game-card-list .game-card-wrap {
  flex: 1 0 50%;
  max-width: 50%;
  padding-top: 0;
}
.game-card-list-wrap.hori-list .game-card-list .game-card-wrap .game {
  padding-top: 15px;
  grid: repeat(4, auto) / 1.9fr 1fr 1fr 1fr;
  text-align: left;
}
.game-card-list-wrap.hori-list .game-card-list .game-card-wrap .game .game-img {
  grid-area: 1/1/6/2;
  transform: none;
  position: static;
  width: auto;
  margin-bottom: 0;
  margin-right: 15px;
}
.game-card-list-wrap.hori-list
  .game-card-list
  .game-card-wrap
  .game
  .game-img
  img {
  border-radius: 25px;
}
.game-card-list-wrap.hori-list
  .game-card-list
  .game-card-wrap
  .game
  .game-title {
  grid-area: 1/2/2/5;
  font-size: 1.5em;
  margin-bottom: 5px;
}
.game-card-list-wrap.hori-list
  .game-card-list
  .game-card-wrap
  .game
  .game-desc {
  grid-area: 2/2/3/5;
  font-size: 1em;
}
.game-card-list-wrap.hori-list
  .game-card-list
  .game-card-wrap
  .game
  .ball-count {
  grid-area: 3/2/4/3;
}
.game-card-list-wrap.hori-list
  .game-card-list
  .game-card-wrap
  .game
  .head-count {
  grid-area: 3/3/4/4;
  display: none;
}
.game-card-list-wrap.hori-list
  .game-card-list
  .game-card-wrap
  .game
  .tkt-prize {
  grid-area: 3/3/4/4;
}
.game-card-list-wrap.hori-list
  .game-card-list
  .game-card-wrap
  .game
  .max-prize {
  grid-area: 3/4/4/5;
}
.game-card-list-wrap.hori-list
  .game-card-list
  .game-card-wrap
  .game
  .game-link {
  grid-area: 4/2/5/5;
}
.game-card-list-wrap.hori-list
  .game-card-list
  .game-card-wrap
  .game
  .game-link
  .btn {
  margin-bottom: 0;
}

button.ctab {
  border: none;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 13px;
  color: #ffffff;
  background: #646edf;
  background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: -webkit-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: linear-gradient(to bottom, #646edf 0%, #46509e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#646edf', endColorstr='#46509e',GradientType=0 );
}
button.ctab.active,
button.ctab.active:hover {
  background: #404040;
  background: -moz-linear-gradient(top, #717171 0%, #404040 100%);
  background: -webkit-linear-gradient(top, #717171 0%, #404040 100%);
  background: linear-gradient(to bottom, #717171 0%, #404040 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#717171', endColorstr='#404040',GradientType=0 );
}

.block4-icons {
  display: grid;
  grid-template: 40% auto / 50% 50%;
  flex: 1;
  padding-top: 15px;
}
.block4-icons > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}
.block4-icons .img1 {
  grid-area: 1/1/2/2;
}
.block4-icons .img2 {
  grid-area: 1/2/2/3;
}
.block4-icons > div:nth-child(-n + 2) {
  border-top: none;
}
.block4-icons .img3 {
  grid-area: 2/1/3/3;
}

.accordion-button {
  font-size: 1.1rem;
}

.pg-wrap {
  text-align: center;
  margin-bottom: 80px;
  margin-top: 20px;
}
.pg-img-wrap img {
  display: inline-block;
  padding: 10px 15px;
  max-height: 56px;
}

#sp-feature {
  background: #0d2a6d;
  background: -moz-linear-gradient(-45deg, #0d2a6d 0%, #0b1f68 100%);
  background: -webkit-linear-gradient(-45deg, #0d2a6d 0%, #0b1f68 100%);
  background: linear-gradient(135deg, #0d2a6d 0%, #0b1f68 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0d2a6d', endColorstr='#0b1f68',GradientType=1 );
  margin: 50px 0;
  color: #ffffff;
}

#sp-main-body {
  padding: 0;
  margin-bottom: 40px;
}

.promo-strip {
  display: flex;
}
.promo-strip .img-wrap1 {
  display: flex;
  align-items: center;
}
.promo-strip .text-wrap {
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  padding: 10px 25px;
}
.promo-strip .img-wrap2 {
  display: flex;
  margin-top: -15px;
}
.promo-strip .text-wrap button.btn {
  max-width: 150px;
}

/* ------------------------------------------------------------------------------------
----------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------ */

:root {
  /** Main Colors **/
  --primary-color: #0c2867;
  --secondary-color: #13abe8;
  --success-color: #4caf50;
  --warning-color: #ffd13e;
  --error-color: #f44336;
  /**Other Colors **/
  --primary-font-color: #252525;
  --secondary-font-color: #717171;
  --border-line: #dddddd;
  --white-color: #ffffff;
  /*Global Fonts*/
}
/* Style Guide CSS End*/

/* .pb35{
	padding-bottom:3.5rem;
} */
.pb20 {
  padding-bottom: 2rem;
}
.mr15 {
  margin-right: 1.5rem;
}
.mt15 {
  margin-top: 1.5rem;
}
.ml15 {
  margin-left: 1.5rem;
}
.mr12 {
  margin-right: 1.2rem;
}

/* ----Table----- */
.table {
  width: 100%;
}
.table thead tr th {
  color: var(--secondary-font-color);
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  padding: 1rem;
  border-bottom: 0.1rem solid var(--border-line);
  vertical-align: middle;
}
.table thead tr th:first-child,
.table tbody tr td:first-child {
  padding-left: 0;
}
.table tbody tr td {
  text-align: left;
  padding: 1rem;
  vertical-align: middle;
  border-bottom: 0.1rem solid var(--border-line);
}
.table thead tr th:last-child,
.table tbody tr td:last-child {
  text-align: right;
  padding-right: 0;
}
.table-heading-label {
  color: var(--primary-font-color);
  font-size: var(--small-font);
}
.table-heading-span {
  display: block;
  color: var(--secondary-font-color);
  font-weight: 400;
}
.table-heading-p {
  color: var(--secondary-font-color);
  font-size: 13px;
}
.status-tag {
  padding: 0.5rem 1rem;
  display: inline-block;
  border-radius: 0.5rem;
  background: var(--secondary-color);
  height: 2.8rem;
  text-transform: lowercase;
  color: var(--secondary-font-color);
  width: 8rem;
  text-align: center;
}
.see-more-row {
  margin-top: 5rem;
  text-align: center;
  width: 100%;
}
.see-more-row .see-more-button {
  background: transparent;
  padding: 1.5rem 4rem;
  border-radius: 5rem;
  font-weight: 700;
  cursor: pointer;
}

.success {
  background-color: var(--success-color);
}
.success-text {
  color: var(--success-color);
}
.warning-text {
  color: var(--warning-color);
}
.error-text {
  color: var(--error-color);
}
.warning {
  background-color: var(--warning-color);
}
.badge {
  padding: 0.4rem 1.2rem 0.3rem;
  text-transform: uppercase;
  vertical-align: middle;
  color: #ffffff;
  font-weight: 700;
  display: inline-block;
}

/* ------pagination------ */

.table-pagination {
  display: inline-flex;
}
.table-pagination li {
  display: flex;
}
.table-pagination li a {
  font-size: 13px;
  color: var(--primary-font-color);
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  border: 0.1rem solid var(--white-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem;
  background-color: var(--primary-color);
}
.table-pagination li a.active {
  background-color: var(--secondary-font-color);
  border-color: var(--secondary-font-color);
}
.table-pagination li a:hover {
  background-color: var(--secondary-color);
}

.my-acc-title.mb-5 {
  margin-bottom: 0 !important;
}
.my-acc-title h1 {
  margin-top: 15px;
}

#sp-component > .sp-column > iframe {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
}

/* ------my-profile------ */

/* Profile Page CSS*/
.my-profile-title h1 {
  color: var(--primary-font-color);
  margin-top: 15px;
}
.my-profile-title p {
  color: var(--primary-font-color);
}
.my-profile-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row-reverse;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  /* min-height: 19rem; */
}
/* .my-profile-user-avatar {
    width: 27.8rem;
    position: relative;
    height: 10rem;
} */
.my-profile-user-details {
  justify-content: space-between;
  flex-direction: column;
  height: 250px;
  display: flex;
  /* width: calc(100% - 27.8rem); */
  padding-right: 3rem;
}
.user-avatar-img {
  position: relative;
  /* bottom: 0; */
  right: 0;
  max-width: 100%;
  /* aspect-ratio: 1/1; */
  object-fit: cover;
  object-position: center center;
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.edit-profile-btn {
  margin-top: 12px;
  background: #0c2867;
  width: 100%;
  max-width: 180px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  cursor: pointer;
  border-radius: 9px;
  padding: 12px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.edit-profile-btn img {
  width: 24px;
  height: 24px;
}
.edit-profile-btn:hover {
  bottom: -7rem;
}
.my-profile-user-info {
  width: 100%;
  margin-bottom: 4rem;
}
.my-profile-user-wallet {
  /* width: 35rem; */
  border-radius: 1rem;
  background-color: #e9e9e9;
  padding: 0.7rem 1.2rem;
  display: flex;
  position: relative;
}
.my-profile-user-balance {
  width: auto;
  display: flex;
  margin-right: 4rem;
  flex-wrap: nowrap;
  align-items: center;
}
.my-profile-balance-icon {
  margin-right: 1rem;
}
.my-profile-balance-icon img {
  vertical-align: middle;
}
.my-profile-user-balance-info h6 {
  color: var(--secondary-font-color);
}
.my-profile-user-balance-info label {
  font-size: 16px;
  /* font-weight: 500; */
  font-weight: 700;
}
.my-profile-user-add-balance,
.my-profile-user-add-balance.primary-gradient {
  width: 90px;
  height: 90px;
  position: absolute;
  right: -1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 4.2rem;
  color: #fff;
  border-radius: 100px;
  margin: 0;
  background: #646edf;
  background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: -webkit-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: linear-gradient(to bottom, #646edf 0%, #46509e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr= '#646edf', endColorstr='#46509e',GradientType=0 );
  border: none;
  min-width: auto;
  font-weight: 400;
}
.my-profile-user-contact {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
}
.my-profile-user-contact button {
  all: unset;
  border: 2px solid #0c2867;
  padding: 5px 10px;
  border-radius: 100px;
  color: #0c2867;
  font-weight: 500;
}
.my-profile-user-contact-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.1rem solid var(--border-line);
  padding: 1.8rem 0;
}
.user-contact-info {
  width: 50%;
  display: flex;
  align-items: center;
}
.user-contact-icon {
  width: 4rem;
  margin-right: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-contact-number {
  width: calc(100% - 5.2rem);
}
.user-contact-number h6 {
  color: var(--secondary-font-color);
}
.user-contact-number label {
  font-size: 16px;
}
.my-profile-user-contact-row:last-child {
  border: 0;
}

/* -------ticket------------ */

.myTicketOuterWrap {
  margin: 10px 0;
  text-align: left;
}
.myTicketInnerWrap {
  color: #ffffff;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
  display: flex;
  border-radius: 6px;
  overflow: hidden;
}
.myTicketInnerWrap > div {
  display: inline-block;
  background-color: #6561b9;
  padding: 10px;
  min-height: 155px;
}
.myTicketInnerWrap .myTicketInnerWrap1 {
  width: 75%;
  vertical-align: top;
  position: relative;
  padding-bottom: 50px;
  text-align: left;
}
.myTicketInnerWrap .myTicketInnerWrap2 {
  width: 25%;
  vertical-align: top;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  border-left: 2px dashed #29275c;
}
.myTicketInnerWrap .ticketGameName {
  font-size: 18px;
  /* font-weight:500; */
  font-weight: 700;
  line-height: 120%;
  color: #ffd13e;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myTicketInnerWrap .transactionIDTitle,
.myTicketInnerWrap .transactionTimeName {
  color: #d9d7ff;
  font-size: 12px;
  font-style: italic;
  margin-bottom: 1px;
  margin-top: 8px;
}
.myTicketInnerWrap .transactionIDNum {
  font-size: 18px;
  margin-bottom: 4px;
}
.myTicketInnerWrap .transactionTimeNum {
  font-size: 14px;
}
.myTicketInnerWrap .ticketResultSymbol,
.myTicketInnerWrap .ticketPrice {
  position: absolute;
  bottom: 10px;
}
.myTicketInnerWrap .ticketResultSymbol {
  left: 10px;
}
.myTicketInnerWrap .ticketPrice {
  right: 10px;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  vertical-align: baseline;
  bottom: 5px;
}
.myTicketInnerWrap .gameTypeIconTic {
  margin-bottom: 5px;
}
.myTicketInnerWrap .gameTypeIconTic img {
  max-height: 100px;
  margin: auto;
}
.myTicketInnerWrap .myTicketInnerWrap1:before,
.myTicketInnerWrap .myTicketInnerWrap1:after {
  content: "";
  display: block;
  width: 19px;
  height: 282px;
  position: absolute;
  top: -1px;
  right: -10px;
  z-index: 5;
  overflow: hidden;
}
.myTicketInnerWrap .myTicketInnerWrap1:after {
  background-position: center bottom;
  z-index: 10;
  height: 16px;
  top: auto;
  bottom: -5px;
}
#tktContainer {
  margin: 15px 0 0;
  padding: 1.6rem 2rem;
  background-color: var(--primary-color);
  border-radius: 1rem;
}

/* -----modal------- */
.modal-header {
  border-bottom: none;
  text-align: center;
  position: relative;
}
.modal-header h4 {
  flex: 1;
  position: relative;
}

.modal-header h4:after {
  content: "";
  width: 4rem;
  position: absolute;
  height: 0.3rem;
  background: var(--warning-color);
  bottom: -1rem;
  left: 50%;
  transform: translate(-50%, 0);
}
.modal-footer {
  border-top: none;
  text-align: center;
  display: flex;
  justify-content: center;
}
.modal-header button.close {
  margin-left: auto;
  color: #ffffff;
  background-color: transparent;
  order: 10;
  position: absolute;
  right: 0;
  z-index: 2;
  border: 0;
}
#error_popup .modal-body {
  padding: 15px;
  text-align: center;
}
.modal-footer button.btn {
  background: hsla(340, 85%, 63%, 1);
  background: linear-gradient(
    270deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  background: -moz-linear-gradient(
    270deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#F15288", endColorstr="#C4463C", GradientType=1 );
  box-shadow: 0 0 3.3rem rgb(241 80 134 / 50%);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 0;
  border-radius: 4.5rem;
  display: inline-flex;
  padding: 1.3rem 2.5rem;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  /* border: 0.1rem solid transparent; */
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary-font-color);
  min-width: 100px;
  justify-content: center;
  margin: 0 10px;
}
.modal-footer button.btn:hover {
  background: hsla(340, 85%, 63%, 1);
  background: linear-gradient(
    90deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  background: -moz-linear-gradient(
    90deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#F15288", endColorstr="#C4463C", GradientType=1 );
  box-shadow: 0 0 3.3rem rgb(241 80 134 / 50%);
  border-color: transparent;
}
#email_verify .modal-body {
  padding: 15px;
}
#email_verify .modal-body {
  padding: 15px;
}
button#continue-btn-email {
  background: hsla(340, 85%, 63%, 1);
  background: linear-gradient(
    270deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  background: -moz-linear-gradient(
    270deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#F15288", endColorstr="#C4463C", GradientType=1 );
  box-shadow: 0 0 3.3rem rgb(241 80 134 / 50%);
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  /* border: 0.1rem solid transparent; */
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary-font-color);
  justify-content: center;
  border-radius: 0.9rem;
  line-height: 1;
  height: 100%;
  padding: 1.2rem 1.5rem;
}
#emailVerificationModal-resend-link {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 0;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  border: 0.1rem solid #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary-font-color);
  justify-content: center;
  border-radius: 0.9rem;
  line-height: 1;
  height: 100%;
  padding: 1rem 1.5rem;
  margin-right: 6px;
}
#email_verify .button_holder {
  align-self: stretch;
  padding: 2px;
}

#add_account_modal .modal-right-signup {
  margin-left: 0;
  width: 100%;
  min-height: auto;
}

/* -----register------ */

.register-full-screen-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0 0 0 / 70%);
}
.register-modal-box {
  width: 100%;
  position: absolute;
  background: var(--secondary-color);
  border: 0.1rem solid #261854;
  box-shadow: 0.2rem 0.2rem 3.2rem rgb(0 0 0 / 80%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  max-width: 79rem;
}
.register-left-banner {
  width: 38rem;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
}
.register-right-signup {
  width: calc(100% - 38rem);
  padding: 5rem 4.2rem 3rem;
  position: relative;
  margin-left: 38rem;
  min-height: 50.3rem;
}
.register-form-wrap {
  max-width: 500px;
  margin: 0 auto;
}
.close-button {
  position: absolute;
  top: 1.8rem;
  right: 1.8rem;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0.6rem;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.close-button img {
  width: 1.6rem;
}
.close-button:hover img {
  opacity: 0.7;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.h2-title {
  text-align: center;
  position: relative;
  font-size: 1.8rem;
  line-height: 2.2rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
}
.h2-title:after {
  content: "";
  width: 4rem;
  position: absolute;
  height: 3px;
  background: #606ad5;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%, 0);
}
.input-group-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 0.1rem solid #dddddd;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  position: relative;
  background: #eeeeee;
  /* overflow: hidden; */
}
input[type="text"]:not(.form-control),
input[type="email"]:not(.form-control),
input[type="url"]:not(.form-control),
input[type="date"]:not(.form-control),
input[type="password"]:not(.form-control),
input[type="search"]:not(.form-control),
input[type="tel"]:not(.form-control),
input[type="number"]:not(.form-control),
select:not(.form-select):not(.form-control) {
  border-radius: 5px;
  font-size: 14px;
}
.form_item_holder input,
.input-group-row input {
  border: none !important;
}
.input-group-left {
  width: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-control {
  width: calc(100% - 6rem);
  padding: 0 15px;
  height: 40px;
  background: transparent;
  border: 0;
  color: var(--primary-font-color);
  font-size: 13px;
}
.input-group-left span {
  color: #5b5989;
  font-size: 13px;
  font-weight: 600;
}
.input-group-left img {
  width: 1.6rem;
}
.input-control::placeholder {
  font-weight: 600;
  color: #686697;
  font-size: 13px;
}
.input-group-left + input {
  border-radius: 0 5px 5px 0 !important;
}
.password-show-hide {
  position: absolute;
  right: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  top: 0;
  padding: 0.6rem;
  height: 100%;
}
.password-show-hide:hover {
  opacity: 0.8;
}
.registration-helping-text {
  text-align: center;
  width: 100%;
}
.registration-helping-text p {
  font-size: 1.1rem;
  line-height: 1.5;
}
.registration-helping-text p a {
  text-decoration: none;
}
.proceed-button {
  padding: 0 20px;
  min-width: 150px;
  border-radius: 2.5rem;
  border: 0;
  color: var(--primary-font-color);
  font-size: 13px;
  text-transform: uppercase;
  /* font-weight: 500; */
  font-weight: 700;
  cursor: pointer;
  height: 50px;
  background: #646edf;
  background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: -webkit-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: linear-gradient(to bottom, #646edf 0%, #46509e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#646edf', endColorstr='#46509e',GradientType=0 );
  border: none;
  color: #ffffff;
}
.new-account-link {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
/* .new-account-link span {
    font-size: 13px;
} */
/* .new-account-link span a{
	color:var(--warning-color);
} */
.register-left-banner img {
  vertical-align: top;
}
.verify-input-group {
  width: 100%;
  display: flex;
  justify-content: center;
}
.verify-input-group .verify-input {
  width: 4.8rem;
  border: 1px solid #6561b9;
  border-radius: 1rem;
  background: #383671;
  display: inline-block;
  font-size: 2.4rem;
  line-height: 2rem;
  text-align: center;
  font-weight: 400;
  margin: 0 0.3rem 0 0.3rem;
  box-shadow: inset 0px 3px 6px 0px rgb(14 7 41 / 35%);
}
.verify-input-group {
  max-width: 180px;
  margin: 0 auto;
}
.verify-helping-text p {
  font-size: 13px;
  line-height: 1.8rem;
  font-weight: 400;
  margin-bottom: 0;
}
.verify-helping-text {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  /* padding-top: 2rem; */
}
.verify-helping-text .number-span {
  font-size: 1.15em;
  font-weight: 500;
}

.resendOtpLink {
  text-align: center;
}
#resendMsg {
  color: #8bc34a !important;
  margin-bottom: 10px;
  margin-top: 0px;
}
.number-span {
  display: inline-flex;
  font-size: 13px;
  line-height: 1.8rem;
  align-items: flex-end;
}
.number-edit-icon {
  border: 0;
  background: transparent;
  padding: 0 0.6rem;
  cursor: pointer;
  margin-left: 0.5rem;
}
.verify-button {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  padding-bottom: 20px;
}
.verify-button .btn.btnDefault {
  padding: 0 20px;
  min-width: 150px;
  border-radius: 2.5rem;
  border: 0;
  color: var(--primary-font-color);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  height: 50px;
  background: #646edf;
  background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: -webkit-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: linear-gradient(to bottom, #646edf 0%, #46509e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#646edf', endColorstr='#46509e',GradientType=0 );
  border: none;
  color: #ffffff;
}

#otp-registration-form-1 {
  text-align: center;
}

#otp-registration-form-1 .verify-input-group-error {
  margin-top: 15px;
  margin-bottom: -15px;
  color: #ff3e3e;
  font-size: 0.85em !important;
}

.modal-left-banner {
  display: none;
}

.close-button {
  position: absolute;
  top: -12px;
  right: -14px;
  border: 0;
  background: #000000;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.36);
  border: 2px solid #ffffff;
  /* background-color: #; */
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0.75;
}
.close-button:hover {
  opacity: 1;
}
/* -----balance--------- */

.sub-title {
  font-size: var(--small-font);
  letter-spacing: 0.1rem;
  color: var(--primary-font-color);
}
.balance-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.balance-col {
  width: calc(50% - 1rem);
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.amount-balance {
  margin-right: 1rem;
}
.amount-bonus {
  margin-left: 1rem;
}
.balance-price-box {
  width: 50%;
}
.balance-deposit-box {
  width: 50%;
  text-align: right;
}
.balance-deposit-button {
  border: 0;
  border-radius: 4.5rem;
  display: inline-flex;
  padding: 0.4rem 3.5rem 0.4rem 0.4rem;
  align-items: center;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--primary-font-color);
  height: 5.6rem;
}
.balance-deposit-button img {
  margin-right: 0.8rem;
}
.balance-subline-span {
  font-size: var(--small-font);
  letter-spacing: 1px;
}
.view-offers-link {
  height: 5.6rem;
  display: inline-flex;
  padding: 1.2rem 3rem 1rem;
  border-radius: 4.5rem;
  text-transform: uppercase;
  align-items: center;
}
.withdrawal-balance-row {
  /* width: 100%; */
  width: calc(50% - 1rem);
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.balance-history-row {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}
.balance-history-table {
  width: 100%;
}
/***Table div CSS***/
.balance-table-container {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.tableWrap .tableBody .tableRow .labelName {
  display: none;
}

.tableWrap .tableRow {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.tableWrap .tableHead .col.col1 {
  padding-left: 0;
}

.tableHead .col.col5 .labelName {
  text-align: right;
}
.tableBody .col.col5 .table-inner-data {
  text-align: right;
}
.tableWrap .tableHead .tableRow .col .labelName {
  color: var(--secondary-font-color);
  font-size: 14px;
  font-weight: 600;
}
.tableWrap .col {
  color: var(--secondary-font-color);
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding: 1rem;
  border-bottom: 0.1rem solid var(--border-line);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.tableBody .col.col1 {
  padding-left: 0;
}
.tableWrap .tableHead .col.col5 {
  display: flex;
  justify-content: flex-end;
}
.tableWrap .tableBody .col.col5 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.col.col1 {
  width: 15%;
}
.col.col2 {
  width: 25%;
}
.col.col3 {
  width: 30%;
}
.col.col4 {
  width: 15%;
}
.col.col5 {
  width: 15%;
}
.tableWrap {
  width: 100%;
  margin-top: 2rem;
}

.customMessageBoxWrap {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  line-height: 1.6;
  margin-top: 15px;
  border-radius: 1rem;
}

/* ------my-transactions--------- */

.transaction-date-row {
  width: 100%;
  position: relative;
  padding: 20px 20px 0;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.transaction-date-range-tabs {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 0.1rem solid var(--border-line);
}
.date-range-tab {
  color: var(--primary-font-color);
  font-size: var(--small-font);
  font-weight: 400;
  background: transparent;
  cursor: pointer;
  border: 0;
  padding: 1.5rem 1rem;
  margin-right: 2.2rem;
  position: relative;
}
.date-range-tab:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5rem;
  background-color: transparent;
}
.date-range-tab.active:after {
  content: "";
  background-color: #2896fe;
  border-radius: 0.5rem 0.5rem 0 0;
}
.date-range-tab.active {
  color: #2896fe;
}
.transaction-date-range-view {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-top: 3rem;
  padding-bottom: 1.2rem;
}
.transaction-date-range-view form {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.range-date-picker {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.transaction-range-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.transaction-range-buttons button {
  height: 42px;
}
.date-label {
  color: var(--primary-font-color);
  font-size: var(--small-font);
}
.date-picker {
  background-color: #383671;
  color: var(--white-color);
  border: 0.1rem solid #6561b9;
  padding: 1.5rem 2.2rem;
  border-radius: 1rem;
  margin: 0 0 0 2rem;
  height: 5rem;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  /* font-weight: 500; */
  font-weight: 700;
  font-family: var(--font-family);
  background-repeat: no-repeat;
  background-position: center right 1rem;
  min-width: 21rem;
}
.range-search-button {
  border: 0;
  border-radius: 4.5rem;
  padding: 0;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--primary-font-color);
  height: 50px;
  min-width: 110px;
  text-align: center;
  margin: 0 5px;
  background: #646edf;
  background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: -webkit-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: linear-gradient(to bottom, #646edf 0%, #46509e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#646edf', endColorstr='#46509e',GradientType=0 );
  border: none;
  color: #ffffff;
}
.range-reset-button {
  border-radius: 4.5rem;
  padding: 0;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  color: #0c2867;
  height: 50px;
  min-width: 100px;
  margin: 0 5px;
  text-align: center;
  border: 2px solid #0c2867;
  background-color: #ffffff;
}

/* -------limits---------- */

.deposit-limit-row {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.deposit-limit-head {
  width: 100%;
  display: flex;
  border-bottom: 0.1rem solid var(--border-line);
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  align-items: center;
}
.deposit-limit-title-icon {
  width: 3rem;
  height: 3rem;
}
.deposit-limit-title-amount {
  width: calc(100% - 26rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.deposit-limit-title-text {
  color: var(--primary-font-color);
  width: 26rem;
  font-size: 2.6rem;
  line-height: 1.8rem;
}
.deposit-limit-title-amount label {
  color: var(--primary-font-color);
  font-size: var(--small-font);
  line-height: 1.8rem;
}
.deposit-limit-title-amount label span {
  margin-left: 0.8rem;
  color: var(--secondary-font-color);
  line-height: 1.8rem;
  font-size: var(--small-font);
}
.deposit-setting-button {
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
}
.deposit-setting-button .setting-icon {
  display: none;
}
.deposit-setting-button .setting-icon.active {
  display: block;
}
.deposit-limit-body {
  width: 100%;
  display: flex;
  padding: 3rem 0 1rem;
}
.deposit-limit-switch-box {
  width: 26rem;
  padding-right: 2rem;
}
.deposit-switch-button {
  width: 100%;
}
.switch-button {
  width: 100%;
  position: relative;
}
.switch-button label {
  width: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  height: 5rem;
  border-radius: 1rem;
  background-color: var(--secondary-color);
  border: 0.1rem solid #6561b9;
}
.switch-button input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.switch-button label:before {
  content: "Per Day";
  position: absolute;
  top: 0.4rem;
  font-size: 13px;
  text-align: center;
  line-height: 1;
  border-radius: 0.7rem;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
  padding: 1.4rem 1.2rem;
  width: calc(50% - 0.6rem);
  color: var(--primary-font-color);
  left: 0.4rem;
  height: 4rem;
  background-color: #383671;
}
.switch-button label:after {
  content: "Per Month";
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
  position: absolute;
  top: 0.4rem;
  font-size: 13px;
  text-align: center;
  line-height: 1;
  border-radius: 0.7rem;
  -webkit-transition: 0.3s ease all;
  -o-transition: 0.3s ease all;
  transition: 0.3s ease all;
  padding: 1.4rem 0.6rem;
  width: calc(50% - 0.6rem);
  color: var(--primary-font-color);
  right: 0.4rem;
  height: 4rem;
}
.switch-button input:checked + label:before {
  content: "Per Day";
  background: transparent;
}
.switch-button input:checked + label:after {
  content: "Per Month";
  background-color: #383671;
}
.deposit-input-field {
  background-color: #383671;
  border: 0.1rem solid #6561b9;
  padding: 0rem 2.2rem;
  border-radius: 1rem;
  height: 5rem;
  width: 24rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.deposit-input-field input {
  padding: 1.3rem;
  color: var(--white-color);
  text-transform: uppercase;
  font-size: 13px;
  /* font-weight: 500; */
  font-weight: 700;
  font-family: var(--font-family);
  border: 0;
  background: transparent;
  outline: none;
}
.deposit-input-amount {
  display: flex;
  width: calc(100% - 26rem);
}

/* ------notifications------ */

.back-button {
  width: 10.8rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications-container {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.notifications-head-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.6rem;
}
.back-button img {
  margin-right: 1.5rem;
}
.delete-button {
  background: transparent;
  cursor: pointer;
  padding: 0;
  text-align: center;
  border: 0.1rem solid var(--white-color);
  border-radius: 50%;
  width: 4.2rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notifications-detail-descriptions h5 {
  font-size: var(--small-font);
  margin-bottom: 1rem;
}
.notifications-detail-descriptions h5 strong {
  /* font-weight:500; */
  font-weight: 700;
}
.notifications-detail-descriptions {
  width: 100%;
  margin-top: 4rem;
}
.notifications-detail-descriptions p {
  color: var(--secondary-font-color);
  margin-bottom: 1rem;
}
.notifications-description-banner {
  width: 100%;
  padding: 1rem 0;
}
/****Latest Notifications CSS*****/
.latest-notifications-row {
  width: 100%;
}
.latest-notifications-row h4 {
  width: 100%;
}
.latest-notifications-tabs {
  border-bottom: 0.1rem solid var(--border-line);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.8rem 0;
}
.latest-notifications-table {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.delete-row {
  border: 0;
  background: transparent;
  padding: 0.3rem;
  cursor: pointer;
}
.notifications-table-checkbox {
  padding: 1rem;
  width: 4.4rem;
  position: relative;
  display: flex;
  height: 4.4rem;
}
.notifications-table-checkbox input {
  display: none;
}
.notifications-table-checkbox label {
  position: relative;
  cursor: pointer;
}
.notifications-table-checkbox label:before {
  content: "";
  position: absolute;
  width: 2.4rem;
  height: 2.4rem;
  border: 0.2rem solid var(--secondary-font-color);
  border-radius: 0.2rem;
}
.notifications-table-checkbox input:checked + label:before {
  content: "";
  border: 0.2rem solid var(--white-color);
  background-position: center;
  background-size: 1.4rem;
  background-repeat: no-repeat;
}
.latest-notifications--table-row {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  border-bottom: 0.1rem solid var(--border-line);
}
.notifications-date {
  margin: 0 0 0 3rem;
  width: calc(14rem);
}
.notifications-details {
  margin: 0;
  width: calc(100% - 17rem);
  padding-right: 4rem;
}
.notifications-delete {
  position: absolute;
  right: 0;
}
.notifications-setting-outer-btn {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: relative;
}
.notifications-setting-btn {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: relative;
}
.notifications-setting-sublist {
  position: absolute;
  width: 16rem;
  right: 0;
  top: 40px;
  background: var(--secondary-color);
  border-radius: 0.3rem;
  z-index: 1;
  display: none;
}
.notifications-setting-sublist li {
  border-bottom: 0.1rem solid var(--border-line);
  padding: 0.6rem 1.2rem;
  text-align: right;
}
.notifications-setting-sublist li a {
  color: var(--secondary-font-color);
  font-size: 13px;
}
.notifications-setting-sublist li a.active {
  color: var(--primary-font-color);
}
.notifications-setting-sublist:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 6px solid #ff000000;
  border-bottom: 6px solid var(--secondary-color);
  border-left: 6px solid #ff000000;
  top: -12px;
  right: 13px;
}
.notifications-setting-btn:focus + .notifications-setting-sublist {
  display: block;
}

/* -----withdrawal------ */

.wallTxn-full-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.wallTxn-modal-box {
  max-width: 79rem;
  border: 0.1rem solid #261854;
  box-shadow: 0.2rem 0.2rem 3.2rem rgb(0 0 0 / 50%);
  border-radius: 1rem;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 5rem 4rem;
  width: 90%;
}
.modal-close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  border: 0;
  background: transparent;
  width: 1.8rem;
  padding: 0.2rem;
  cursor: pointer;
}
.wallTxn-title {
  text-align: center;
  text-transform: uppercase;
  position: relative;
  color: var(--primary-font-color);
}
.wallTxn-title:after {
  content: "";
  width: 4rem;
  height: 0.3rem;
  background: var(--warning-color);
  position: absolute;
  bottom: -0.5rem;
  transform: translate(-50%, -50%);
  left: 50%;
}
.wallTxn-subtitle-text {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}
.wallTxn-subtitle-text p {
  font-size: 13px;
  color: var(--primary-font-color);
}
.wallTxn-subtitle-text p strong {
  color: var(--warning-color);
  margin: 0 0.2rem;
}
.refresh-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
.wallTxn-select-amount {
  width: 100%;
  margin-top: 2.5rem;
}
.wallTxn-select-amount h4,
.payment-method-select-row h4 {
  font-size: var(--small-font);
  font-weight: 600;
}
.wallTxn-amount-group {
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
}
.amount-group-left {
  display: flex;
  justify-content: flex-start;
  position: relative;
  width: 56%;
}
.wallTxn-amount-input-field {
  background-color: var(--secondary-color);
  color: var(--white-color);
  border: 0.1rem solid #6561b9;
  padding: 1.5rem 2.2rem;
  border-radius: 1rem;
  height: 5rem;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--font-family);
  width: calc(33% - 1rem);
  margin-right: 1rem;
  cursor: pointer;
}
.wallTxn-amount-input-field.active,
.wallTxn-amount-input-field:hover {
  background-color: #383671;
}
.mini-text {
  position: absolute;
  color: var(--secondary-font-color);
  bottom: -25px;
  left: 0;
}
.max-text {
  position: absolute;
  color: var(--secondary-font-color);
  bottom: -25px;
  right: 0;
}
.arrow-img {
  width: 6rem;
  text-align: center;
}
.amount-group-right {
  margin-left: 1rem;
  width: calc(44% - 6rem);
  position: relative;
}
.wallTxn-amount-group input.wallTxn-amount-input-field-max[type="text"] {
  width: 100%;
  color: var(--white-color);
  border: 0.1rem solid #6561b9;
  padding: 1.5rem 2.2rem 1.5rem 6rem;
  border-radius: 1rem;
  height: 5rem;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-family: var(--font-family);
}
.wallTxn-amount-group input.wallTxn-amount-input-field-max[type="text"].active,
.wallTxn-amount-group input.wallTxn-amount-input-field-max[type="text"]:active,
.wallTxn-amount-group input.wallTxn-amount-input-field-max[type="text"]:focus {
  background-color: #383671;
}
.dollar-icon {
  color: #8785c8;
  top: 1.4rem;
  left: 2rem;
  position: absolute;
  font-size: 13px;
  font-weight: 600;
}
.payment-method-select-row {
  width: 100%;
  margin-top: 6rem;
}
.payment-method-radio-col {
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
  align-items: flex-start;
}
.payment-radio-button {
  width: 12.4rem;
  height: 10rem;
  border-radius: 1rem;
  border: 0.1rem solid #6561b9;
  position: relative;
  margin-right: 1rem;
  cursor: pointer;
}
.payment-radio-button label {
  position: absolute;
  width: calc(100% - 0.8rem);
  height: calc(100% - 0.8rem);
  left: 0.4rem;
  top: 0.4rem;
  border-radius: 1rem;
  border: 1px solid transparent;
  background: transparent;
  text-align: center;
  padding: 1rem;
  padding-top: 2.7rem;
  cursor: pointer;
}
.payment-radio-button label img {
  margin: 0 auto;
}
.payment-radio-button input:checked + label {
  background: #383671;
  border: 1px solid #6561b9;
}
.payment-radio-button label span {
  color: #bebebe;
  position: absolute;
  bottom: 0.2rem;
  left: 0;
  width: 100%;
  text-align: center;
}
.payment-radio-button input {
  position: absolute;
  /* display: none; */
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.payment-radio-button input:disabled ~ label img {
  filter: grayscale(1);
}
.radio-circle {
  width: 1.2rem;
  height: 1.2rem;
  border: 0.1rem solid #777695;
  border-radius: 50%;
  top: 1rem;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.payment-radio-button input:checked + label .radio-circle:after,
.payment-radio-button input:checked + .radio-circle:after {
  position: absolute;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  border-radius: 50%;
  background-color: #ffffff;
  content: "";
  opacity: 0.65;
}
.proceed-now-button {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2.5rem;
}
.proceed-now-button p {
  color: var(--secondary-font-color);
  margin-right: 6rem;
  line-height: 2rem;
}

/* -----responsive------ */
@media screen and (max-width: 991px) {
  .wallTxn-full-screen {
    position: relative;
    overflow: auto;
  }
  .wallTxn-modal-box {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    border: 0;
    border-radius: 0;
    padding: 5rem 3rem 3rem;
  }
  .wallTxn-amount-group {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .arrow-img {
    width: 100%;
    padding: 1.5rem 0;
  }
  .arrow-img img {
    transform: rotate(90deg);
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 10px;
  }
  .mini-text {
    left: inherit;
    right: 0;
  }
  .amount-group-left {
    width: 100%;
  }
  .amount-group-right {
    width: 100%;
    margin: 0;
  }
  .wallTxn-amount-input-field.mr0 {
    margin: 0;
  }
  .wallTxn-amount-input-field {
    width: calc(33% - 0.5rem);
    padding: 1.5rem;
  }
  .wallTxn-subtitle-text {
    margin-top: 3rem;
  }
  .payment-method-radio-col {
    flex-wrap: wrap;
  }
  .payment-radio-button {
    width: calc(50% - 1rem);
    margin: 0 0.5rem 1rem;
  }
  .payment-method-select-row {
    margin-top: 5rem;
  }
  .proceed-now-button {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .proceed-now-button p {
    margin: 0;
    padding: 0 0 2rem;
    text-align: center;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 767px) {
  /****My Profile CSS****/

  .my-profile-details {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .my-profile-user-avatar {
    position: relative;
    height: auto;
    width: 18rem;
  }
  .user-avatar-img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }
  .edit-profile-btn {
    bottom: 1.5rem;
    right: 0.5rem;
  }
  .my-profile-user-details {
    width: 100%;
    padding: 0;
    justify-content: space-evenly;
  }
  .my-profile-user-info {
    text-align: center;
    margin: 2rem 0;
  }
  .my-profile-user-wallet {
    width: 100%;
    padding-right: 5rem;
    flex-direction: column;
  }
  .my-profile-user-wallet {
    min-width: auto !important;
    white-space: nowrap;
  }
  .my-profile-user-balance {
    width: calc(100% - 1rem);
    margin-right: 2rem;
  }
  .my-profile-user-add-balance {
    top: -3px;
    position: absolute;
    width: 6.5rem;
    height: 6.5rem;
    font-size: 4.5rem;
    right: -1.1rem;
  }
  .my-profile-user-contact {
    padding: 2rem;
  }
  .my-profile-user-contact-row {
    flex-wrap: wrap;
  }
  .user-contact-info {
    width: 100%;
  }
  .my-profile-user-contact-row {
    display: flex;
    flex-wrap: wrap;
    padding: 1.2rem 0;
  }
  .user-contact-info {
    width: 100%;
  }
  .my-profile-user-links {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  .user-contact-number label {
    font-size: var(--small-font);
  }
  .user-contact-icon {
    width: 3rem;
  }
  .user-contact-number {
    width: calc(100% - 4.2rem);
  }
  /****My Profile CSS****/
  /****Register CSS****/
  .register-modal-box {
    position: relative;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    max-width: 100%;
    padding: 0;
    height: 100%;
    border: 0;
    overflow: auto;
    border: 0;
    border-radius: 0;
  }
  .register-left-banner {
    display: none;
  }
  .register-right-signup {
    width: 100%;
    margin: 0;
    padding: 6rem 3rem 3rem;
  }
  /****Register CSS****/
  /****Table CSS****/
  .balance-row {
    display: flex;
    flex-direction: column;
  }
  .balance-col {
    width: 100%;
    margin: 0 0 2rem 0;
    padding: 2rem;
  }
  .withdrawal-balance-row {
    width: 100%;
    margin: 0 0 2rem 0;
    padding: 2rem;
  }
  .balance-history-row {
    padding: 2rem;
  }
  .balance-subline-span {
    font-size: 13px;
  }
  .balance-history-table {
    overflow: hidden;
    overflow-x: auto;
  }
  .table-inner-data {
    min-width: 6rem;
  }
  .tableWrap .tableHead .tableRow {
    display: none;
  }

  .tableWrap .tableBody .tableRow .labelName {
    display: block;
    width: 100%;
    font-size: 11px;
    line-height: 11px;
    opacity: 0.6;
  }

  .tableWrap .tableBody .tableRow {
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
    border: 0.1rem solid #0e0729;
    border-bottom: 0;
  }
  .tableWrap .tableBody .tableRow:last-child {
    border-bottom: 0.1rem solid #0e0729;
  }
  .tableWrap .tableBody .tableRow .col {
    flex-basis: calc(100% - 90px);
    /* flex-grow: 0; */
    text-align: left;
    border-bottom: 0.1rem solid var(--border-line);
    /* min-width: 0;
		max-width: none; */
    /* margin-left: 90px; */
  }

  .tableWrap .tableBody .tableRow .col.col1 {
    background-color: #e4e4e4;
    position: absolute;
    left: 0;
    top: 0;
    flex-basis: 90px;
    width: 90px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 0.1rem solid var(--border-line);
    margin-left: 0;
    border-bottom: 0;
  }

  .tableWrap .col1 {
    word-break: break-word;
  }

  .tableWrap .tableBody .tableRow .col.col5 {
    border-bottom: 0;
  }
  .tableWrap .tableBody .col.col5 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .tableWrap {
    margin-top: 1.5rem;
  }
  .tableBody .col.col5 .table-inner-data {
    text-align: left;
  }
  .see-more-row {
    margin-top: 3rem;
    text-align: center;
    width: 100%;
    margin-bottom: 2rem;
  }
  .table-heading-label {
    font-size: 13px;
  }
  /****Table CSS****/
  /****My Transactions CSS****/
  .transaction-date-range-tabs {
    overflow: auto;
  }
  .date-range-tab {
    white-space: pre;
    margin-right: 1.2rem;
  }
  .transaction-date-row {
    padding: 2rem;
  }
  .transaction-date-range-view {
    align-items: flex-start;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 1.2rem;
  }
  .transaction-date-range-view form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .range-date-picker {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
  }
  .range-date-picker .date-picker {
    width: 100%;
    margin: 1rem 0;
  }
  .transaction-range-buttons {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  .range-search-button {
    margin: 1rem 0;
    width: 100%;
  }
  .range-reset-button {
    width: 100%;
    margin: 1rem 0 0;
  }
  .transaction-title span {
    display: block;
  }
  /****Limits CSS****/
  .deposit-limit-head {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .deposit-limit-title-amount {
    width: 100%;
    padding-top: 2rem;
  }
  .deposit-limit-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 0 1rem;
  }
  .deposit-input-amount {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .deposit-limit-row {
    padding: 2rem;
  }
  .deposit-limit-title-text {
    font-size: 18px;
  }
  .deposit-limit-switch-box {
    width: 100%;
    padding: 0;
    margin-bottom: 1rem;
  }
  .deposit-input-field {
    width: 100%;
    margin: 1rem 0;
  }
  /* Notifications CSS*/
  .notifications-container {
    padding: 2rem;
  }
  .notifications-table-tabs {
    width: calc(100% - 3rem);
    overflow: auto;
    display: flex;
    margin-right: 2rem;
  }
  .latest-notifications--table-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
  .notifications-table-checkbox {
    position: absolute;
    flex-basis: 5rem;
    width: 5rem;
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
    left: 0;
  }
  .notifications-date {
    flex-basis: calc(100% - 5rem);
    flex-grow: 0;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-left: 5rem;
    margin-bottom: 0.5rem;
  }
  .notifications-details {
    flex-basis: calc(100% - 5rem);
    flex-grow: 0;
    text-align: left;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin-left: 5rem;
    flex-direction: column;
  }
  .notifications-date .table-heading-span {
    margin-left: 0.6rem;
  }
  .notifications-table-checkbox label {
    position: absolute;
    cursor: pointer;
    top: 13px;
    left: 0;
  }
}

/*  Medium devices (tablets, 768px and up)*/

@media screen and (max-width: 991px) and (min-width: 768px) {
  /****My Profile CSS****/
  .my-profile-details {
    padding: 3rem;
  }
  .my-profile-user-avatar {
    width: 20rem;
  }

  /****My Profile CSS****/
  /****Register CSS****/
  .register-modal-box {
    width: 90%;
  }
  .register-left-banner {
    width: 30rem;
  }
  .register-right-signup {
    width: calc(100% - 30rem);
    margin-left: 30rem;
  }
  /****Register CSS****/
  /****Table CSS****/
  .view-offers-link {
    padding: 1.2rem 2rem 1rem;
  }
  .balance-col {
    padding: 3rem;
  }
  .withdrawal-balance-row {
    padding: 3rem;
  }
  .balance-history-row {
    padding: 3rem;
  }
  /****Table CSS****/
  /****My Transactions CSS****/
  .transaction-date-row {
    padding: 3rem;
  }
  .transaction-date-range-view form {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
  }
  .range-date-picker {
    width: 56%;
  }
  .transaction-range-buttons {
    width: 44%;
  }
  .range-date-picker .date-picker {
    width: calc(50% - 1rem);
    margin: 0 2rem 0 0;
    min-width: auto;
  }
  .range-date-picker .date-picker:last-child {
    margin: 0;
  }
  .transaction-date-range-view {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-top: 3rem;
    padding-bottom: 1.2rem;
  }
  .range-search-button {
    min-width: calc(50% - 2.5rem);
    margin: 0 2rem 0 3rem;
  }
  .range-reset-button {
    min-width: calc(50% - 2.5rem);
  }
  .transaction-date-range-tabs {
    justify-content: space-between;
  }
  .date-range-tab {
    padding: 1.5rem 1.5rem;
    margin-right: 2rem;
    font-size: 13px;
  }
  .date-label {
    margin-bottom: 1rem;
  }
  /****Limits CSS****/
  .deposit-limit-row {
    padding: 3rem;
  }
  .deposit-limit-title-text {
    width: 20rem;
    font-size: 17px;
    line-height: 1.8rem;
  }
  .deposit-limit-title-amount {
    width: calc(100% - 22rem);
  }
  .deposit-limit-switch-box,
  .deposit-input-field {
    width: 20rem;
  }
  .deposit-input-amount {
    width: calc(100% - 20rem);
  }
  .deposit-input-amount .transaction-range-buttons {
    width: calc(100% - 20rem);
  }
  /* Notifications CSS*/
  .notifications-container {
    padding: 3rem;
  }
  .app-icon-wrap .app-icon-list {
    justify-content: flex-start !important;
  }
}
/*  Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
}

/*  Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
}

/* ***********************************
*************************************
************************************ */

.img-msg-wrap {
  text-align: center;
}
.img-msg-wrap .svg-wrap svg,
.img-msg-wrap .svg-wrap img {
  max-width: 280px;
  margin: 0 auto 15px;
}

.my-profile-user-balance {
  margin-right: 40px;
}
.my-profile-user-balance + .my-profile-user-balance {
  display: none;
}
.my-profile-user-add-balance {
  line-height: 1;
}

.my-profile-user-wallet {
  /* min-width: 35rem;
    width:auto; */
  display: inline-flex;
  justify-content: space-between;
}
.offcanvas-menu .offcanvas-inner .sp-module .sp-module-title {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  opacity: 0.6;
  margin-top: 30px;
  display: block;
}

.sp-pre-loader {
  background: #f7f7ff;
}
.sp-pre-loader .sp-loader-with-logo .line {
  background: var(--warning-color);
}
.primary-gradient {
  transition: all 0.2s ease-in-out;
  padding: 0 20px;
  min-width: 150px;
  border-radius: 2.5rem;
  border: 0;
  color: var(--primary-font-color);
  font-size: 13px;
  text-transform: uppercase;
  /* font-weight: 500; */
  font-weight: 700;
  cursor: pointer;
  height: 50px;
  background: #646edf;
  background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: -webkit-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: linear-gradient(to bottom, #646edf 0%, #46509e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#646edf', endColorstr='#46509e',GradientType=0 );
  border: none;
  color: #ffffff;
}

.loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
.loading {
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border-radius: 50%;
  border-top: 10px solid #0c2867;
  position: relative;
  animation: a1 2s linear infinite;
}

.loading::before,
.loading::after {
  content: "";
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: -10px;
  box-sizing: border-box;
  border-radius: 50%;
}

.loading::before {
  border-top: 10px solid #11b6f0;
  transform: rotate(120deg);
}

.loading::after {
  border-top: 10px solid #eb35a3;
  transform: rotate(240deg);
}

.loading .txt {
  position: absolute;
  width: 100px;
  height: 100px;
  color: #fff;
  text-align: center;
  animation: a2 2s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0;
}
.loading .txt span {
  position: relative;
  line-height: 1;
}
.loading .txt span:after {
  content: " ...";
  animation: a3 3s linear infinite;
  position: absolute;
  left: calc(100% + 5px);
  top: 1px;
}

@keyframes a1 {
  to {
    transform: rotate(360deg);
  }
}
@keyframes a2 {
  to {
    transform: rotate(-360deg);
  }
}
@keyframes a3 {
  0% {
    content: ".";
  }
  50% {
    content: "..";
  }
  100% {
    content: "...";
  }
}

#sp-menu .loginBtn.btn {
  border: 2px solid #ffffff;
  min-height: 40px;
  min-width: 140px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.2),
    1px 1px 0px rgba(255, 255, 255, 0.2);
}

#sp-position2 .rownav-tabs-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 58px;
  z-index: 10;
  background-color: #0e0729;
  overflow-x: auto;
  white-space: nowrap;
  margin: 1rem 0 1rem;
}

.page-view-row {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
}

.rownav-tabs-wrapper {
  position: relative;
  padding: 0;
}
.rownav-tabs-wrapper .slick-arrow {
  position: absolute;
  z-index: 20;
  width: 25px;
  height: 100%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  cursor: pointer;
}
.rownav-tabs-wrapper .slick-arrow.slick-prev {
  left: 0;
}
.rownav-tabs-wrapper .slick-arrow.slick-next {
  right: 0;
  left: auto;
}
.rownav-tabs-wrapper .slick-list {
  padding: 0;
  margin: 0 25px;
}
.rownav-tabs-wrapper .slick-prev:before,
.rownav-tabs-wrapper .slick-next:before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #ffd13e;
}
.rownav-tabs-wrapper .slick-prev:before {
  content: "\f053";
}
.rownav-tabs-wrapper .slick-next:before {
  content: "\f054";
}
.rownav-tabs-wrapper .slick-arrow.slick-disabled {
  filter: grayscale(0.98);
  opacity: 0.7;
}

.rownav-tabs-wrapper .rownav-tabs-box {
  padding: 0;
  position: relative;
  width: 100%;
  overflow-x: hidden;
}

.rownav-tabs {
  width: 100%;
  display: flex;
  gap: 3px;
  align-items: center;
  overflow-x: auto;
  padding: 0 5px;
}
.rownav-tabs-wrapper .slick-list .slick-track {
  padding: 12px 35px;
}
.rownav-tabs .tab-item {
  color: var(--primary-font-color);
  font-size: var(--small-font);
  font-weight: 400;
  background: transparent;
  cursor: pointer;
  border: 0;
  padding: 1.5rem 1rem;
  margin-right: 1rem;
  letter-spacing: 0.02em;
  position: relative;
}
.rownav-tabs .tab-item {
  background-color: #150b37;
  border-radius: 3rem;
  box-sizing: border-box;
  color: #ecab26;
  font-size: 1.4rem;
  line-height: 1;
  padding: 1rem 1.6rem;
  text-align: center;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  background: #1e1b53;
  background: -moz-linear-gradient(top, #13103d 0%, #36328b 100%);
  background: -webkit-linear-gradient(top, #13103d 0%, #36328b 100%);
  background: linear-gradient(to bottom, #13103d 0%, #36328b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#13103d', endColorstr='#36328b',GradientType=0 );
}
.rownav-tabs .tab-item .num {
  margin-left: 5px;
  letter-spacing: 0.08em;
  font-size: 0.9em;
  font-weight: 600;
}
.rownav-tabs .tab-item.active {
  color: #020106;
  background: #a87616;
  background: -moz-linear-gradient(top, #8e5e00 0%, #e09d1c 100%);
  background: -webkit-linear-gradient(top, #8e5e00 0%, #e09d1c 100%);
  background: linear-gradient(to bottom, #8e5e00 0%, #e09d1c 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8e5e00', endColorstr='#e09d1c',GradientType=0 );
  font-weight: 600;
}
.rownav-tabs .tab-item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.32rem;
  background-color: transparent;
}
.rownav-tabs .tab-item.active:after {
  content: "";
  background-color: var(--warning-color);
  border-radius: 0.5rem 0.5rem 0 0;
  display: none;
}
.rownav-tabs::-webkit-scrollbar {
  height: 5px;
  transition: all 0.2s ease-in-out;
}
.rownav-tabs::-webkit-scrollbar:hover {
  height: 8px;
}
.rownav-tabs::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.rownav-tabs::-webkit-scrollbar-thumb {
  background: #888;
}
.rownav-tabs::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (min-width: 1200px) {
  button.ctab:hover,
  button.primary-gradient:hover,
  button.btn.btn-primary:hover,
  button.btn-primary:hover,
  btn.primary-gradient:hover {
    background: #46509e;
    background: -moz-linear-gradient(top, #46509e 0%, #646edf 100%);
    background: -webkit-linear-gradient(top, #46509e 0%, #646edf 100%);
    background: linear-gradient(to bottom, #46509e 0%, #646edf 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#46509e', endColorstr='#646edf',GradientType=0 );
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.36);
  }

  button.btn.btn-outline:hover,
  button.btn-outline:hover,
  .btn.btn-outline:hover,
  #sp-menu .loginBtn.btn:hover {
    background-color: #ffffff;
    color: #0c2867;
  }

  button.btn.btn-secondary:hover,
  button.btn-secondary:hover,
  btn.primary-secondary:hover {
    background: #0979ff;
    background: -moz-linear-gradient(top, #0979ff 0%, #3fadfc 100%);
    background: -webkit-linear-gradient(top, #0979ff 0%, #3fadfc 100%);
    background: linear-gradient(to bottom, #0979ff 0%, #3fadfc 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0979ff', endColorstr='#3fadfc',GradientType=0 );
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.36);
  }

  .rownav-tabs .tab-item:hover {
    background: linear-gradient(to bottom, #171349 0%, #38339b 100%);
  }
  .rownav-tabs .tab-item.active:hover {
    color: #020106;
    background: linear-gradient(to bottom, #8e5e00 0%, #e09d1c 100%);
  }
}

.product-page #sp-main-body {
  padding: 0;
}
.product-page #sp-main-body .container {
  width: 100%;
  max-width: none;
  padding: 0;
}

.article-details .article-ratings-social-share {
  display: none !important;
}

#sp-left .sp-module,
#sp-right .sp-module {
  border: none;
  background-color: #0c2867;
  border-radius: 10px;
}

.misc-page-row {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  line-height: 1.6;
  margin-top: 3rem;
  border-radius: 1rem;
}
.misc-page-row p {
  font-size: 16px;
}

/* accordion */
.panel-group .panel {
  border-color: transparent;
  box-shadow: none;
}
.panel .panel-heading {
  background-color: transparent;
  padding: 0;
  font-weight: 700;
  cursor: pointer;
}
.panel .panel-heading h4 {
  font-size: 15px;
  position: relative;
  padding-right: 20px;
  padding: 10px 35px 10px 15px;
  /* font-weight: 500; */
  font-weight: 700;
  letter-spacing: 0.02em;
}
.panel .panel-heading h4:after {
  position: absolute;
  top: 50%;
  font-size: 20px;
  margin-top: -13px;
  right: 15px;
  color: #ffffff;
  content: "+";
  min-width: 25px;
  text-align: center;
}
.panel .panel-heading h4[aria-expanded="true"] {
  background-color: rgba(255, 255, 255, 0.11);
}
.panel .panel-heading h4[aria-expanded="true"]:after {
  content: "-";
}
/* .panel .panel-collapse.collapse.in, .panel .panel-collapse.collapsing {
    background-color: #edf4f7; border: 1px solid #edf4f7; border-top: none; border-radius: 0 0 5px 5px; color: #444444;
} */
.panel-group .panel + .panel {
  margin-top: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.panel .panel-heading h4,
.panel .panel-collapse.collapse,
.panel .panel-collapse.collapsing {
  transition: all 0.25s ease-in-out;
}
.panel .panel-collapse.collapse {
  padding: 15px 0 30px;
}
.panel .panel-collapse .panel-body {
  padding: 0 15px;
}
/* -------List Style----- */
.decilist ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}
.decilist ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}
.decilist ol > li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;
}
.decilist li ol > li {
  margin: 0;
}
.decilist li ol > li:before {
  content: counters(item, ".") " ";
}

/**********************Inner Mailer Css*****************************/

.myaccount_body_section .heading span.mail_count {
  color: #fff;
  font-size: 16px;
}
.myaccount_body_section .heading .search_mail {
  float: right;
  width: auto;
}
.myaccount_body_section .heading .search_mail .search_box {
  float: left;
  margin: -4px 0 0px 0;
  width: 200px;
}
.myaccount_body_section .heading .search_mail .search_box .input-group {
  border: 1px solid #fff;
  float: left;
  width: 100%;
  height: 25px;
  border-radius: 25px;
  padding: 0;
}
.myaccount_body_section
  .heading
  .search_mail
  .search_box
  .input-group
  .btn.btn-default {
  border: 0;
  background: none;
  border-radius: 25px 0px 0px 25px;
  height: 23px;
  padding: 0 10px;
  color: #fff;
}
.myaccount_body_section
  .heading
  .search_mail
  .search_box
  .input-group
  .custome_input {
  border: 0;
  background: none;
  border-radius: 0 25px 25px 0;
  padding-right: 15px;
  height: 23px;
  font-size: 14px;
  color: #fff;
}
.myaccount_body_section .heading .search_mail .mail_actionbtn {
  float: right;
  margin: 0;
  padding: 0;
}
.myaccount_body_section .heading .search_mail .mail_actionbtn a.maroon_bg {
  float: left;
  margin: -4px 0 0 10px;
}
.myaccount_body_section .heading .search_mail .mail_actionbtn a.green_bg {
  float: left;
  font-size: 14px;
  border-radius: 3px;
  height: 25px;
  line-height: 25px;
  margin: -4px 0 0 0px;
}
.myaccount_body_section .heading .search_mail .mail_actionbtn a.back_btn {
  background: none;
  float: left;
  color: #474747;
  font-size: 14px;
  border: 0;
  text-shadow: none;
  margin: -1px 10px 0 0;
  padding: 0;
}

.myaccount_body_section .heading .search_mail .mail_actionbtn_inner {
  float: right;
  margin: 0;
  padding: 0;
}
.myaccount_body_section
  .heading
  .search_mail
  .mail_actionbtn_inner
  a.maroon_bg {
  float: left;
  margin: -4px 0 0 10px;
}
.myaccount_body_section .heading .search_mail .mail_actionbtn_inner a.green_bg {
  float: left;
  font-size: 14px;
  border-radius: 3px;
  height: 25px;
  line-height: 25px;
  margin: -4px 0 0 0px;
}
.myaccount_body_section .heading .search_mail .mail_actionbtn_inner a.back_btn {
  background: none;
  float: left;
  color: #a7f4fd;
  font-size: 14px;
  border: 0;
  text-shadow: none;
  margin: -1px 10px 0 0;
  padding: 0;
}

.myaccount_body_section .inbox {
  float: left;
  margin: 0;
  width: 100%;
  padding: 3.6rem 4rem;
  background-color: #e4e4e4;
  border-radius: 0 0 1rem 1rem;
}
.myaccount_body_section .inbox #inbox-table-footable > tbody {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
}
.myaccount_body_section .inbox #inbox-table-footable > tbody > tr > td {
  padding: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
  border-top: 0;
}
.myaccount_body_section .inbox .mail_item {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 0;
  border-left: 3px solid #0e0729;
}
.myaccount_body_section .inbox td.text-center .mail_item {
  border-left: 0;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.5;
}
/* .myaccount_body_section .inbox .mail_item:last-child{border:0;} */
.myaccount_body_section .inbox .mail_item .mail_checkbox {
  float: left;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
}
.myaccount_body_section
  .inbox
  .mail_item
  .mail_checkbox
  .icheckbox_square-green {
  background-position: -119px 0;
}
.myaccount_body_section
  .inbox
  .mail_item
  .mail_checkbox
  .icheckbox_square-green.hover {
  background-position: -119px 0;
}
.myaccount_body_section
  .inbox
  .mail_item
  .mail_checkbox
  .icheckbox_square-green.checked {
  background-position: -176px 0;
}
.myaccount_body_section .inbox .mail_item .mail_shortinfo {
  float: right;
  margin: 0;
  padding: 10px 10px 10px 0;
  width: calc(100% - 40px);
}
.myaccount_body_section .inbox .mail_item .mail_shortinfo .mail_title {
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.myaccount_body_section .inbox .mail_item a {
  color: inherit;
}
/* .myaccount_body_section .inbox .mail_item a .mail_shortinfo .mail_title{float:left; font-size:14px; color:inherit; text-decoration:none;} */
.myaccount_body_section
  .inbox
  .mail_item
  .mail_shortinfo
  .mail_title
  .mail_date {
  float: right;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: 13px;
}
.myaccount_body_section .inbox .mail_item .mail_shortinfo .mail_info {
  float: left;
  margin: 0;
  padding: 0;
  width: 100%;
  color: #777777;
  font-size: 14px;
}
.myaccount_body_section .inbox .mail_item.unread {
  background: rgba(0, 0, 0, 0.1);
  border-left-color: #ffd13e;
}
.myaccount_body_section .inbox .mail_item.unread a {
  font-weight: bold;
}
/*.myaccount_body_section .inbox .mail_item.unread .mail_shortinfo .mail_title a{}*/
.myaccount_body_section .inbox .mail_item.unread .mail_shortinfo .mail_title {
  font-weight: bold;
}
.myaccount_body_section
  .inbox
  .mail_item.unread
  .mail_shortinfo
  .mail_title
  .mail_date {
  font-weight: bold;
}
.myaccount_body_section .inbox .inbox_inner {
  float: left;
  width: 100%;
  margin-bottom: 40px;
}
.myaccount_body_section .heading .search_mail a.back_btn i {
  font-size: 25px;
  float: left;
  margin: -4px 5px 0 0;
}
.myaccount_body_section .inbox .inbox_inner .mail_heading {
  margin: 0;
  padding: 10px 0;
  margin-bottom: 15px;
  width: 100%;
  font-size: 20px;
  border-bottom: 1px solid #ddd;
  line-height: 1.2;
}
.myaccount_body_section .inbox .inbox_inner .mail_heading .mail_date {
  font-size: 14px;
  display: block;
  margin-top: 5px;
}
.myaccount_body_section .inbox .inbox_inner .inbox_mailer {
  width: 100%;
  float: left;
  margin: 0 auto;
}

.myaccount_body_section .inbox .mail_item .mail_checkbox label,
.icheckbox_label {
  width: 40px;
  height: 36px;
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  padding: 8px 10px;
}
.footable-first-column .icheckbox_label {
  margin-bottom: -10px;
}
.myaccount_body_section .inbox .mail_item .mail_checkbox label input,
.icheckbox_label input {
  opacity: 0;
}
.myaccount_body_section
  .inbox
  .mail_item
  .mail_checkbox
  label
  input
  + span:after,
.icheckbox_square-green + span:after {
  /*content: '\f096';*/
  font-family: "Font Awesome 5 Free";
  position: absolute;
  width: 20px;
  height: 20px;
  text-align: center;
  top: 8px;
  left: 10px;
  line-height: 20px;
  font-size: 18px;
  z-index: 10;
  content: "\f0c8";
}
.myaccount_body_section
  .inbox
  .mail_item
  .mail_checkbox
  label
  input:checked
  + span:after,
.icheckbox_square-green.checked + span:after {
  /*content: '\f14a';*/
  content: "\f14a";
}
.myaccount_body_section .inbox .mail_item .mail_checkbox label:after,
.icheckbox_label:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-radius: 50%;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
  transition: all 0.25s ease-in-out;
  transform-origin: center center;
}
.myaccount_body_section .inbox .mail_item .mail_checkbox label:hover:after,
.icheckbox_label:hover:after {
  width: 35px;
  height: 35px;
}
.myaccount_body_section .mail_count {
  font-size: 0.8em;
}
.myaccount_body_section .search_mail {
  display: flex;
  padding: 15px;
  justify-content: space-between;
  width: 100%;
  padding: 3.6rem 4rem 0;
  background-color: #e4e4e4;
  border-radius: 1rem 1rem 0 0;
}
.search_box .input-group .input-group-btn {
  position: absolute;
  top: 0px;
  right: 0;
  width: 35px;
  height: 100%;
}
.search_box .input-group .input-group-btn button {
  margin: 0;
  border: none;
  height: 100%;
  position: absolute;
  padding: 0;
  width: 35px;
  text-align: center;
  color: #ffd13e;
  font-size: 16px;
}
.search_box .input-group input {
  width: 280px;
  height: 4.8rem;
  background: transparent;
  font-size: 13px !important;
  padding: 0.5rem 1rem !important;
}
.search_box .input-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 0.1rem solid #6561b9;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  background: #383671;
}
.search_mail #delete_main,
.search_mail .mail_actionbtn_inner a {
  border: 1px solid #0448a8;
  padding: 3px 18px;
  border-radius: 20px;
  position: relative;
  display: inline-block;
  line-height: 1.6;
  vertical-align: baseline;

  font-size: 13px;
  color: var(--primary-font-color);
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 2.5rem;
  border: 0.1rem solid var(--white-color);
  padding: 1.2rem 2.5rem;
  background: #e4e4e4;
  font-family: var(--font-family);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.search_mail .mail_actionbtn_inner a:hover {
  background-color: var(--secondary-color);
  color: inherit;
}
.search_mail #delete_main:before,
.search_mail .mail_actionbtn_inner a:before {
  content: "\f1f8";
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  text-align: center;
  display: inline-block;
  margin-right: 7px;
  font-weight: 600;
}
.search_mail #delete_main:hover,
.search_mail .mail_actionbtn_inner a:hover {
  background-color: var(--secondary-color);
  color: inherit;
}
.search_mail .mail_actionbtn_inner a.back_btn:before {
  /* content: '\f104'; content: '\f0a5'; */
  /*content: '\f100';*/
  content: "\f060";
}
/* .search_mail .mail_actionbtn_inner a.btn:hover{
    color: #ffffff;
    background-color: #d29020;
    border-color: #d29020;
} */

.scroll-wrapper {
  margin-bottom: 20px;
  margin-top: 20px;
}

.myaccount_body_section .inbox > table,
.myaccount_body_section .inbox > table td,
.myaccount_body_section .inbox > table th {
  background-color: transparent !important;
  border: none;
  color: inherit;
  padding: 0;
  margin: 0;
}
/*.myaccount_body_section .inbox .icheckbox_square-green, .myaccount_body_section .inbox .iradio_square-green {
    display: inline-block; vertical-align: middle; margin: 0; padding: 0; width: 24px; height: 24px;
    background: url(../images/common/green.png) no-repeat; border: none; cursor: pointer;}
    */

@media only screen and (min-width: 480px) and (max-width: 600px) {
  .myaccount_body_section .inbox .inbox_inner .inbox_mailer .deviceWidthouter {
    width: 100% !important;
    padding: 0;
  }
  .myaccount_body_section .inbox .inbox_inner .inbox_mailer .deviceWidth {
    width: 100% !important;
    padding: 0;
  }
  .myaccount_body_section
    .inbox
    .inbox_inner
    .inbox_mailer
    .deviceWidth
    img.feature {
    width: auto !important;
    padding: 0;
  }
  .myaccount_body_section .inbox .inbox_inner .inbox_mailer .btnWidth {
    width: 210px !important;
    padding: 0;
  }
  .myaccount_body_section .inbox .inbox_inner .inbox_mailer .center {
    text-align: center !important;
  }
}
@media only screen and (min-width: 0px) and (max-width: 479px) {
  .myaccount_body_section .inbox .inbox_inner .inbox_mailer .deviceWidthouter {
    width: 100% !important;
    padding: 0;
  }
  .myaccount_body_section .inbox .inbox_inner .inbox_mailer .deviceWidth {
    width: 100% !important;
    padding: 0;
  }
  .myaccount_body_section .inbox .inbox_inner .inbox_mailer .btnWidth {
    width: 210px !important;
    padding: 0;
  }
  .myaccount_body_section .inbox .inbox_inner .inbox_mailer .center {
    text-align: center !important;
  }
}
/**********************Inner Mailer Css*****************************/

.alert {
  margin: 10px 0;
  padding: var(--bs-alert-padding-y) 40px var(--bs-alert-padding-y)
    var(--bs-alert-padding-x);
}

.alert .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
  width: 40px;
  text-align: center;
}

.burger-icon > span {
  background-color: #fff;
}
.offcanvas-menu {
  background-color: #0c2867;
  color: #ffffff;
}
.offcanvas-active .burger-icon > span,
#modal-menu-toggler.active .burger-icon > span {
  background-color: #ffffff;
}
.offcanvas-menu .offcanvas-inner ul.menu > li a {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  opacity: 1;
  color: #ffffff;
}
.offcanvas-menu .offcanvas-inner ul.menu > li a span {
  color: #ffffff;
}
.offcanvas-menu .offcanvas-inner ul.menu > li a img {
  margin-right: 10px;
  width: 28px;
  height: 28px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
}
.offcanvas-menu .offcanvas-inner .sp-module.nav-myacc ul.menu > li a img {
  width: 25px;
  height: 27px;
}
.logout {
  font-weight: bold;
  color: white;
  border-bottom: none;
  margin: 35px auto;
  max-width: 170px;
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 2.5rem;
  border: none;
  padding: 1.2rem 2.5rem;
  background: #e4e4e4;
  font-family: var(--font-family);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(113, 213, 239);
  background: -moz-linear-gradient(
    0deg,
    rgba(113, 213, 239, 1) 0%,
    rgba(8, 92, 178, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(113, 213, 239, 1) 0%,
    rgba(8, 92, 178, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(113, 213, 239, 1) 0%,
    rgba(8, 92, 178, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#71d5ef",endColorstr="#085cb2",GradientType=1);
  box-shadow: 0 0 3.3rem rgb(82 167 233 / 50%);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.offcanvas-menu .offcanvas-inner {
  padding: 0;
  position: relative;
}
.offcanvas-menu .offcanvas-inner ul.menu > li:hover > a > span,
.offcanvas-menu .offcanvas-inner ul.menu > li.active > a > span {
  color: #00bcd4;
}
.offcanvas-menu .offcanvas-inner ul.menu > li:hover > a img,
.offcanvas-menu .offcanvas-inner ul.menu > li.active > a img {
  object-position: center bottom;
}
.offcanvas-menu .offcanvas-inner ul.menu ul.menu-child > li a img {
  min-width: 80px;
  height: auto;
}
.offcanvas-menu .offcanvas-inner ul.menu ul.menu-child > li a {
  min-height: 55px;
  padding-left: 30px;
}
.post-login .offcanvas-menu .offcanvas-inner .nav-myacc .menu > li:last-child {
  position: absolute;
  width: 100%;
  bottom: 0;
}

.offcanvas-menu
  .offcanvas-inner
  ul.menu
  > li.menu-parent
  > a
  > .menu-toggler:after,
.offcanvas-menu
  .offcanvas-inner
  ul.menu
  > li.menu-parent
  > .menu-separator
  > .menu-toggler:after {
  content: "+";
  color: #ffffff;
  font-size: 22px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  padding-left: 10px;
  line-height: 40px;
  min-width: 35px;
  display: block;
  text-align: center;
}
.offcanvas-menu
  .offcanvas-inner
  ul.menu
  > li.menu-parent.menu-parent-open
  > a
  > .menu-toggler:after,
.offcanvas-menu
  .offcanvas-inner
  ul.menu
  > li.menu-parent.menu-parent-open
  > .menu-separator
  > .menu-toggler:after {
  content: "-";
}
.offcanvas-menu .offcanvas-inner ul.menu > li a:hover,
.offcanvas-menu .offcanvas-inner ul.menu > li a:focus,
.offcanvas-menu .offcanvas-inner ul.menu > li span:hover,
.offcanvas-menu .offcanvas-inner ul.menu > li span:focus {
  color: #00bcd4;
}

#sp-section-1 .row,
#sp-section-1 div[class^="col-"],
#sp-section-2 .row,
#sp-section-2 div[class^="col-"] {
  padding: 0 !important;
  margin: 0 !important;
}

.slick-prev {
  left: 25px;
}
.slick-next {
  right: 25px;
}
.slick-prev,
.slick-next {
  z-index: 10;
}
.sp-module.mobile_slider {
  display: none;
}
.user_avtar img {
  max-width: 280px;
  margin: auto;
  max-height: 280px;
  object-fit: cover;
  aspect-ratio: 1/1;
  object-position: center center;
  border-radius: 1rem;
}

.selected_avtar {
  max-width: 380px;
  text-align: center;
}

.upload_pic_actbtn img {
  display: inline-block;
}
.upload_pic .button_holder {
  margin-top: 10px;
}

#avatar_saving_btn {
  color: inherit;
  cursor: default;
}
#avatar_saved_btn {
  margin-top: 10px;
  margin-bottom: -10px;
  display: inline-flex;
}
.datepicker {
  font-size: 12px;
  padding: 10px;
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background: rgb(113, 213, 239);
  background: -moz-linear-gradient(
    0deg,
    rgba(113, 213, 239, 1) 0%,
    rgba(8, 92, 178, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(113, 213, 239, 1) 0%,
    rgba(8, 92, 178, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(113, 213, 239, 1) 0%,
    rgba(8, 92, 178, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#71d5ef",endColorstr="#085cb2",GradientType=1);
}
form label {
  font-size: 0.85em;
  margin-bottom: 5px;
}
.edit_avtar .entry-header h2 {
  margin-bottom: 10px;
  margin-top: 14px;
}
.transction_filter,
#player-avatar-form,
#player-profile-form {
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-top: 3rem;
}
#player-profile-form .form-group.last.button_holder {
  margin-top: 1.5rem;
}

.button_holder button.btn.btn_next,
.button_holder .btn.upload_btn,
.button_holder .btn.save_btn {
  background: #646edf;
  border: 1px solid #646edf;
  background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: -webkit-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: linear-gradient(to bottom, #646edf 0%, #46509e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#646edf', endColorstr='#46509e',GradientType=0 );
  border-radius: 100px;
  color: #ffffff;
  min-width: 90px;
  margin: 5px;
}
.button_holder button.btn.btn_next.btnStyle1 {
  background: none;
  background-color: #ffffff;
  color: #646edf;
  border: 1px solid #646edf;
}

form label {
  font-size: 0.85em;
  margin-bottom: 5px;
}
.form_item_holder .error_tooltip,
.form-group .error_tooltip {
  position: absolute;
  width: 100%;
  top: calc(100% + 5px);
}

form .form-group {
  position: relative;
}

.formItemBtnGroup,
#player-profile-form .do_verify,
#player-profile-form .verify {
  position: relative;
}

.formItemBtnGroup button,
#player-profile-form .do_verify button,
#player-profile-form .verify button,
#player-profile-form .do_verify .btn_verify,
#player-profile-form .verify .btn_verify {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  /* border-radius: 1rem; */
  border: 0 solid #d9d9d9;
  border-left-width: 1px;
  text-align: center;
  width: 80px;
  font-size: 1em;
  z-index: 10;
  background-color: #d9d9d9;
  border-radius: 0;
}
button.btn.disabled {
  opacity: 0.3;
}

.formItemBtnGroup input,
#player-profile-form .do_verify input,
#player-profile-form .verify input {
  padding-right: 85px;
  max-width: none;
}

#player-profile-form .do_verify button,
#player-profile-form .verify button {
  cursor: pointer;
}

#player-profile-form .do_verify button,
#player-profile-form .verify .btn_verify {
  display: block;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

#player-profile-form .do_verify .btn_verify,
#player-profile-form .verify button {
  display: none;
}

#player-profile-form .verify .btn_verify {
  line-height: 30px;
  color: green;
  font-size: 12px;
  /* font-weight: 500; */
  font-weight: 700;
}

#player-profile-form textarea {
  max-width: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 0.1rem solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  position: relative;
}
.input-group.date {
  flex-wrap: nowrap;
}

.gender .checkbox {
  margin: 0;
  display: flex;
}
.gender .checkbox label {
  position: relative;
  padding: 0;
  margin: 0;
  float: left;
  flex: 1;
  cursor: pointer;
}

/* .gender .checkbox:after {content: '';display: block;clear: both;} */
.gender .checkbox label input {
  position: absolute;
  opacity: 0;
}

.gender .checkbox label span {
  position: relative;
  border: 0.1rem solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  text-align: center;
  background-color: #ffffff;
  /* box-shadow: inset 1px 1px 2px rgba(0,0,0,0.6); */
  color: #555555;
  height: 42px;
  font-size: 13px !important;
}

.gender .checkbox label + label {
  margin-left: -1px;
}

.gender .checkbox label:first-child span {
  border-radius: 3rem 0 0 3rem;
}

.gender .checkbox label:last-of-type span {
  border-radius: 0 3rem 3rem 0;
}

.gender .checkbox label input:checked + span {
  background-color: #ffb559;
  cursor: default;
  color: #000f38;
}

.gender .checkbox label input:checked + span:before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  margin-right: 5px;
  font-weight: 600;
}

.gender .checkbox label input.error + span {
  border-color: #ec0f1c;
}

.send_msg {
  color: #91dd39;
  line-height: 1.4;
}

form#contact-us-form > .formWrap {
  border: none !important;
}

form#contact-us-form {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  line-height: 1.6;
  margin-top: 3rem;
  border-radius: 1rem;
}

main#sp-component p {
  font-size: var(--small-font);
}

.form_item_holder .inputGroup,
.form_item_holder .inputGroup input {
  width: 100%;
}

textarea {
  color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 0.1rem solid #d9d9d9;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  padding: 10px;
}

.inputGroup.error {
  margin-bottom: 35px;
}
.form_item_holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 0.1rem solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  position: relative;
  background: #ffffff;
}
.form-group.last.gender {
  margin-bottom: 1.5rem;
}
#contact-us-form .form_item_holder {
  background: transparent;
  border: none;
  margin: auto;
  border-radius: 0;
}
#contact-us-form .form_item_holder .inputGroup {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 0.1rem solid #d9d9d9;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  position: relative;
  max-width: 500px;
  /* background: #383671; */
}
#contact-us-form textarea {
  max-width: 500px;
  border-radius: 5px;
}
#contact-us-form .form_item_holder .inputGroup.error {
  margin-bottom: 35px;
}
.form_item_holder.error {
  margin-bottom: 35px;
}
.form_item_holder input {
  height: 40px;
  background: transparent;
  font-size: 13px !important;
  padding: 0 12px !important;
}

.input-group.date button.btn_date {
  background-color: transparent;
  border: 0;
}
.input-group.date a.input-group-addon.btn_date {
  display: flex;
  align-items: center;
  padding-right: 5px;
  color: inherit;
}

#contact-us-form button.btn[type="submit"] {
  margin-top: 20px;
  min-width: 200px !important;
  padding: 0 20px;
  min-width: 150px;
  border-radius: 2.5rem;
  border: 0;
  color: var(--primary-font-color);
  font-size: 13px;
  text-transform: uppercase;
  /* font-weight: 500; */
  font-weight: 700;
  cursor: pointer;
  height: 50px;
  background: #646edf;
  background: -moz-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: -webkit-linear-gradient(top, #646edf 0%, #46509e 100%);
  background: linear-gradient(to bottom, #646edf 0%, #46509e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#646edf', endColorstr='#46509e',GradientType=0 );
  border: none;
  color: #ffffff;
}

#transaction-details-form {
  display: flex;
  gap: 15px;
}
#transaction-details-form .filter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
#transaction-details-form .filter .btn_search {
  margin-bottom: 1.6rem;
}

#transaction-details-form .input-group.date input {
  background-color: transparent;
  border: 0;
  /* color: #ffffff; */
}
#transaction-details-form .range-date-picker .input-group + .input-group {
  border-left: 0.1rem solid #d9d9d9;
}
.transaction-date-range-view span.date-label {
  line-height: 1;
  padding-right: 10px;
}

.select_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  position: relative;
  background: #ffffff;
}
.select_box select.custome_input {
  height: 40px;
  background: transparent;
  font-size: 13px !important;
  /* padding: 0 15px !important; */
  min-width: 120px;
}
.select_box select.custome_input option {
  color: #0e0729;
  background-color: #ffffff;
  font-size: 1.2em;
}
#transaction-details-form .filter.select_type .form-group label {
  white-space: nowrap;
  margin-top: -20px;
  display: block;
  margin-bottom: 4px;
}
#transaction-details-form .range-date-picker {
  margin-bottom: 0;
}
#transaction-details-form .select_box {
  margin-bottom: 0;
}
.range-search-button {
  margin-left: 0;
}

.winner-section .section2 {
  background: rgb(72, 29, 150);
  background: -moz-linear-gradient(
    -45deg,
    rgba(72, 29, 150, 1) 0%,
    rgba(144, 58, 215, 1) 100%
  );
  background: -webkit-linear-gradient(
    -45deg,
    rgba(72, 29, 150, 1) 0%,
    rgba(144, 58, 215, 1) 100%
  );
  background: linear-gradient(
    135deg,
    rgba(72, 29, 150, 1) 0%,
    rgba(144, 58, 215, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#481d96', endColorstr='#903ad7',GradientType=1 );
  border-radius: 10px;
  padding: 20px;
}

.winner-list-wrap .list-row {
  display: flex;
  gap: 10px;
}

.winner-list-wrap .list-row .list-detail {
  flex: 1;
  display: grid;
  grid-template: auto auto / auto auto;
}

.winner-list-wrap .list-row .list-detail .amt {
  display: flex;
  justify-content: flex-end;
}

.winner-list-wrap .list-row .list-detail .game-name {
  text-align: right;
}

.winner-list-wrap .header {
  text-align: center;
}

.winner-list-wrap .header .img-wrap img {
  display: inline-block;
}

.game-list-wrap .game-list {
  display: grid;
  gap: 15px;
  position: relative;
}
.game-list-wrap .game-list li {
  position: relative;
  z-index: 1;
}
.game-list-wrap .game-list li:hover {
  position: relative;
  z-index: 5;
}

.game-list-wrap.type4 .game-list {
  grid-template: auto / repeat(4, 1fr);
}

.game-list-wrap .game-list img {
  width: 100%;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  /* box-shadow: 0 0px 4px rgba(0, 0, 0, 0); */
  box-shadow: 0 0px 10px rgba(49, 53, 120, 0.36);
  height: 145px;
  background-color: #0e0729;
  /* object-fit: cover;
    object-position: center center; */
}
.game-list-wrap .game-list a:hover img {
  transform: scale(1.2);
  transform-origin: center center;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.9);
}

.game-list-wrap.type3 .game-list {
  grid-template: auto / repeat(6, 1fr);
}
.game-list-wrap.type3 .game-list li.feature:first-child {
  grid-area: 1/1/2/3;
}
.game-list-wrap.type2 .game-list {
  grid-template: auto / repeat(5, 1fr);
}
.game-list-wrap.type2 .game-list li.feature:first-child {
  grid-area: 1/1/3/3;
}
.game-list-wrap.type1 .game-list {
  grid-template: auto / repeat(3, 1fr);
}

.homePage #sp-main-body {
  display: none;
}

.game-list-wrap .game-list-head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.game-list-wrap .game-list-head .title {
  flex: 1;
  text-transform: uppercase;
  font-size: 20px;
}
.game-list-wrap .game-list-head .action-wrap .btn {
  color: #e8c844;
  font-size: 14px;
  position: relative;
}

/* #sp-component.col-lg-12  */
.entry-header {
  text-align: center;
  background-color: #404c91;
  margin: 45px auto 0;
  padding: 15px 15px 20px;
  color: #ffffff;
  border-radius: 1rem;
}

/* #sp-component.col-lg-12  */
.entry-header .my-acc-title.mb-5 {
  margin-bottom: 0 !important;
}

/* #sp-component.col-lg-12  */
.entry-header .sub-title {
  color: inherit;
  margin: 0;
}

/* #sp-component.col-lg-12  */
.entry-header h1 {
  margin: 0;
}

.g-recaptcha-wrap .g-recaptcha div {
  margin: 0 auto;
}

.input-group-row + .error_tooltip {
  margin-top: -8px;
  margin-bottom: 15px;
}
.error_tooltip {
  color: #ff3e3e;
  font-size: 0.85em;
}
.input-group-row .error_tooltip {
  position: absolute;
  top: calc(100% + 2px);
  z-index: 10;
}
.input-group-row.error {
  margin-bottom: 3rem;
}
#cashier-add-account-form .button_holder {
  text-align: center;
}
.top-user-wallet {
  width: auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  /* box-shadow: 0 2px 8px rgba(0,0,0,0.16); */
  display: inline-flex;
  position: relative;
  padding: 5px 10px 5px 20px;
  padding-right: 58px;
}

.top-user-wallet .user-balance {
  width: auto;
  display: flex;
  margin-right: 10px;
  flex-wrap: nowrap;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

button.refresh-balance {
  background-color: transparent;
  border: none;
  color: #ffffff;
}
i.icon-refresh:after {
  font-family: "FONT AWESOME 5 FREE";
  background-color: transparent;
  font-weight: 600;
  content: "\f2f1";
  font-size: 12px;
  color: #ffb559;
}

.top-user-wallet .user-balance .balance-icon {
  margin-right: 10px;
  margin-left: 7px;
  padding-bottom: 10px;
}

.top-user-wallet .user-balance .balance-icon img {
  vertical-align: middle;
}

.top-user-wallet .user-balance .balance-info h6 {
  color: var(--secondary-font-color);
}

.top-user-wallet .user-balance .balance-info label {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}

.top-user-wallet .user-add-balance {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 4px;
  top: 50%;
  border: 0;
  cursor: pointer;
  font-size: 36px;
  color: #fff;
  line-height: 1;
  transform: translateY(-50%);
  border-radius: 60px;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #13abe8;
}

.top-user-wallet .user-add-balance:before {
  content: "";
  position: absolute;
  background-position: right bottom;
  z-index: 1;
  width: 40px;
  height: 48px;
  display: block;
  background-size: 100%;
  bottom: 0;
  right: 0;
}

/* ------ */
/* .top-user-wallet .user-balance .balance-icon {display: none;} */
.top-user-wallet .user-balance .balance-info h6 {
  display: none;
}
.top-user-wallet .user-balance .balance-info label {
  /* position: absolute; */
  width: 100%;
  top: 2px;
  left: 2px;
  font-size: 13px;
}
.top-user-wallet .user-add-balance {
  position: static;
  width: auto;
  height: auto;
  font-size: 18px;
  transform: none;
  display: block;
  min-width: 120px;
  text-align: left;
  text-transform: uppercase;
  padding: 4px 45px 4px 15px;
  display: none;
}
.top-user-wallet .user-add-balance:after {
  display: none;
}
.top-user-wallet {
  background-color: transparent;
  display: block;
  padding: 0;
  /* padding-top: 20px; */
}
/* ------ */

.notification-info {
  width: 48px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #e4e4e4;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.notification-info:hover {
  background-color: #d64958;
}

.notification-info.unread:after {
  content: "";
  background: hsla(340, 85%, 63%, 1);
  background: linear-gradient(
    270deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  background: -moz-linear-gradient(
    270deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  background: -webkit-linear-gradient(
    270deg,
    hsla(340, 85%, 63%, 1) 0%,
    hsla(4, 54%, 50%, 1) 100%
  );
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#F15288", endColorstr="#C4463C", GradientType=1 );
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: -3px;
  border: 2px solid #1f1e48;
}

.top-user-pic {
  display: inline-flex;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: 4px;
  position: relative;
}

.top-user-pic .user-pic-wrap {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 5;
}
.top-user-pic .user-pic-wrap img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.top-user-pic:before {
  content: "";
  width: 52px;
  height: 52px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  background-color: #1f1e47;
  z-index: 2;
}

#sp-left .sp-module .sp-module-title,
#sp-right .sp-module .sp-module-title {
  font-size: 22px;
  font-weight: 500;
  border: 0;
  padding: 26px;
  margin-bottom: 0px;
  color: #ffffff;
  text-transform: uppercase;
}
#sp-left .sp-module,
#sp-right .sp-module {
  padding: 0;
}
#sp-left .menu li a img {
  width: 25px;
  height: 27px;
  object-fit: cover;
  object-position: top left;
  display: inline;
  vertical-align: middle;
  margin-right: 15px;
}
#sp-left .menu li.active a img {
  object-position: bottom left;
}
#sp-left .menu li a {
  color: #ffffff;
  line-height: 46px !important;
  padding: 2px 15px !important;
  position: relative;
}
#sp-left .menu li.active a {
  color: #00bcd4;
}
#sp-left .menu li.active a:before {
  content: "";
  width: 5px;
  height: 74%;
  position: absolute;
  top: 13%;
  background-color: #00bcd4;
  left: 0;
  border-radius: 0 10px 10px 0;
}
#sp-left .sp-module ul > li,
#sp-right .sp-module ul > li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}
#sp-left .sp-module ul,
#sp-right .sp-module ul {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
}
#sp-left .sp-module ul > li .link-logout {
  display: none;
}
#sp-left .sp-module ul {
  padding-bottom: 30px;
}

#sp-menu .nav-myacc {
  position: relative;
  margin-left: 10px;
}
#sp-menu .nav-myacc .sp-module-content {
  position: absolute;
  display: none;
  right: 0;
  top: 100%;
  min-width: 230px;
  padding-top: 10px;
}
#sp-menu .nav-myacc h3 {
  font-size: 0;
  height: 56px;
  position: relative;
}
#sp-menu .nav-myacc h3:after {
  content: "";
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  line-height: 1;
  color: #686697;
  font-weight: 600;
  position: absolute;
  padding-top: 6px;
  top: 50%;
  transform: translateY(-50%);
}
#sp-menu .nav-myacc h3:before {
  content: "";
  position: absolute;
  top: 0;
  left: -70px;
  width: 85px;
  height: calc(100% + 10px);
  z-index: 5;
  cursor: pointer;
}
#sp-menu .nav-myacc:hover h3.sp-module-title + .sp-module-content {
  display: block;
}
#sp-menu .nav-myacc .sp-module-content .menu {
  background-color: #0c2867;
  padding: 10px 0;
  border-radius: 0 0 6px 6px;
  list-style: none;
}
#sp-menu .nav-myacc .sp-module-content .menu button {
  all: unset;
  cursor: pointer;
  width: 230px;
}
#sp-menu .nav-myacc .sp-module-content .menu a,
#sp-menu .nav-myacc .sp-module-content .menu button {
  color: #ffffff;
  font-size: 14px;
  padding: 10px 15px;
  line-height: 24px;
  display: block;
  transition: all 0.2s ease-in-out;
}

#sp-menu .nav-myacc .sp-module-content .menu a:hover,
#sp-menu .nav-myacc .sp-module-content .menu button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
#sp-menu .nav-myacc .sp-module-content .menu a img {
  display: none;
}
#sp-menu .nav-myacc .sp-module-content .menu li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
}
#sp-menu .nav-myacc .sp-module-content .menu li.current a {
  color: #00bcd4;
}
.user-top-info .sp-module-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
#sp-menu .social-wrap {
  display: none !important;
}
#offcanvas-toggler {
  display: none !important;
}

#sp-bottom .sp-module .sp-module-title {
  position: relative;
}
#sp-bottom .sp-module .sp-module-title:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: #00bcd4;
  top: calc(100% + 3px);
  left: 0;
}
#sp-bottom .sp-module ul > li > a {
  color: #ffffff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 4px;
  margin-bottom: 4px;
}
#sp-bottom .sp-module ul > li {
  margin-bottom: 10px;
}
#sp-bottom .sp-module ul > li > a:hover {
  color: #00bcd4;
}
#sp-bottom {
  padding: 80px 0 50px;
}
#sp-footer2 .footer2 p {
  opacity: 0.7;
}
#sp-footer1 .footer1 p {
  opacity: 0.7;
}
#sp-footer1 .footer1 .block-wrap .block-title {
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 8px;
}

.wallTxn-modal-box {
  position: static;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  transform: none;
  width: 100%;
  max-width: none;
  top: auto;
  left: auto;
}

.winner-list-wrap .body .list-row {
  position: relative;
  padding: 13px 0;
}
.winner-list-wrap .body .list-row:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 7%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.3) 93%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 7%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.3) 93%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 7%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.3) 93%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  left: 0;
  bottom: 0;
}
.winner-list-wrap .body .list-row .pic-wrap {
  width: 42px;
  height: 42px;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ffffff;
}
.winner-list-wrap .list-row .list-detail .time,
.winner-list-wrap .list-row .list-detail .game-name {
  font-size: 0.85em;
  opacity: 0.7;
}
.winner-list-wrap .body .list-row:last-child:after {
  display: none;
}
.winner-list-wrap .title {
  text-transform: uppercase;
  font-size: 24px;
  margin-top: -15px;
  position: relative;
  margin-bottom: 10px;
}
.winner-list-wrap .title:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: #00bcd4;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.winner-list-wrap .header .img-wrap {
  margin-top: -10px;
}

.upcoming-item-list-wrap ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}
.upcoming-item-list-wrap ul li {
  flex: 0 0 20%;
  padding: 10px;
  position: relative;
}
/* .upcoming-item-list-wrap ul li:after {
  content: "";
  position: absolute;
  width: 100%;
  display: block;
  height: 56px;
  bottom: 0;
  left: 0;
  z-index: 5;
  background-color: #f7f7ff;
  background: -moz-linear-gradient(
    top,
    rgba(247, 247, 255, 0.02) 0%,
    rgba(247, 247, 255, 0.6) 46%,
    rgba(247, 247, 255, 0.96) 75%,
    rgba(247, 247, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(247, 247, 255, 0.02) 0%,
    rgba(247, 247, 255, 0.6) 46%,
    rgba(247, 247, 255, 0.96) 75%,
    rgba(247, 247, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(247, 247, 255, 0.02) 0%,
    rgba(247, 247, 255, 0.6) 46%,
    rgba(247, 247, 255, 0.96) 75%,
    rgba(247, 247, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7ff38', endColorstr='#f7f7ff',GradientType=0 );
  transition: all 0.2s ease-in-out;
} */
/* .upcoming-item-list-wrap ul li:before {
  content: "Play now";
  position: absolute;
  line-height: 1;
  text-align: center;
  z-index: 7;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 500;
  color: #0c2867;
  margin-top: 20px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
} */

.upcoming-item-list-wrap ul li {
  cursor: pointer;
}
.upcoming-item-list-wrap ul li img {
  /* border-radius: 8px 8px 0 0; */
  border-radius: 8px;
  /* transition: all 0.2s ease-in-out; */
}

.slot-game {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.slot-game .image-container {
  position: relative;
  border-radius: 8px;
}

.slot-game .image-container img {
  display: block;
  width: 100%;
  height: auto;
}

.slot-game .image-container .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%; /* Adjust based on how much fading area you want */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  display: flex;
  align-items: flex-end; /* To align the content at the bottom */
  border-radius: 8px;
}

.slot-game .image-container .overlay .play-now-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px; /* Adjust padding for spacing */
  color: white;
  font-size: 16px; /* Adjust the font size */
  font-weight: bold;
}

.slot-game .image-container .overlay .play-now-row .play-now-text {
  margin-left: 10px;
}

.slot-game .image-container .overlay .play-now-row .arrow-icon {
  margin-right: 10px;
}

/* .upcoming-item-list-wrap ul li:hover:after {
  height: 100%;
  background-color: #f7f7ff;
  background: -moz-linear-gradient(
    top,
    rgba(247, 247, 255, 0.02) 0%,
    rgba(247, 247, 255, 0.76) 26%,
    rgba(247, 247, 255, 0.96) 75%,
    rgba(247, 247, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(247, 247, 255, 0.02) 0%,
    rgba(247, 247, 255, 0.76) 26%,
    rgba(247, 247, 255, 0.96) 75%,
    rgba(247, 247, 255, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(247, 247, 255, 0.02) 0%,
    rgba(247, 247, 255, 0.76) 26%,
    rgba(247, 247, 255, 0.96) 75%,
    rgba(247, 247, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7ff38', endColorstr='#f7f7ff',GradientType=0 );
} */
.upcoming-item-list-wrap ul li:hover:before {
  opacity: 1;
  margin-top: 0;
}
@media only screen and (max-width: 991px) {
  .upcoming-item-list-wrap ul li {
    flex: 0 0 50%;
  }
}
@media only screen and (max-width: 599px) {
  .upcoming-item-list-wrap ul li:before {
    font-size: 16px;
    padding: 0 15px;
  }
}

.game-list-wrap .game-list-head .icon-wrap {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: -moz-linear-gradient(
    -45deg,
    rgba(240, 40, 130, 0.99608) 0%,
    rgb(238, 85, 73) 100%
  );
  background-image: -webkit-linear-gradient(
    -45deg,
    rgba(240, 40, 130, 0.99608) 0%,
    rgb(238, 85, 73) 100%
  );
  background-image: -ms-linear-gradient(
    -45deg,
    rgba(240, 40, 130, 0.99608) 0%,
    rgb(238, 85, 73) 100%
  );
  border-radius: 5px;
  margin-right: 15px;
  border: 1px solid #b4903a;
  box-shadow: inset 2px 2px 5px rgba(255, 255, 255, 0.5),
    inset -2px -2px 5px rgba(0, 0, 0, 0.6);
}

.game-list-wrap .game-list-head .action-wrap .btn:after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  margin-left: 5px;
}
.sp-megamenu-parent {
  margin: 0;
}
.sp-megamenu-parent > li:hover > a {
  /* color: #fed420; */
  color: #f3c952;
}
.sp-megamenu-parent .sp-dropdown .sp-dropdown-inner {
  background-color: #2c285d;
  padding: 10px 0;
  border-radius: 0 0 6px 6px;
}
.sp-megamenu-parent .sp-dropdown .sp-dropdown-inner li + li {
  border-top: 1px solid rgba(255, 255, 255, 0.07);
}
.sp-megamenu-parent .sp-dropdown.sp-dropdown-main {
  padding-top: 15px;
}
.sp-megamenu-parent li a.live-casino + .sp-dropdown .sp-dropdown-inner {
  padding: 0;
}
.sp-megamenu-parent
  li
  a.live-casino
  + .sp-dropdown
  .sp-dropdown-inner
  .sp-dropdown-items {
  display: flex;
}
.sp-megamenu-parent
  li
  a.live-casino
  + .sp-dropdown
  .sp-dropdown-inner
  .sp-dropdown-items
  li
  a
  span {
  display: none;
}
.sp-megamenu-parent
  li
  a.live-casino
  + .sp-dropdown
  .sp-dropdown-inner
  .sp-dropdown-items
  li
  a {
  min-height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
}
.sp-megamenu-parent li a.live-casino + .sp-dropdown {
  width: auto !important;
}
.sp-megamenu-parent
  li
  a.live-casino
  + .sp-dropdown
  .sp-dropdown-inner
  .sp-dropdown-items
  li
  + li {
  border-top: none;
  border-left: 1px solid rgba(255, 255, 255, 0.07);
}
.sp-megamenu-parent
  li
  a.live-casino
  + .sp-dropdown
  .sp-dropdown-inner
  .sp-dropdown-items
  li {
  display: flex;
}
.sp-megamenu-parent
  li
  a.live-casino
  + .sp-dropdown
  .sp-dropdown-inner
  .sp-dropdown-items
  li
  a
  img {
  max-height: 45px;
  max-width: 150px;
}
.sp-megamenu-parent .sp-dropdown .sp-dropdown-inner li.sp-menu-item > a {
  color: #ffffff;
  font-size: 14px;
  padding: 10px 15px;
  line-height: 24px;
  display: block;
  transition: all 0.2s ease-in-out;
}
.sp-megamenu-parent .sp-dropdown .sp-dropdown-inner li.sp-menu-item > a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.sp-megamenu-parent
  .sp-dropdown
  .sp-dropdown-inner
  li.sp-menu-item.current
  > a {
  color: #f3c952;
}
.about-box .imgWrap img {
  display: none;
}
.about-box #footer h3.mb-3 {
  display: none;
}

@media only screen and (min-width: 1401px) {
  .container .container {
    width: 100%;
  }
  .game-list-wrap.type2 .game-list {
    grid-template: auto / repeat(6, 1fr);
  }
}
@media only screen and (min-width: 1600px) {
  .game-list-wrap.type2 .game-list {
    grid-template: auto / repeat(7, 1fr);
  }
}
@media (max-width: 1650px) {
  .offer-group-wrap {
    gap: 15px;
  }
  #sp-user1 .winner-section {
    gap: 15px;
  }
  #sp-user1 .winner-section .section.section1 {
    max-width: 180px;
  }
  .winner-section .section2 {
    padding: 20px 15px;
    font-size: 0.9em;
  }
  #sp-feature .offer-wrap .img-symbol-wrap img {
    max-width: 75%;
  }
  #sp-feature .offer-wrap.type3 .img-symbol-wrap {
    left: -35px;
    bottom: -16px;
  }
  #sp-feature .offer-wrap.type2 .img-symbol-wrap {
    right: -120px;
    bottom: -10px;
  }
  #sp-feature:before,
  #sp-feature:after {
    background-size: auto 80%;
  }
  #sp-bottom {
    padding: 30px 0 10px;
  }
  #sp-footer .container-inner {
    padding: 15px 0;
  }
  #sp-main-body {
    padding: 40px 0;
  }
  .group-logo img {
    max-width: 131px;
    margin-top: 0;
  }
  #sp-position1 {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .my-profile-user-balance {
    margin-right: 1.5rem;
  }
  .my-profile-user-balance + .my-profile-user-balance {
    margin-right: 60px;
  }
  .my-profile-user-balance + .my-profile-user-balance {
    margin-right: 60px;
  }
  .my-profile-user-add-balance {
    width: 5.5rem;
    height: 5.5rem;
    font-size: 4.2rem;
    top: 50%;
    transform: translateX(150%) translateY(-50%);
    right: 6.5rem;
  }
}

@media only screen and (max-width: 1599px) {
  #sp-logo {
    width: 200px;
  }
  #sp-header .logo a:after {
    width: 190%;
    clip-path: polygon(
      0% 0%,
      25% 0%,
      25% 100%,
      75% 100%,
      75% 0%,
      100% 0%,
      100% 100%,
      0% 100%
    );
  }
}
@media only screen and (max-width: 1400px) {
  .sp-megamenu-parent .sp-dropdown.sp-dropdown-main {
    padding-top: 10px;
  }
  #sp-bottom .sp-module .sp-module-title {
    margin-bottom: 20px;
  }
  #sp-bottom .sp-module ul > li {
    margin-bottom: 0px;
  }
  .block4-icons {
    padding-top: 0;
  }
  .app-icon-wrap .app-icon-list li {
    margin-bottom: 0 !important;
    max-width: 150px;
  }
  #sp-bottom .sp-module {
    margin-bottom: 10px;
  }
  #sp-feature {
    padding: 0;
  }
  /* #sp-header.header-sticky .sp-megamenu-parent>li>a .image-title {
        margin-top: 4px;
        font-size: 0.8em;
    } */
  #sp-menu {
    height: 70px;
  }
  #sp-header {
    height: 70px;
  }
  /* #sp-header .logo {height: 76px;} 
    .logo-image {height: 76px;}*/
  .sp-megamenu-parent > li > a .image-title {
    margin-top: 5px;
  }
  .sp-megamenu-parent > li > a,
  .sp-megamenu-parent > li > span {
    font-size: 0.85em;
  }
  #sp-menu .loginBtn.btn {
    min-height: 40px;
  }
}

@media only screen and (max-width: 1300px) {
  #sp-logo .logo {
    width: 150px;
    height: auto;
  }
}
@media only screen and (max-width: 1199px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: none !important;
  }
  #sp-menu .nav-myacc {
    display: none;
  }

  .playresfix,
  #sp-left {
    display: none;
  }
  #sp-component.col-lg-9 {
    width: 100%;
  }
  #offcanvas-toggler {
    display: flex !important;
  }
  #sp-menu .sp-megamenu-wrapper {
    display: none !important;
  }
  #sp-header .sp-module.user-top-info {
    margin-left: auto;
  }
  /* #sp-feature .sp-module-content {width: 90%;} */
  #sp-feature .sp-module-content .offer-group-wrap img {
    display: none;
  }
  #sp-feature .offer-wrap.type1 {
    grid-area: 1/1/2/3;
    text-align: left;
  }
  #sp-feature .offer-wrap.type2 {
    grid-area: 2/1/3/2;
  }
  #sp-feature .offer-wrap.type3 {
    text-align: left;
  }

  #sp-header .logo a img {
    padding: 0;
  }
  #sp-header .logo a:after {
    display: none;
  }
  #sp-logo {
    position: relative !important;
    transform: none;
    top: 0;
    left: 0;
    width: 160px;
    flex: 0 0 160px;
  }
  #sp-header.header-sticky #sp-logo .logo a img {
    padding: 5px;
  }
  #sp-header.header-sticky #sp-logo {
    flex: 0 0 140px;
    width: 140px;
  }
  /* .offcanvas-menu .logo { max-width: 55%; } */
  #sp-bottom1 {
    width: 75%;
  }
  #sp-bottom2 {
    display: none;
  }
  .game-card-list .game-card-wrap {
    flex: 1 0 33.33%;
    max-width: 33.33%;
  }
}

@media only screen and (max-width: 991px) {
  #sp-logo .logo {
    /* width: 70px;
        height: 70px;*/
    margin-left: 12px;
  }
  .promo-strip {
    flex-wrap: wrap;
    padding: 15px 15px 0;
    align-items: flex-start;
  }
  .promo-strip .img-wrap2 {
    margin: 15px auto 0;
    align-self: flex-end;
  }
  #sp-logo .logo .logo-image {
    padding: 2px;
  }
  .block4-icons {
    display: flex;
    padding: 0;
  }
  .block4-icons .img3 {
    border-top: none;
  }
  .game-list-wrap .game-list img {
    height: 100px;
  }
  .game-list-wrap.type2 .game-list {
    grid-template: auto / repeat(4, 1fr);
  }
  .modal-dialog .modal-left-banner {
    display: none;
  }
  .modal-dialog .modal-right-signup {
    margin-left: 0;
    width: 100%;
    min-height: auto;
  }
  .modal-right-signup .login-form form .new-account-link {
    position: static;
  }
  #transaction-details-form .filter.select_type .form-group label {
    margin-bottom: 12px;
    margin-top: -30px;
  }
  .offcanvas-menu .offcanvas-inner ul.menu > li > a,
  .offcanvas-menu .offcanvas-inner ul.menu > li > span {
    font-size: 14px;
  }
  #sp-bottom1 {
    width: 100%;
  }
  .promo-strip .img-wrap1 img,
  .promo-strip .img-wrap2 img {
    max-width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 1.7rem;
  }
  #sp-position2 .rownav-tabs-wrapper {
    margin: 1rem -15px;
  }
  .block4-app-wrap .block4-icons {
    margin-bottom: 0px;
    display: grid;
  }
  .game-list-wrap.type2 .game-list {
    grid-template: auto / repeat(3, 1fr);
  }
  .offer-group-wrap {
    grid-template: repeat(3, 1fr) / repeat(1, 1fr);
  }
  #sp-feature .offer-wrap.type1 {
    grid-area: 1/1/2/2;
  }
  #sp-feature .offer-wrap.type3 {
    grid-area: 3/1/4/2;
  }
  #sp-feature:before,
  #sp-feature:after {
    background-size: auto;
    background-position: right center;
    transform: translateX(-50%);
  }
  #sp-feature:after {
    transform: translateX(50%) scaleX(-1);
  }
  #sp-menu .nav-myacc {
    display: none;
  }
  .user-top-info .top-user-pic {
    display: none;
  }
  .top-user-wallet .user-balance .balance-icon {
    display: none;
  }
  #sp-header {
    height: 60px;
  }
  .header-sticky #sp-logo .logo {
    width: 80px;
    height: auto;
    top: 5px;
  }
  #sp-menu {
    height: 60px;
    position: absolute !important;
    top: 0;
    right: 0;
  }
  #sp-logo {
    width: 120px;
    flex: 0 0 120px;
  }
  /* .logo-image {height: 56px;} */
  #sp-header .logo {
    /* padding: 8px; */
    /* height: 56px; */
    top: 0;
  }
  .top-user-wallet .user-balance .balance-info label {
    font-size: var(--small-font);
    font-weight: 400;
  }
  .notification-info {
    width: 38px;
    height: 38px;
    aspect-ratio: 1/1;
  }
  .notification-info img {
    max-width: 80%;
    margin: 0 auto;
  }
  /* #sp-menu {padding: 0;} */
  body.ltr #offcanvas-toggler.offcanvas-toggler-right {
    margin-left: 15px;
  }

  .top-user-wallet .user-add-balance {
    padding: 4px 15px;
    min-width: auto;
  }
  .top-user-wallet .user-add-balance:before {
    display: none;
  }
  #sp-bottom2 {
    margin-top: 15px;
  }
  .block4-icons .img3 {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
  }
  .app-icon-wrap {
    display: flex;
    justify-content: center;
  }

  .myaccount_body_section
    .inbox
    .mail_item.unread
    .mail_shortinfo
    .mail_title
    .mail_date {
    font-weight: 400;
    opacity: 0.6;
  }
  .myaccount_body_section .inbox .mail_item .mail_shortinfo .mail_title {
    flex-direction: column;
    align-items: flex-start;
  }
  .myaccount_body_section .search_mail {
    flex-direction: column;
  }
  /* .h2-title{
        font-size: 1.5rem;
    } */
  /* .range-search-button,
    .range-reset-button,
    .proceed-button{
        padding: 1rem 4rem;
        height: 4.4rem;
    } */
  /* .search_box .input-group input,
    .select_box select.custome_input,
    .form_item_holder input,
    .input-group-row .custome_input,
    .input-control{
        height: 4.4rem;
    } */
  .search_mail #delete_main,
  .search_mail .mail_actionbtn_inner a {
    line-height: 1.4;
  }
  .sp-module.desktop_slider {
    display: none;
  }
  .sp-module.mobile_slider {
    display: block;
  }
  .misc-page-row,
  form#contact-us-form,
  .transction_filter,
  #player-avatar-form,
  #player-profile-form {
    padding: 1.6rem 1.5rem;
  }
  .my-profile-user-wallet {
    width: calc(100% - 15px);
  }
  .myaccount_body_section .search_mail {
    padding: 1.6rem 1.5rem 0;
  }
  .myaccount_body_section .inbox {
    padding: 1.6rem 1.5rem;
  }
  #player-profile-form .row .col-xs-12,
  #player-profile-form .row .col-md-4 {
    width: 100% !important;
  }
  #myticket > .col-sm-6 {
    width: 100%;
  }
  .transaction-date-range-tabs #lastweektxn,
  .transaction-date-range-tabs #lastmonthtxn {
    display: none;
  }
  .transaction-date-range-view span.date-label {
    margin-bottom: 12px;
  }

  #transaction-details-form .filter.select_type .form-group label {
    margin-top: 0;
  }
  #transaction-details-form .filter {
    width: 100%;
  }
  h2.transaction-title span {
    font-size: 0.85em;
    opacity: 0.6;
  }
  .faqOuterWrap {
    margin: 0 -15px;
  }
  .panel .panel-heading h4:after {
    right: 5px;
  }
  .game-card-list-wrap.hori-list .game-card-list .game-card-wrap {
    flex: 1 0 100%;
    max-width: 100%;
  }
  .game-card-list .game-card-wrap {
    flex: 1 0 50%;
    max-width: 50%;
  }
  /*.game-card-list-wrap:not(.hori-list) .game-card-list .game-card-wrap .game .game-img {
        max-width: 120px;
    }*/
  button.ctab {
    margin: 2px 2px 5px;
  }

  /*.game-card-list .game-card-wrap .game .game-link .btn {
        min-width: 110px;
    }*/
  .game-card-list-wrap:not(.hori-list) .game-card-list .game-card-wrap {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 15px;
    padding-bottom: 0;
  }
  .game-card-list .game-card-wrap .game-card {
    border-radius: 15px;
  }
  .game-card-list-wrap:not(.hori-list) .game-card-list .game-card-wrap .game {
    padding: 8px 8px 0;
    padding-top: 26%;
  }
  .game-card-list .game-card-wrap .ga-label img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .game-card-list-wrap.hori-list .game-card-list .game-card-wrap .game {
    padding: 8px;
  }
  .game-card-list-wrap.hori-list
    .game-card-list
    .game-card-wrap
    .game
    .game-img
    img {
    border-radius: 10px;
  }
  .game-card-list .game-card-wrap .game .game-img {
    width: calc(100% + 30px);
    position: static;
    transform: none;
    margin: -15px -15px 15px;
  }

  .game-card-list-wrap:not(.hori-list) .game-card-list .game-card-wrap .game {
    padding-top: 8px;
  }

  .game-card-list .game-card-wrap .game .game-img img {
    border-radius: 0;
  }

  .game-card-list .game-card-wrap .game-card {
    overflow: hidden;
  }
}

@media only screen and (max-width: 560px) {
  .game-card-list-wrap.hori-list
    .game-card-list
    .game-card-wrap
    .game
    .game-img {
    margin-right: -15px;
  }
  .section-title-wrap {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  .promo-strip {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .promo-strip .text-wrap button {
    margin: auto;
  }
  .promo-strip .text-wrap {
    padding: 25px 0px;
  }

  .game-card-list .game-card-wrap .game {
    display: block;
  }
  .game-card-list .game-card-wrap .game .ball-count,
  .game-card-list .game-card-wrap .game .head-count,
  .game-card-list .game-card-wrap .game .tkt-prize,
  .game-card-list .game-card-wrap .game .max-prize {
    flex-direction: row;
    justify-content: center;
  }

  .game-list-wrap.type2 .game-list {
    grid-template: auto / repeat(2, 1fr);
  }
  #sp-feature:before,
  #sp-feature:after {
    background-size: 80%;
  }
  .myTicketInnerWrap .myTicketInnerWrap2 {
    border-bottom: 2px dashed #29275c;
    border-left: none;
    width: 100%;
    min-height: auto;
  }
  .myTicketInnerWrap {
    flex-direction: column-reverse;
  }
  .myTicketInnerWrap .myTicketInnerWrap1 {
    width: 100%;
  }
  .myTicketInnerWrap .gameTypeIconTic img {
    max-height: 65px;
  }
  #transaction-details-form {
    flex-direction: column;
  }
}

@media only screen and (max-width: 379px) {
  #sp-logo {
    width: 90px;
    flex: 0 0 90px;
  }
}

@media only screen and (max-width: 1800px) {
  .game-list-wrap .game-list img {
    height: 140px;
  }
}
@media only screen and (max-width: 1700px) {
  .game-list-wrap .game-list img {
    height: 120px;
  }
}
@media only screen and (max-width: 1599px) {
  .game-list-wrap .game-list img {
    height: 135px;
  }
}
@media only screen and (max-width: 1500px) {
  .game-list-wrap .game-list img {
    height: 120px;
  }
}
@media only screen and (max-width: 1199px) {
  .game-list-wrap .game-list img {
    height: 105px;
  }
}
@media only screen and (max-width: 991px) {
  .game-list-wrap .game-list img {
    height: 100px;
  }
}
@media only screen and (max-width: 400px) {
  .game-list-wrap .game-list img {
    height: 85px;
  }
}

#sp-left {
  margin-top: 100px;
}

#home_login .modal-dialog,
#home_forgot .modal-dialog,
#home_register_popup-otp .modal-dialog {
  max-width: 500px;
}

.gamePage #sp-main-body .container {
  max-width: none;
  padding: 0;
}

.coming-soon .pretitle {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0.4rem;
  position: relative;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

.coming-soon .pretitle:before {
  content: "";
  display: block;
  height: 2px;
  width: 40px;
  background-color: #ffffff;
  opacity: 1;
  position: absolute;
  top: 120%;
  left: 50%;
  margin-left: -20px;
  right: calc(100% + 1.6rem);
}

.coming-soon .huge-title {
  font-weight: 300;
  font-size: 5rem;
  line-height: 1.2;
  margin-top: 20px;
}

.coming-soon {
  text-align: center;
  background-color: #404c91;
  color: #ffffff;
  border-radius: 20px;
  padding: 50px 20px;
  margin-top: 30px;
}

.btn.btn-outline {
  border: 2px solid #ffffff;
  min-height: 40px;
  min-width: 140px;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-shadow: -1px -1px 0px rgba(0, 0, 0, 0.2),
    1px 1px 0px rgba(255, 255, 255, 0.2);
}

.coming-soon .button-wrap {
  margin-top: 30px;
}

body.gamePage {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  overflow: hidden;
}
.bingoframe {
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
}
body.gamePage .bingoframe iframe {
  width: 100% !important;
  min-height: 100%;
  height: 100% !important;
}

.bounceballs {
  width: 150px;
  margin: 0 auto;
  margin-top: 110px;
  height: 1px;
  position: relative;
  overflow: visible;
}
.bounceballs .ballWrap {
  position: absolute;
  bottom: 0;
  width: 44px;
}
.bounceballs .ballWrap.bbw1 {
  left: 0;
}
.bounceballs .ballWrap.bbw2 {
  left: 50%;
  transform: translateX(-50%);
}
.bounceballs .ballWrap.bbw2:after {
  animation-delay: 0.2s;
}
.bounceballs .ballWrap.bbw3 {
  right: 0;
}
.bounceballs .ballWrap.bbw3:after {
  animation-delay: 0.4s;
}
.bounceballs .ballWrap:after {
  content: "";
  position: absolute;
  bottom: 4px;
  width: 54px;
  height: 5px;
  border-radius: 50%;
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    #000 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    #000 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: radial-gradient(
    ellipse at center,
    #000 0%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00ffffff',GradientType=1 );
  filter: blur(2px);
  -webkit-filter: blur(2px);
  opacity: 0.26;
  left: -5px;
  right: 0;
  animation: bounceBallShadowAnimate 0.4s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
  transform-origin: center center;
}
.bounceballs .ball {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.bounceballs .ball img {
  width: 44px;
  height: 44px;
}
.bounceballs .ball.ball1 {
  animation: bounceBallAnimate 0.4s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
}
.bounceballs .ball.ball2 {
  animation: bounceBallAnimate 0.4s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
  animation-delay: 0.2s;
}
.bounceballs .ball.ball3 {
  animation: bounceBallAnimate 0.4s;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
  animation-iteration-count: infinite;
  animation-delay: 0.4s;
}

@keyframes bounceBallAnimate {
  from {
    bottom: 60px;
  }

  to {
    bottom: 0px;
  }
}
@keyframes bounceBallShadowAnimate {
  from {
    transform: scale(1);
    opacity: 0.26;
  }

  to {
    transform: scale(0.4);
    opacity: 0.85;
  }
}

.pagenotfound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('../public/images/icons/calendar_icon.png') no-repeat;
    width: 25px;
    height: 25px;
    border-width: thin
} */

input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("../public/images/icons/calendar_icon.png") no-repeat;
  background-position: right;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 6px;
  top: 0;
  width: auto;
}

/* input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
}
input[type=date]:focus::-webkit-datetime-edit {
    color: black !important;
} */

.bg-body-tertiary {
  background: none !important;
}
.offcanvas-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.offcanvas-cross-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.flymenu-dropdown-icon {
  position: relative;
  top: 25px;
  left: -30px;
  height: 21px;
  width: 21px;
}
.edit-profile-btn {
  display: flex;
  justify-content: center;
}
.my-profile-user-avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Make the iframe responsive */
.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 100% of viewport height */
}

.responsive-iframe {
  width: 80vw; /* 80% of viewport width */
  height: 80vh; /* 80% of viewport height */
  border: none;
}
