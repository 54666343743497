@charset "UTF-8"; :root {
    --header_height: 70px;
}

body {
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

body.helix-ultimate-preloader {
    overflow: hidden
}

body.helix-ultimate-preloader:before {
    content: " ";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99998;
    background: rgba(255, 255, 255, 0.9)
}

body.helix-ultimate-preloader:after {
    content: "";
    font-family: "FontAwesome";
    font-size: 36px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
    width: 48px;
    height: 48px;
    line-break: 48px;
    text-align: center;
    color: #007bff;
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
    z-index: 99999
}

a {
    transition: color 400ms, background-color 400ms
}

a,a:hover,a:focus,a:active {
    text-decoration: none
}

label {
    font-weight: normal
}

legend {
    padding-bottom: 10px
}

img {
    display: block;
    max-width: 100%;
    height: auto
}

.row {
    --bs-gutter-x: 1.875rem
}

.container {
    padding-left: 15px;
    padding-right: 15px
}

.element-invisible {
    position: absolute;
    padding: 0;
    margin: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden
}

#sp-top-bar {
    padding: 8px 0;
    font-size: 0.875rem
}

#sp-top-bar .sp-module {
    display: inline-block;
    margin: 0 0 0 1.25rem
}

ul.social-icons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block
}

ul.social-icons>li {
    display: inline-block;
    line-height: 1.5
}

ul.social-icons>li:not(:last-child) {
    margin-right: 20px
}

.flex-auto {
    flex: auto;
}

#sp-menu.menu-with-social .sp-megamenu-wrapper {
    flex: auto;
    justify-content: space-between;
    display: flex;
    align-items: center
}

#sp-menu>.sp-column {
    height: 100%
}

#sp-menu .social-wrap {
    position: relative;
    padding-left: 60px
}

#sp-menu .social-wrap:after {
    content: "";
    margin-top: 10px;
    margin-bottom: 10px;
    position: absolute;
    width: 2px;
    height: calc(var(--header_height) - 20px);
    left: 30px;
    background-color: rgba(80, 80, 80, 0.2)
}

#sp-menu ul.social-icons>li:not(:last-child) {
    margin-right: 20px
}

#sp-menu ul.social-icons a {
    color: #9c9c9c
}

#sp-menu .social-wrap.no-border ul.social-icons {
    border-left: none;
    padding-left: 0px
}

#sp-menu.menu-center .sp-megamenu-wrapper {
    justify-content: center
}

.menu-flex .sp-megamenu-wrapper {
    display: flex;
    justify-content: flex-end
}

#menu-right .sp-module:first-child {
    border-right: 2px solid rgba(80, 80, 80, 0.2);
    padding-right: 30px
}

.header-has-modules #offcanvas-toggler {
    margin-right: 20px;
    margin-left: 0
}

.sp-module-content .mod-languages ul.lang-inline {
    margin: 0;
    padding: 0
}

.sp-module-content .mod-languages ul.lang-inline li {
    border: none;
    display: inline-block;
    margin: 0 5px 0 0
}

.sp-module-content .mod-languages ul.lang-inline li a {
    padding: 0 !important
}

.sp-module-content .mod-languages ul.lang-inline li>a:before {
    display: none
}

.sp-module-content .mod-languages img {
    display: inline-block;
    border: none
}

.sp-module-content .latestnews>li>a>span {
    display: block;
    margin-top: 5px;
    font-size: 85%
}

div.mod-languages ul li.lang-active {
    background-color: #82828280 !important;
    padding: 0px 5px 3px;
    border-radius: 3px
}

.sp-contact-info {
    list-style: none;
    padding: 0;
    margin: 0px
}

.sp-contact-info li {
    display: inline-block;
    margin: 0px 10px 0px 0px;
    font-size: 90%;
    white-space: nowrap
}

.sp-contact-info li i {
    margin: 0 3px
}

#sp-header {
    height: 80px;
    width: 100%;
    position: relative;
    z-index: 99;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    animation: spFadeIn 0.5s
}

#sp-header #offcanvas-toggler.offcanvas,#sp-header #offcanvas-toggler.mega {
    display: none !important
}

#sp-header.full-header-left #offcanvas-toggler.offcanvas,#sp-header.full-header-center #offcanvas-toggler.offcanvas,#sp-header.header-with-modal-menu #offcanvas-toggler.offcanvas,#sp-header.lg-header #offcanvas-toggler.offcanvas,#sp-header.header-with-social #offcanvas-toggler.offcanvas {
    display: flex !important
}

/* #sp-header.full-header-left .menu-with-offcanvas #offcanvas-toggler,#sp-header.full-header-center .menu-with-offcanvas #offcanvas-toggler,#sp-header.header-with-modal-menu .menu-with-offcanvas #offcanvas-toggler,#sp-header.lg-header .menu-with-offcanvas #offcanvas-toggler,#sp-header.header-with-social .menu-with-offcanvas #offcanvas-toggler {
    display: none !important
} */

#sp-header.full-header {
    padding-left: 45px;
    padding-right: 45px
}

#sp-header.lg-header {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1)
}

#sp-header.lg-header .logo {
    height: auto
}

#sp-header.lg-header #offcanvas-toggler {
    margin-right: 20px;
    margin-left: 0px
}

#sp-header.lg-header #offcanvas-toggler.offcanvas-toggler-right.offcanvas {
    display: none !important
}

#sp-header.lg-header .sp-column {
    height: 100%
}

#sp-header.lg-header #sp-logo #offcanvas-toggler {
    display: none
}

#sp-header.header-with-modal-menu {
    color: #252525
}

#sp-header.header-with-modal-menu a {
    color: rgba(37, 37, 37, 0.6)
}

#sp-header.header-with-modal-menu a:hover,#sp-header.header-with-modal-menu a:focus {
    color: #ec430f
}

#sp-header.header-with-modal-menu .sp-contact-info>li {
    margin-right: 15px;
    opacity: 0.7;
    transition: 0.3s
}

#sp-header.header-with-modal-menu .sp-contact-info>li:hover,#sp-header.header-with-modal-menu .sp-contact-info>li:focus {
    opacity: 1
}

#sp-header.header-with-modal-menu .sp-contact-info>li>span {
    margin-right: 5px
}

#sp-header.header-with-modal-menu #offcanvas-toggler {
    display: none !important
}

#sp-header.header-with-modal-menu.center-layout .modules-wrapper {
    height: auto;
    border: none;
    padding: 0px;
    max-width: 730px;
    margin: 0 auto
}

#sp-header.header-with-modal-menu.center-layout #modal-menu .modal-menu-inner>.container {
    align-items: flex-start;
    padding: 170px 0 0
}

#sp-header.header-with-modal-menu.center-layout #modal-menu .search {
    margin-bottom: 80px
}

#sp-header.header-with-modal-menu.center-layout #modal-menu .search form:after {
    font-size: 16px
}

#sp-header.header-with-modal-menu.center-layout #modal-menu .search form:hover:after {
    display: none
}

#sp-header.header-with-modal-menu.center-layout #modal-menu .search form input {
    background: transparent;
    border-bottom: 2px solid rgba(32, 31, 31, 0.1);
    font-size: 26px;
    padding: 7px;
    border-radius: 0px
}

#sp-header.header-with-modal-menu.center-layout #modal-menu .search form input::placeholder {
    color: rgba(32, 31, 31, 0.4)
}

#sp-header.header-with-modal-menu.center-layout #modal-menu .search form input:focus,#sp-header.header-with-modal-menu.center-layout #modal-menu .search form input:active,#sp-header.header-with-modal-menu.center-layout #modal-menu .search form input:active:focus {
    box-shadow: none;
    border-bottom: 2px solid rgba(32, 31, 31, 0.7)
}

#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper {
    justify-content: center
}

#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper ul.sp-megamenu-parent {
    flex-direction: row
}

#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li>a,#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li>span {
    padding: 0
}

#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li>a:after,#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li>span:after {
    top: 100%;
    width: 2px;
    height: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin: 10px 0 0 0
}

#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li.sp-has-child:hover>a:after,#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li.sp-has-child:hover>span:after {
    height: 30px
}

#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li.sp-has-child>.sp-dropdown {
    animation: spFadeIn 0.5s
}

#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li:not(:last-child)>a,#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li:not(:last-child)>span {
    margin-right: 70px
}

#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li>.sp-dropdown.sp-dropdown-main {
    top: 100%;
    padding-top: 50px;
    padding-left: 0;
    left: auto !important;
    right: 0px;
    transition: 0.35s
}

#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li>.sp-dropdown.sp-dropdown-main li.sp-menu-item>a,#sp-header.header-with-modal-menu.center-layout .sp-megamenu-wrapper .sp-megamenu-parent>li>.sp-dropdown.sp-dropdown-main li.sp-menu-item>span {
    padding: 6px 0
}

#sp-header.header-with-modal-menu.classic-layout .modal-menu-inner>div {
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 0
}

#sp-header.header-with-modal-menu.classic-layout .modal-menu-inner>div .sp-megamenu-wrapper {
    height: 100%;
    align-items: center
}

#sp-header.header-with-modal-menu.classic-layout .modal-menu-inner>div .social-icons {
    margin-top: auto;
    margin-bottom: 0px;
    width: 100%;
    border-top: 2px solid rgba(32, 31, 31, 0.1);
    padding-top: 20px;
    font-size: 12px
}

#sp-header .logo {
    height: 80px;
    display: inline-flex;
    margin: 0;
    align-items: center
}

#sp-header .logo a {
    font-size: 24px;
    line-height: 1;
    margin: 0;
    padding: 0
}

#sp-header .logo p {
    margin: 5px 0 0
}

#sp-header.header-sticky {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    animation: spFadeInDown 0.5s
}

#sp-header .sp-module {
    margin-left: 20px
}

#sp-header nav.sp-megamenu-wrapper #offcanvas-toggler {
    display: none
}

#sp-header-topbar {
    position: relative;
    z-index: 111
}

#sp-header-topbar .sp-profile-wrapper .sp-sign-in .user-text {
    display: none !important
}

#sp-header-topbar #sp-logo #offcanvas-toggler {
    display: none !important
}

#sp-header-topbar .container-inner {
    border-bottom: 1px solid #e5e5e5
}

#sp-header-topbar .container-inner>.row {
    min-height: 80px
}

#sp-header-topbar a {
    color: #909196
}

#sp-header-topbar a:hover {
    color: #ec430f
}

#sp-header-topbar .sp-contact-info {
    color: #909196
}

.header-modules {
    margin-left: 20px
}

.header-modules .sp-module {
    margin-left: 20px
}

.header-modules .search {
    position: relative
}

.header-modules .search form:after {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    top: 0;
    font-size: 12px;
    line-height: 32px;
    right: 10px
}

.header-modules .search form input {
    background: #efeff0;
    border: none;
    height: 32px;
    font-size: 15px;
    border-radius: 3px;
    padding: 5px 10px
}

.burger-icon {
    width: 25px;
    cursor: pointer
}

.burger-icon>span {
    display: block;
    height: 2px;
    background-color: #000;
    margin: 5px 0;
    transition: background-color 0.3s ease-in, opacity 0.3s ease-in, transform 0.3s ease-in
}

.offcanvas-active .burger-icon>span,#modal-menu-toggler.active .burger-icon>span {
    background-color: #000
}

.offcanvas-active .burger-icon>span:nth-child(1),#modal-menu-toggler.active .burger-icon>span:nth-child(1) {
    transform: translate(0, 8px) rotate(-45deg)
}

.offcanvas-active .burger-icon>span:nth-child(2),#modal-menu-toggler.active .burger-icon>span:nth-child(2) {
    opacity: 0
}

.offcanvas-active .burger-icon>span:nth-child(3),#modal-menu-toggler.active .burger-icon>span:nth-child(3) {
    transform: translate(0, -5px) rotate(45deg)
}

.offcanvas-active .close-offcanvas .burger-icon>span {
    transition-delay: 0.2s
}

body.modal-menu-active {
    overflow-y: hidden
}

#modal-menu {
    background: #f9f9f9;
    position: fixed !important;
    top: 80px;
    left: 0;
    right: 0;
    transition: 0.5s;
    bottom: 120%;
    overflow: hidden;
    display: flex;
    z-index: 10
}

#modal-menu.has-bg {
    /* background-image: url(../images/menu-bg.jpg); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center
}

#modal-menu.active {
    bottom: 0
}

#modal-menu .sp-megamenu-parent>li>a,#modal-menu .sp-megamenu-parent>li>span {
    display: block
}

#modal-menu .sp-megamenu-parent>li>a:after,#modal-menu .sp-megamenu-parent>li>span:after {
    transition: 0.3s
}

#modal-menu .sp-megamenu-parent>li:hover>a,#modal-menu .sp-megamenu-parent>li:hover>span,#modal-menu .sp-megamenu-parent>li.active>a,#modal-menu .sp-megamenu-parent>li.active>span {
    color: #ec430f
}

#modal-menu .sp-megamenu-parent>li:hover>a:after,#modal-menu .sp-megamenu-parent>li:hover>span:after,#modal-menu .sp-megamenu-parent>li.active>a:after,#modal-menu .sp-megamenu-parent>li.active>span:after {
    opacity: 1
}

#modal-menu .sp-megamenu-parent>li>a .nav-counter,#modal-menu .sp-megamenu-parent>li>span .nav-counter {
    font-size: 16px
}

#modal-menu .sp-megamenu-parent>li:hover>a:before,#modal-menu .sp-megamenu-parent>li.active>a:before {
    width: 0;
    opacity: 0
}

#modal-menu .modal-menu-inner {
    position: relative !important;
    width: 100%
}

#modal-menu .modal-menu-inner #menu-dismiss {
    position: absolute;
    top: 60px;
    left: 60px;
    cursor: pointer
}

#modal-menu .modal-menu-inner #menu-dismiss svg {
    font-size: 55px
}

#modal-menu .modal-menu-inner>.container {
    display: flex;
    padding: 70px 0;
    align-items: center;
    height: 100%
}

#modal-menu .modal-menu-inner>.container>.row {
    width: 100%
}

#modal-menu ul.sp-megamenu-parent {
    display: flex !important;
    flex-direction: column
}

#modal-menu ul.sp-megamenu-parent>li {
    position: relative
}

#modal-menu ul.sp-megamenu-parent>li:not(:last-child) {
    margin-bottom: 30px
}

#modal-menu ul.sp-megamenu-parent>li>a,#modal-menu ul.sp-megamenu-parent>li>span {
    font-size: 26px;
    font-weight: 600;
    line-height: 1.4;
    text-transform: initial
}

#modal-menu ul.sp-megamenu-parent li.sp-has-child>a,#modal-menu ul.sp-megamenu-parent li.sp-has-child>span {
    position: relative
}

#modal-menu ul.sp-megamenu-parent li.sp-has-child>a:after,#modal-menu ul.sp-megamenu-parent li.sp-has-child>span:after {
    content: "";
    font-family: "Font Awesome 5 Free";
    float: right;
    position: absolute;
    top: 2px
}

#modal-menu ul.sp-megamenu-parent li.sp-has-child:hover>a:after,#modal-menu ul.sp-megamenu-parent li.sp-has-child:hover>span:after {
    transform: translateX(5px)
}

#modal-menu ul.sp-megamenu-parent li.sp-has-child:hover>.sp-dropdown {
    animation: spFadeInDown 0.5s
}

#modal-menu ul.sp-megamenu-parent .sp-dropdown {
    left: 100% !important;
    transition: 0.3s;
    padding-left: 20px
}

#modal-menu ul.sp-megamenu-parent .sp-dropdown.sp-dropdown-main {
    top: 0px
}

#modal-menu ul.sp-megamenu-parent .sp-dropdown.sp-dropdown-mega {
    top: auto;
    bottom: 0px
}

#modal-menu ul.sp-megamenu-parent .sp-dropdown .sp-dropdown-inner {
    box-shadow: none;
    border-radius: 0px;
    position: relative;
    overflow: visible !important;
    max-height: initial;
    background-color: #fff;
    padding: 20px
}

#modal-menu ul.sp-megamenu-parent .sp-dropdown .sp-dropdown-inner ul>li>a {
    font-weight: 500 !important;
    font-size: 15px !important
}

#modal-menu .modules-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    max-width: 330px;
    border-left: 2px solid rgba(32, 31, 31, 0.1);
    padding: 30px;
    color: #252525
}

#modal-menu .modules-wrapper a {
    color: #252525
}

#modal-menu .modules-wrapper a:hover,#modal-menu .modules-wrapper a:focus {
    color: #ec430f
}

#modal-menu .modules-wrapper .sp-module {
    margin-left: 0;
    margin-bottom: 15px
}

#modal-menu .modules-wrapper .search form {
    margin-bottom: 30px
}

#modal-menu .modules-wrapper .search form input {
    height: 40px
}

#modal-menu .modules-wrapper .sp-contact-info {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column
}

#modal-menu .modules-wrapper .sp-contact-info>li {
    margin-bottom: 35px
}

#modal-menu .modules-wrapper .sp-contact-info>li>span {
    font-size: 12px;
    margin-bottom: 10px
}

#modal-menu .modules-wrapper .sp-contact-info>li>a {
    opacity: 0.7
}

#modal-menu .modules-wrapper .sp-contact-info>li>a:hover,#modal-menu .modules-wrapper .sp-contact-info>li>a:focus {
    opacity: 1
}

#modal-menu .modules-wrapper .social-icons {
    font-size: 14px
}

#modal-menu .modules-wrapper .social-icons a {
    opacity: 0.7
}

#modal-menu .modules-wrapper .social-icons a:hover,#modal-menu .modules-wrapper .social-icons a:focus {
    opacity: 1
}

#sp-logo.has-border {
    position: relative;
    padding-right: 45px
}

#sp-logo.has-border:after {
    content: "";
    margin-top: 10px;
    margin-bottom: 10px;
    position: absolute;
    width: 2px;
    height: calc(var(--header_height) - 20px);
    right: 15px;
    background-color: rgba(80, 80, 80, 0.2)
}

#sp-logo.has-border .sp-column {
    display: inline-flex;
    align-items: center
}

.sp-profile-wrapper {
    position: relative;
    font-size: 14px
}

.sp-profile-wrapper .arrow-icon {
    font-size: 11px
}

.sp-profile-wrapper .sp-profile-dropdown {
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    padding: 10px 15px !important;
    list-style: none !important;
    font-size: 14px;
    position: absolute;
    min-width: 100px;
    right: 0;
    top: 100%;
    display: none !important
}

.sp-profile-wrapper .sp-profile-dropdown>li:not(:last-child) {
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 5px;
    margin-bottom: 5px;
    line-height: 1.3
}

.sp-profile-wrapper:hover .sp-profile-dropdown {
    display: block !important;
    animation: spFadeInDown 0.5s
}

#sp-title {
    min-height: 0
}

.sp-page-title {
    padding: 30px 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    background-attachment: fixed
}

.sp-page-title .sp-page-title-heading,.sp-page-title .sp-page-title-sub-heading {
    margin: 0;
    padding: 0;
    color: #fff
}

.sp-page-title .sp-page-title-heading {
    font-size: 24px;
    line-height: 1
}

.sp-page-title .sp-page-title-sub-heading {
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    margin-top: 10px
}

.sp-page-title .breadcrumb {
    background: none;
    padding: 0;
    margin: 10px 0 0 0
}

.sp-page-title .breadcrumb>.active {
    color: rgba(255, 255, 255, 0.8)
}

.sp-page-title .breadcrumb>span,.sp-page-title .breadcrumb>li,.sp-page-title .breadcrumb>li+li:before,.sp-page-title .breadcrumb>li>a {
    color: #fff
}

.breadcrumb {
    padding: 0.75rem 1rem;
    background-color: #e9ecef;
    border-radius: 0.25rem
}

.body-innerwrapper {
    overflow-x: hidden
}

.layout-boxed .body-innerwrapper {
    max-width: 1240px;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2)
}

#sp-main-body {
    padding: 100px 0
}

body.view-article.remove-container .body-innerwrapper>#sp-main-body>.container,body.view-article.remove-container .body-innerwrapper>.sp-main-body>.container {
    max-width: 100%;
    padding: 0
}

.com-sppagebuilder #sp-main-body {
    padding: 0
}

#sp-left .sp-module,#sp-right .sp-module {
    margin-top: 50px;
    border: 1px solid #f3f3f3;
    padding: 30px;
    border-radius: 3px
}

#sp-left .sp-module:first-child,#sp-right .sp-module:first-child {
    margin-top: 0
}

#sp-left .sp-module .sp-module-title,#sp-right .sp-module .sp-module-title {
    margin: 0 0 30px;
    padding-bottom: 20px;
    font-size: 22px;
    font-weight: 700;
    border-bottom: 1px solid #f3f3f3
}

#sp-left .sp-module ul,#sp-right .sp-module ul {
    list-style: none;
    padding: 0;
    margin: 0
}

#sp-left .sp-module ul>li,#sp-right .sp-module ul>li {
    display: block;
    border-bottom: 1px solid #f3f3f3
}

#sp-left .sp-module ul>li>a,#sp-right .sp-module ul>li>a {
    display: block;
    padding: 5px 0;
    line-height: 36px;
    padding: 2px 0
}

#sp-left .sp-module ul>li:last-child,#sp-right .sp-module ul>li:last-child {
    border-bottom: none
}

#sp-left .sp-module .categories-module ul,#sp-right .sp-module .categories-module ul {
    margin: 0 10px
}

#sp-left .sp-module .latestnews>div,#sp-right .sp-module .latestnews>div {
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #e8e8e8
}

#sp-left .sp-module .latestnews>div:last-child,#sp-right .sp-module .latestnews>div:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0
}

#sp-left .sp-module .latestnews>div>a,#sp-right .sp-module .latestnews>div>a {
    display: block;
    font-weight: 400
}

#sp-left .sp-module .tagscloud,#sp-right .sp-module .tagscloud {
    margin: -2px 0
}

#sp-left .sp-module .tagscloud .tag-name,#sp-right .sp-module .tagscloud .tag-name {
    display: inline-block;
    padding: 5px 10px;
    background: #808080;
    color: #fff;
    border-radius: 4px;
    margin: 2px 0
}

#sp-left .sp-module .tagscloud .tag-name span,#sp-right .sp-module .tagscloud .tag-name span {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px
}

.login img {
    display: inline-block;
    margin: 20px 0
}

.login .checkbox input[type="checkbox"] {
    margin-top: 6px
}

.form-links ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.hide-label {
    border: 0;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.search .btn-toolbar {
    margin-bottom: 20px
}

.search .btn-toolbar span.icon-search {
    margin: 0
}

.search .btn-toolbar button {
    color: #fff
}

.search .phrases .phrases-box .controls label {
    display: inline-block;
    margin: 0 20px 20px
}

.search .phrases .ordering-box {
    margin-bottom: 15px
}

.search .only label {
    display: inline-block;
    margin: 0 20px 20px
}

.search .search-results dt.result-title {
    margin-top: 40px
}

.search .search-results dt,.search .search-results dd {
    margin: 5px 0
}

.filter-search .chzn-container-single .chzn-single {
    height: 34px;
    line-height: 34px
}

.form-search .finder label {
    display: block
}

.form-search .finder .input-medium {
    width: 60%;
    border-radius: 4px
}

.finder .word input {
    display: inline-block
}

.finder .search-results.list-striped li {
    padding: 20px 0
}

.article-list .article {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #f5f5f5;
    border-radius: 3px
}

.article-list .article .article-intro-image,.article-list .article .article-featured-video,.article-list .article .article-featured-audio,.article-list .article .article-feature-gallery {
    margin: -20px -20px 20px -20px;
    border-radius: 3px 3px 0 0;
    border-bottom: 1px solid #f5f5f5;
    overflow: hidden
}

.article-list .article .article-intro-image img,.article-list .article .article-featured-video img,.article-list .article .article-featured-audio img,.article-list .article .article-feature-gallery img {
    border-radius: 3px 3px 0 0
}

.article-list .article .article-header h1,.article-list .article .article-header h2 {
    font-size: 1.375rem;
    margin: 0 0 1.25rem 0
}

.article-list .article .article-header h1 a,.article-list .article .article-header h2 a {
    color: #252525
}

.article-list .article .article-header h1 a:hover,.article-list .article .article-header h1 a:active,.article-list .article .article-header h1 a:focus,.article-list .article .article-header h2 a:hover,.article-list .article .article-header h2 a:active,.article-list .article .article-header h2 a:focus {
    color: #044CD0
}

.article-list .article .readmore {
    font-size: 0.875rem
}

.article-list .article .readmore a {
    color: #252525;
    font-weight: 500
}

.article-list .article .readmore a:hover,.article-list .article .readmore a:active,.article-list .article .readmore a:focus {
    color: #044CD0
}

.article-list .article figcaption.caption {
    text-align: center;
    font-size: 14px;
    color: #252525;
    margin: 5px 0px;
    opacity: 0.7
}

.related-article-large .article-image {
    width: 350px;
    min-width: 350px
}

.related-article-large .article-information {
    border-left: 1px solid #f1f1f1;
    padding-left: 20px;
    margin-left: 20px
}

.related-article-large .article-information>span {
    font-size: 0.813rem
}

.related-article-large .article-information>span+span::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    content: "/"
}

.related-article-large .article-information>span a {
    color: #252525
}

.related-article-large .article-information>span a:hover {
    color: #044CD0
}

.related-article-large .article-information .intro-text {
    margin-top: 20px
}

.article-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 1rem
}

.article-info>span {
    font-size: 0.813rem
}

.article-info>span+span::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    content: "/"
}

.article-info>span a {
    color: #252525
}

.article-info>span a:hover {
    color: #044CD0
}

.article-details .article-header {
    position: relative
}

.article-details .article-header h1,.article-details .article-header h2 {
    font-size: 2.25rem
}

.article-details .article-info {
    margin-bottom: 1rem
}

.article-details .article-full-image {
    margin-bottom: 2rem
}

.article-details .article-full-image img {
    display: inline-block;
    border-radius: 4px
}

.article-details .article-featured-video,.article-details .article-featured-audio,.article-details .article-feature-gallery {
    margin-bottom: 2rem
}

.article-details .article-ratings-social-share {
    padding: 1rem 0;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 1rem
}

.article-details .article-author-information {
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #f5f5f5
}

.article-details .article-can-edit {
    position: relative;
    margin-bottom: 10px
}

.article-details .article-can-edit a {
    display: inline-block;
    background: #2a61c9;
    color: #fff;
    padding: 5px 12px;
    border-radius: 3px;
    font-size: 14px
}

.article-details figcaption.caption {
    font-size: 14px;
    color: #252525;
    margin: 5px 0px;
    opacity: 0.7
}

.related-article-list-container {
    margin-top: 15px
}

.related-article-list-container .related-article-title {
    font-size: 22px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f3f3f3
}

.related-article-list-container ul.related-article-list {
    list-style: none;
    padding: 0
}

.related-article-list-container ul.related-article-list li {
    margin-bottom: 10px
}

.related-article-list-container ul.related-article-list li .article-header h2 {
    font-size: 1.2rem
}

.related-article-list-container ul.related-article-list li .article-header h2 a {
    color: #252525
}

.related-article-list-container ul.related-article-list li .article-header h2 a:hover {
    color: #044cd0
}

.related-article-list-container ul.related-article-list li .published {
    font-size: 14px
}

.tags {
    list-style: none;
    padding: 0;
    margin: -0.3125rem;
    display: flex;
    flex-wrap: wrap
}

.tags>li {
    display: inline-block;
    margin: 0.3125rem
}

.tags>li a {
    display: block;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    border-radius: 6.25rem
}

.tags>li a:hover {
    color: #fff
}

.pager {
    list-style: none;
    padding: 2rem 0 0 0;
    margin: 2rem 0 0 0;
    border-top: 1px solid #f5f5f5
}

.pager::after {
    display: block;
    clear: both;
    content: ""
}

.pager>li {
    display: inline-block
}

.pager>li.previous {
    float: left
}

.pager>li.next {
    float: right
}

.pager>li a {
    display: inline-block;
    font-size: 0.785rem;
    padding: 1rem 2.5rem;
    border-radius: 100px
}

#article-comments {
    padding-top: 2rem;
    margin-top: 2rem;
    border-top: 1px solid #f5f5f5
}

.article-ratings {
    display: flex;
    align-items: center
}

.article-ratings .rating-symbol {
    unicode-bidi: bidi-override;
    direction: rtl;
    font-size: 1rem;
    display: inline-block;
    margin-left: 5px
}

.article-ratings .rating-symbol span.rating-star {
    font-family: "Font Awesome 5 Free";
    font-weight: normal;
    font-style: normal;
    display: inline-block
}

.article-ratings .rating-symbol span.rating-star.active:before {
    content: "";
    font-weight: 900;
    color: #f6bc00
}

.article-ratings .rating-symbol span.rating-star:before {
    content: "";
    padding-right: 5px
}

.article-ratings .rating-symbol span.rating-star:hover:before,.article-ratings .rating-symbol span.rating-star:hover~span.rating-star:before {
    content: "";
    color: #e7b000;
    font-weight: 900;
    cursor: pointer
}

.article-ratings .ratings-count {
    font-size: 0.785rem;
    color: #4c4b4b
}

.article-ratings .fa-spinner {
    margin-right: 5px
}

.pagination-wrapper {
    align-items: center
}

.pagination-wrapper .pagination {
    margin-bottom: 0
}

.article-social-share {
    float: right
}

.article-social-share .social-share-icon ul {
    padding: 0;
    list-style: none;
    margin: 0
}

.article-social-share .social-share-icon ul li {
    display: inline-block
}

.article-social-share .social-share-icon ul li:not(:last-child) {
    margin-right: 10px
}

.article-social-share .social-share-icon ul li a {
    border: 1px solid #ededed;
    font-size: 1rem;
    border-radius: 50%;
    height: 34px;
    line-height: 34px;
    text-align: center;
    width: 34px;
    display: inline-block
}

.article-social-share .social-share-icon ul li a:hover,.article-social-share .social-share-icon ul li a:focus {
    color: #fff
}

.newsfeed-category .category {
    list-style: none;
    padding: 0;
    margin: 0
}

.newsfeed-category .category li {
    padding: 5px 0
}

.newsfeed-category #filter-search {
    margin: 10px 0
}

.category-module,.categories-module,.archive-module,.latestnews,.newsflash-horiz,.mostread,.form-links,.list-striped {
    list-style: none;
    padding: 0;
    margin: 0
}

.category-module li,.categories-module li,.archive-module li,.latestnews li,.newsflash-horiz li,.mostread li,.form-links li,.list-striped li {
    padding: 2px 0
}

.category-module li h4,.categories-module li h4,.archive-module li h4,.latestnews li h4,.newsflash-horiz li h4,.mostread li h4,.form-links li h4,.list-striped li h4 {
    margin: 5px 0
}

.article-footer-top {
    display: table;
    clear: both;
    width: 100%
}

.article-footer-top .post_rating {
    float: left
}

.contentpane:not(.com-sppagebuilder) {
    padding: 20px
}

#sp-bottom {
    padding: 60px 0 30px;
    font-size: 14px;
    line-height: 1.6
}

#sp-bottom .sp-module {
    margin-bottom: 30px
}

#sp-bottom .sp-module .sp-module-title {
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 30px;
    color: #fff
}

#sp-bottom .sp-module ul {
    list-style: none;
    padding: 0;
    margin: 0
}

#sp-bottom .sp-module ul>li {
    display: block;
    margin-bottom: 15px
}

#sp-bottom .sp-module ul>li:last-child {
    margin-bottom: 0
}

#sp-bottom .sp-module ul>li>a {
    display: block
}

#sp-footer {
    font-size: 14px;
    padding: 0
}

#sp-footer .container-inner {
    padding: 30px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1)
}

#sp-footer ul.menu {
    display: inline-block;
    list-style: none;
    padding: 0;
    margin: 0 -10px
}

#sp-footer ul.menu li {
    display: inline-block;
    margin: 0 10px
}

#sp-footer ul.menu li a {
    display: block
}

#sp-footer ul.menu li a:hover {
    background: none
}

#sp-footer #sp-footer2 {
    text-align: right
}

html.coming-soon {
    width: 100%;
    height: 100%;
    min-height: 100%
}

html.coming-soon body {
    width: 100%;
    height: 100%;
    min-height: 100%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: 50% 40%
}

html.coming-soon .coming-soon-logo {
    display: inline-block;
    margin-bottom: 2rem
}

html.coming-soon #coming-soon-countdown {
    margin-top: 4rem
}

html.coming-soon #coming-soon-countdown .coming-soon-days,html.coming-soon #coming-soon-countdown .coming-soon-hours,html.coming-soon #coming-soon-countdown .coming-soon-minutes,html.coming-soon #coming-soon-countdown .coming-soon-seconds {
    margin: 0;
    float: left;
    width: 25%
}

html.coming-soon #coming-soon-countdown .coming-soon-days .coming-soon-number,html.coming-soon #coming-soon-countdown .coming-soon-hours .coming-soon-number,html.coming-soon #coming-soon-countdown .coming-soon-minutes .coming-soon-number,html.coming-soon #coming-soon-countdown .coming-soon-seconds .coming-soon-number {
    width: 100%;
    font-size: 4.5rem;
    letter-spacing: -1px;
    line-height: 1
}

html.coming-soon #coming-soon-countdown .coming-soon-days .coming-soon-string,html.coming-soon #coming-soon-countdown .coming-soon-hours .coming-soon-string,html.coming-soon #coming-soon-countdown .coming-soon-minutes .coming-soon-string,html.coming-soon #coming-soon-countdown .coming-soon-seconds .coming-soon-string {
    font-size: 1.5rem;
    letter-spacing: -1px;
    display: block;
    text-transform: uppercase
}

html.coming-soon .coming-soon-position {
    margin-top: 2rem
}

html.coming-soon .social-icons {
    margin-top: 4rem
}

html.coming-soon .coming-soon-login form {
    display: inline-block
}

html.error-page {
    width: 100%;
    height: 100%
}

html.error-page body {
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: 50% 40%
}

html.error-page .error-logo {
    display: inline-block;
    margin-bottom: 2rem
}

html.error-page .error-code {
    margin: 0 0 1rem;
    font-size: 6rem;
    line-break: 1
}

html.error-page .error-message {
    font-size: 1.5rem;
    margin-bottom: 3rem
}

.sp-social-share ul {
    display: block;
    padding: 0;
    margin: 20px -5px 0
}

.sp-social-share ul li {
    display: inline-block;
    font-size: 24px;
    margin: 0 5px
}

.profile>div:not(:last-child) {
    margin-bottom: 30px
}

.dl-horizontal dt {
    margin: 8px 0;
    text-align: left
}

.page-header {
    padding-bottom: 15px
}

table.category {
    width: 100%
}

table.category thead>tr,table.category tbody>tr {
    border: 1px solid #f2f2f2
}

table.category thead>tr th,table.category thead>tr td,table.category tbody>tr th,table.category tbody>tr td {
    padding: 10px
}

.contact-form .form-actions {
    background: none;
    border: none
}

@media print {
    .visible-print {
        display: inherit !important
    }

    .hidden-print {
        display: none !important
    }
}

.sp-preloader {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999
}

.sp-preloader>div {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    margin-left: -25px;
    position: absolute;
    animation: spSpin 0.75s linear infinite
}

.sp-preloader>div:after {
    content: "";
    position: absolute;
    width: 49px;
    height: 49px;
    top: 1px;
    right: 0;
    left: 0;
    margin: auto;
    border-radius: 50%
}

.sp-scroll-up {
    display: none;
    position: fixed;
    bottom: 60px;
    right: 30px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    background: rgba(100, 100, 100, 0.4);
    border-radius: 100%;
    z-index: 1000;
    transition: all 0.3s ease
}

.sp-scroll-up:hover,.sp-scroll-up:active,.sp-scroll-up:focus {
    color: #fff;
    background: #000
}

.control-group {
    margin-bottom: 1rem
}

select.form-control:not([multiple]),select.inputbox:not([multiple]),select:not([multiple]) {
    height: calc(2.25rem + 2px)
}

.form-control-feedback {
    display: block;
    margin-top: 0.25rem;
    font-size: 0.875em
}

input[type="text"],textarea {
    outline: none;
    box-shadow: none !important
}

input[type="text"]:not(.form-control),input[type="email"]:not(.form-control),input[type="url"]:not(.form-control),input[type="date"]:not(.form-control),input[type="password"]:not(.form-control),input[type="search"]:not(.form-control),input[type="tel"]:not(.form-control),input[type="number"]:not(.form-control),select:not(.form-select):not(.form-control) {
    display: block;
    width: 100%;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
}

input[type="text"]:not(.form-control):focus,input[type="email"]:not(.form-control):focus,input[type="url"]:not(.form-control):focus,input[type="date"]:not(.form-control):focus,input[type="password"]:not(.form-control):focus,input[type="search"]:not(.form-control):focus,input[type="tel"]:not(.form-control):focus,input[type="number"]:not(.form-control):focus,select:not(.form-select):not(.form-control):focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0
}

.password-group meter {
    width: 100%
}

.chzn-select,.chzn-select-deselect {
    width: 100%
}

.chzn-container.chzn-container-single .chzn-single {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    height: calc(2.25rem + 2px);
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    box-shadow: none
}

.chzn-container.chzn-container-single .chzn-single div {
    width: 30px;
    background: none
}

.chzn-container.chzn-container-single .chzn-single div>b {
    background: none;
    position: relative;
    font: normal normal normal 14px/1 FontAwesome
}

.chzn-container.chzn-container-single .chzn-single div>b:after {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 0.875rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%)
}

.chzn-container.chzn-container-single.chzn-container-active.chzn-with-drop .chzn-single {
    border-radius: 0.25rem 0.25rem 0 0
}

.chzn-container.chzn-container-single .chzn-search {
    padding: 0.625rem
}

.chzn-container.chzn-container-single .chzn-search input[type="text"] {
    display: block;
    width: 100%;
    padding: 0.5rem 1.429rem 0.5rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.25;
    height: calc(2rem + 2px);
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    box-shadow: none;
    -webkit-box-shadow: none
}

.chzn-container.chzn-container-single .chzn-search input[type="text"]:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)
}

.chzn-container.chzn-container-single .chzn-search:after {
    content: "";
    font-family: FontAwesome;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%)
}

.chzn-container.chzn-container-single .chzn-drop {
    border-color: rgba(0, 0, 0, 0.15);
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: none;
    -webkit-box-shadow: none
}

.chzn-container.chzn-container-single .chzn-results li.highlighted {
    background-image: none !important
}

.chzn-container.chzn-container-multi .chzn-choices {
    display: block;
    width: 100%;
    padding: 0 0.75rem;
    min-height: calc(2.25rem + 2px);
    line-height: 1.25;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    box-shadow: none;
    -webkit-box-shadow: none
}

.chzn-container.chzn-container-multi .chzn-choices li.search-field input[type="text"] {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.25;
    height: calc(2rem + 2px);
    background-color: #fff
}

.chzn-container.chzn-container-multi .chzn-choices li.search-choice {
    margin: 5.5px 5px 0 0;
    padding: 5px 20px 5px 5px;
    background-image: none;
    background-color: #f5f5f5;
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
    -webkit-box-shadow: none
}

.chzn-container.chzn-container-multi .chzn-choices li.search-choice .search-choice-close {
    background: none;
    top: 5px;
    color: rgba(0, 0, 0, 0.2)
}

.chzn-container.chzn-container-multi .chzn-choices li.search-choice .search-choice-close:hover {
    color: rgba(0, 0, 0, 0.5)
}

.chzn-container.chzn-container-multi .chzn-choices li.search-choice .search-choice-close:after {
    content: "";
    font-family: FontAwesome;
    font-size: 0.875rem;
    position: absolute;
    top: 0;
    right: 0
}

.chzn-container.chzn-container-multi.chzn-with-drop.chzn-container-active .chzn-choices {
    border-radius: 0.25rem
}

.chzn-container.chzn-container-multi.chzn-with-drop .chzn-drop {
    margin-top: -4px;
    border: none;
    border-radius: 0;
    box-shadow: none;
    -webkit-box-shadow: none
}

.chzn-container.chzn-container-multi.chzn-with-drop .chzn-drop .chzn-results:not(:empty) {
    padding: 0.75rem;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-top: 0;
    border-radius: 0 0 0.25rem 0.25rem
}

.chzn-container.chzn-container-multi.chzn-with-drop .chzn-drop .chzn-results li.no-results {
    background: none
}

.form-horizontal .control-label {
    float: left;
    width: auto;
    padding-top: 5px;
    padding-right: 5px;
    text-align: left
}

.form-horizontal .controls {
    margin-left: 220px
}

.card-block {
    padding: 20px
}

.card-block ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block
}

.card-block ul li {
    display: block
}

.alert.alert-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}

.invalid {
    color: #a51f18;
    border-color: #a51f18
}

#sp-cookie-consent {
    position: fixed;
    font-size: 16px;
    font-weight: 500;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10001
}

#sp-cookie-consent>div {
    padding: 20px;
    position: relative
}

#attrib-helix_ultimate_blog_options #jform_attribs_helix_ultimate_article_format {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap
}

#attrib-helix_ultimate_blog_options #jform_attribs_helix_ultimate_article_format .form-check-inline {
    margin: 0;
    margin-right: -5px;
    padding-left: 0px
}

#attrib-helix_ultimate_blog_options #jform_attribs_helix_ultimate_article_format .form-check-inline label {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: #f3f3f3;
    color: #333;
    border-top: 1px solid #b3b3b3;
    border-right: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 0
}

#attrib-helix_ultimate_blog_options #jform_attribs_helix_ultimate_article_format .form-check-inline label.btn-success {
    background: #46a546;
    color: #fff
}

#attrib-helix_ultimate_blog_options #jform_attribs_helix_ultimate_article_format .form-check-inline label input[type="radio"] {
    display: none !important
}

#attrib-helix_ultimate_blog_options #jform_attribs_helix_ultimate_article_format .form-check-inline:last-child label {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

#attrib-helix_ultimate_blog_options #jform_attribs_helix_ultimate_article_format .form-check-inline:first-child label {
    border-left: 1px solid #b3b3b3;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

.com-contenthistory .btn-group {
    display: flex;
    justify-content: flex-end
}

.com-contenthistory .btn-group button {
    display: inline-block;
    padding: 4px 12px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #333;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    background-color: #f5f5f5;
    background-repeat: repeat-x;
    border: 1px solid #bbb;
    border-bottom-color: #a2a2a2;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)
}

.com-contenthistory .btn-group button:hover {
    color: #333;
    background-color: #e9e9e9
}

#versionsModal {
    top: 10%
}

#versionsModal .modal-dialog {
    max-width: 80%
}

#versionsModal iframe {
    min-height: 300px
}

body.com-content.view-form.layout-edit .nav-tabs .nav-link {
    display: block !important
}

.no-js img.lazyload {
    display: none
}

#system-message-container {
    position: fixed;
    bottom: 0px;
    right: 15px;
    max-width: 350px;
    z-index: 9999
}

#system-message-container .alert {
    font-size: 13px;
    line-height: 1.5
}

#system-message-container .alert>.btn-close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer
}

#system-message-container joomla-alert {
    font-size: 13px
}

.com-users.view-registration #member-registration .modal-dialog,.com-users.view-registration #member-profile .modal-dialog,.com-users.view-profile #member-registration .modal-dialog,.com-users.view-profile #member-profile .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.hu-media-modal .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.registration #member-registration #jform_privacyconsent_privacy-lbl {
    display: inline-flex
}

.registration #member-registration #jform_privacyconsent_privacy-lbl>a {
    position: initial;
    display: block;
    color: #252525
}

.registration #member-registration #jform_privacyconsent_privacy-lbl>a:hover,.registration #member-registration #jform_privacyconsent_privacy-lbl>a:focus {
    color: #044CD0
}

body.com-users.view-registration>#sbox-window {
    overflow: hidden
}

body.com-users.view-registration>#sbox-window>#sbox-btn-close {
    top: 5px;
    right: 5px
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset>.joomla-tabs {
    display: flex;
    padding: 0;
    margin: 0 !important;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    list-style: outside none none;
    background-color: #f5f5f5;
    border-color: #ccc #ccc currentcolor;
    border-style: solid solid none;
    border-width: 1px 1px 0;
    border-radius: 0.25rem 0.25rem 0 0;
    border-image: none;
    box-shadow: 0 1px #fff inset, 0 2px 3px -3px rgba(0, 0, 0, 0.15), 0 -4px 0 rgba(0, 0, 0, 0.05) inset, 0 0 3px rgba(0, 0, 0, 0.04)
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset>.joomla-tabs .nav-item>.nav-link {
    color: var(--text-color)
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset>.joomla-tabs .nav-item>.nav-link.active {
    background-color: rgba(0, 0, 0, 0.03);
    background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.05) 100%);
    border-right: 0 none;
    border-left: 0 none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 2px 0 1px -1px rgba(0, 0, 0, 0.08) inset, -2px 0 1px -1px rgba(0, 0, 0, 0.08) inset, 0 1px 0 rgba(0, 0, 0, 0.02) inset
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset>.tab-content {
    padding: 15px;
    background-color: #fefefe;
    border: 1px solid #ccc;
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.04)
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #editor label#jform_title-lbl {
    margin-bottom: 15px;
    font-weight: 700
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #editor .js-editor-tinymce {
    display: flex;
    flex-direction: column
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #publishing>.control-group,body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #metadata>.control-group,body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #attrib-helix_ultimate_blog_options>.control-group {
    display: flex;
    flex-direction: column
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #publishing>.control-group>label,body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #metadata>.control-group>label,body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #attrib-helix_ultimate_blog_options>.control-group>label {
    margin-bottom: 5px;
    font-weight: 700
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #publishing>.control-group textarea,body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #metadata>.control-group textarea,body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #attrib-helix_ultimate_blog_options>.control-group textarea {
    width: 100%
}

body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #publishing>.control-group .calendar-container .time td select,body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #metadata>.control-group .calendar-container .time td select,body.helix-ultimate.hu.view-form.layout-edit .edit.item-page>#adminForm>fieldset #attrib-helix_ultimate_blog_options>.control-group .calendar-container .time td select {
    padding: 4px;
    font-size: 13px
}

body.contentpane.com-media.view-images .container-popup #imageForm>#messages+.well .row-fluid {
    display: flex;
    max-width: 100%
}

body.contentpane.com-media.view-images .container-popup #imageForm>#messages+.well .row-fluid .span8 {
    flex: auto
}

body.contentpane.com-media.view-images .container-popup #imageForm>#messages+.well .row-fluid .span8>.controls {
    margin-left: 10px;
    display: flex;
    max-width: 500px
}

body.contentpane.com-media.view-images .container-popup #imageForm>#messages+.well .row-fluid .span8>.controls #folderlist_chzn,body.contentpane.com-media.view-images .container-popup #imageForm>#messages+.well .row-fluid .span8>.controls #folderlist {
    flex: auto
}

body.contentpane.com-media.view-images .container-popup #imageForm>#messages+.well .row-fluid .span8 #upbutton {
    background: var(--bs-primary);
    color: #fff;
    margin: 0 5px
}

body.contentpane.com-media.view-images .container-popup #imageForm .well>.row-fluid:not(:last-child) {
    margin-bottom: 5px
}

body.contentpane.com-media.view-images .container-popup #imageForm .well>.row-fluid:not(:last-child)>.control-group:not(:last-child) {
    margin-bottom: 5px
}

body.contentpane.com-media.view-images .container-popup #imageForm .btn.button-cancel {
    background: var(--bs-danger);
    color: #fff
}

body.contentpane.com-media.view-images .container-popup #imageForm .btn.button-cancel:hover,body.contentpane.com-media.view-images .container-popup #imageForm .btn.button-cancel:focus {
    border-color: var(--bs-danger)
}

body.contentpane.com-menus.view-items.layout-modal #adminForm .js-stools-container-bar {
    display: flex
}

body.contentpane.com-modules.view-modules.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar {
    display: flex;
    align-items: center;
    padding: 10px 0px
}

body.contentpane.com-modules.view-modules.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>label {
    margin-right: 10px
}

body.contentpane.com-modules.view-modules.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper.input-append {
    display: flex
}

body.contentpane.com-modules.view-modules.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper.input-append>button[type="submit"] {
    background: var(--bs-primary);
    color: #fff;
    margin-left: 5px
}

body.contentpane.com-modules.view-modules.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper>button[type="button"] {
    background: var(--bs-primary);
    color: #fff
}

body.contentpane.com-content.view-articles.layout-modal .container-popup #adminForm>.js-stools,body.contentpane.com-menus.view-items.layout-modal .container-popup #adminForm>.js-stools,body.contentpane.com-contact.view-contacts.layout-modal .container-popup #adminForm>.js-stools {
    padding-top: 15px
}

body.contentpane.com-content.view-articles.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar,body.contentpane.com-menus.view-items.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar,body.contentpane.com-contact.view-contacts.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar {
    margin-bottom: 10px
}

body.contentpane.com-content.view-articles.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>label,body.contentpane.com-menus.view-items.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>label,body.contentpane.com-contact.view-contacts.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>label {
    margin-right: 10px
}

body.contentpane.com-content.view-articles.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper.input-append,body.contentpane.com-menus.view-items.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper.input-append,body.contentpane.com-contact.view-contacts.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper.input-append {
    display: flex
}

body.contentpane.com-content.view-articles.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper.input-append>button[type="submit"],body.contentpane.com-menus.view-items.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper.input-append>button[type="submit"],body.contentpane.com-contact.view-contacts.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper.input-append>button[type="submit"] {
    background: var(--bs-primary);
    color: #fff;
    margin-left: 5px
}

body.contentpane.com-content.view-articles.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper>button[type="button"],body.contentpane.com-menus.view-items.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper>button[type="button"],body.contentpane.com-contact.view-contacts.layout-modal .container-popup #adminForm>.js-stools .js-stools-container-bar>.btn-wrapper>button[type="button"] {
    background: var(--bs-primary);
    color: #fff
}

body.contentpane.com-content.view-articles.layout-modal .container-popup #adminForm .js-stools-container-bar,body.contentpane.com-contact.view-contacts.layout-modal .container-popup #adminForm .js-stools-container-bar {
    display: flex;
    align-items: center
}

.modal-dialog.jviewport-width80 {
    width: 80vw;
    max-width: none
}

.btn:focus {
    box-shadow: none !important
}

.hu-media-modal .modal-content {
    height: 65vh
}

.input-group.hu-j4-media {
    max-width: 356px
}

.hidden {
    display: none !important
}

.field-calendar .input-group-text {
    padding: 0
}

.field-calendar .input-group-text button#jform_mycalendar_btn {
    background: transparent;
    border: none;
    color: #212529
}

.form-check-inline label.form-check-label.btn {
    padding: 0.375rem 3px
}

.modal-body .iframe {
    min-height: 60vh !important
}

#sbox-content iframe {
    width: 780px;
    height: 480px
}

.com-media.contentpane {
    padding: 10px !important
}

.com-media .well {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 10px
}

.com-media .well .row {
    margin: 0;
    padding: 0
}

.com-media .well .row>div:first-child {
    width: calc(100% - 200px)
}

.com-media .well .row>div:last-child {
    width: 200px;
    float: right;
    text-align: right;
    padding-top: 30px
}

.com-media .well .row #folderlist {
    display: inline-block
}

.com-media .well .row #upbutton {
    display: none
}

.com-media.view-imagesList {
    padding: 0px 0px 10px 0 !important
}

.com-media.view-imagesList:before {
    content: " ";
    display: table
}

.com-media.view-imagesList:after {
    display: block;
    clear: both;
    content: ""
}

.com-media.view-imagesList ul.manager {
    list-style: none;
    padding: 0;
    margin: 0
}

.com-media.view-imagesList ul.manager>li.thumbnail {
    width: 110px;
    float: left
}

/* .com-media.view-imagesList ul.manager>li.thumbnail>a {
} */

.com-media.view-imagesList ul.manager>li.thumbnail>a.selected {
    border: 2px solid green;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

.com-media.view-imagesList ul.manager>li.thumbnail>a>div:first-child {
    height: 60px;
    margin-bottom: 10px;
    text-align: center
}

.com-media.view-imagesList ul.manager>li.thumbnail>a>div:first-child>span {
    display: inline-block;
    width: 60px;
    height: 40px;
    margin: 0 auto;
    margin-top: 25px;
    position: relative;
    background-color: #92ceff;
    border-radius: 0 5px 5px 5px
}

.com-media.view-imagesList ul.manager>li.thumbnail>a>div:first-child>span:before {
    content: "";
    width: 50%;
    height: 12px;
    border-radius: 0 20px 0 0;
    background-color: #92ceff;
    position: absolute;
    top: -12px;
    left: 0px
}

.com-media.view-imagesList ul.manager>li.thumbnail>a>div:first-child img {
    display: inline-block
}

.sp-megamenu-parent {
    list-style: none;
    padding: 0;
    margin: 0 -15px;
    z-index: 99;
    display: block;
    float: right;
    position: relative
}

.sp-megamenu-parent .sp-menu-item>a img,.sp-megamenu-parent .sp-menu-item>span img {
    display: inline-block
}

.sp-megamenu-parent>li {
    display: inline-block;
    position: relative;
    padding: 0
}

.sp-megamenu-parent>li.menu-justify {
    position: static
}

.sp-megamenu-parent>li>a,.sp-megamenu-parent>li>span {
    display: inline-block;
    padding: 0 15px;
    line-height: 80px;
    font-size: 14px;
    margin: 0
}

.sp-megamenu-parent>li:last-child>a {
    padding: 0px 0px 0px 15px
}

.sp-megamenu-parent>li.active>a {
    font-weight: 500
}

.sp-megamenu-parent>li .sp-menu-badge {
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    font-size: 10px;
    letter-spacing: 1px;
    display: inline-block;
    text-transform: uppercase;
    background: #d60000;
    color: #fff;
    border-radius: 3px
}

.sp-megamenu-parent .sp-module {
    padding: 10px
}

.sp-megamenu-parent .sp-mega-group {
    list-style: none;
    padding: 0;
    margin: 0
}

.sp-megamenu-parent .sp-mega-group>li>a {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px
}

.sp-megamenu-parent .sp-mega-group .sp-mega-group-child {
    list-style: none;
    padding: 0;
    margin: 0
}

.sp-megamenu-parent .sp-dropdown {
    margin: 0;
    position: absolute;
    z-index: 10;
    display: none
}

.sp-megamenu-parent .sp-dropdown .sp-dropdown-inner {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    padding: 20px
}

.sp-megamenu-parent .sp-dropdown .sp-dropdown-inner>.row:not(:first-child) {
    margin-top: 20px
}

.sp-megamenu-parent .sp-dropdown .sp-dropdown-items {
    list-style: none;
    padding: 0;
    margin: 0
}

.sp-megamenu-parent .sp-dropdown.sp-dropdown-main {
    top: 100%
}

.sp-megamenu-parent .sp-dropdown.sp-dropdown-sub {
    top: 0
}

.sp-megamenu-parent .sp-dropdown.sp-dropdown-sub .sp-dropdown-inner {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2)
}

.sp-megamenu-parent .sp-dropdown li.sp-menu-item {
    display: block;
    padding: 0;
    position: relative
}

.sp-megamenu-parent .sp-dropdown li.sp-menu-item>a,.sp-megamenu-parent .sp-dropdown li.sp-menu-item span:not(.sp-menu-badge) {
    font-size: 14px;
    line-height: 1;
    display: inline-block;
    padding: 8px 0;
    cursor: pointer
}

.sp-megamenu-parent .sp-dropdown li.sp-menu-item>a.sp-group-title,.sp-megamenu-parent .sp-dropdown li.sp-menu-item span:not(.sp-menu-badge).sp-group-title {
    text-transform: uppercase;
    font-weight: bold
}

.sp-megamenu-parent .sp-dropdown .sp-module {
    padding: 0
}

.sp-megamenu-parent .sp-dropdown .sp-module .sp-module-title {
    font-size: 14px;
    margin: 0 0 15px;
    text-transform: uppercase;
    font-weight: bold
}

.sp-megamenu-parent .sp-dropdown .sp-module .latestnews {
    margin: 0
}

.sp-megamenu-parent .sp-dropdown .sp-module .latestnews>li:not(:last-child) {
    margin: 0 0 15px
}

.sp-megamenu-parent .sp-dropdown .sp-module .latestnews>li a {
    font-size: 14px
}

.sp-megamenu-parent .sp-dropdown .sp-module .latestnews>li a span {
    opacity: 0.8
}

.sp-megamenu-parent .sp-dropdown-mega>.row {
    margin-top: 30px
}

.sp-megamenu-parent .sp-dropdown-mega>.row:first-child {
    margin-top: 0
}

.sp-megamenu-parent .sp-dropdown-mega .sp-module {
    margin-left: 0px !important
}

.sp-megamenu-parent .sp-has-child:hover>.sp-dropdown {
    display: block
}

.sp-megamenu-parent.menu-animation-fade .sp-has-child:hover>.sp-dropdown {
    animation: spFadeIn 600ms
}

.sp-megamenu-parent.menu-animation-fade-up .sp-has-child:hover>.sp-dropdown {
    animation: spFadeInUp 400ms ease-in
}

.sp-megamenu-parent.menu-animation-fade-down .sp-has-child:hover>.sp-dropdown {
    animation: spFadeInDown 400ms ease-in
}

.sp-megamenu-parent.menu-animation-zoom .sp-has-child:hover>.sp-dropdown {
    animation: spZoomIn 300ms
}

.sp-megamenu-parent.menu-animation-rotate .sp-has-child:hover>.sp-dropdown {
    animation: spRotateIn 300ms
}

.sp-megamenu-parent.menu-animation-pulse .sp-has-child:hover>.sp-dropdown {
    animation: spPulse 300ms
}

.offcanvas-menu .hide-on-offcanvas {
    display: none !important
}

body.ltr .sp-megamenu-parent>li.sp-has-child>a:after,body.ltr .sp-megamenu-parent>li.sp-has-child>span:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    float: right;
    margin-left: 7px;
    font-weight: 900
}

body.ltr .sp-megamenu-parent>li .sp-menu-badge.sp-menu-badge-right {
    margin-left: 5px
}

body.ltr .sp-megamenu-parent>li .sp-menu-badge.sp-menu-badge-left {
    margin-right: 5px
}

body.ltr .sp-megamenu-parent .sp-dropdown .sp-dropdown-items .sp-has-child>a:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    float: right;
    margin-left: 7px;
    font-weight: 900
}

body.ltr .sp-megamenu-parent .sp-dropdown.sp-menu-center {
    margin-left: 45px
}

body.ltr .sp-megamenu-parent .sp-dropdown.sp-dropdown-main.sp-menu-right {
    left: 0
}

body.ltr .sp-megamenu-parent .sp-dropdown.sp-dropdown-main.sp-menu-full {
    left: auto;
    right: 0
}

body.ltr .sp-megamenu-parent .sp-dropdown.sp-dropdown-main.sp-menu-left {
    right: 0
}

body.ltr .sp-megamenu-parent .sp-dropdown.sp-dropdown-sub {
    left: 100%
}

@media (min-width: 576px) {
    .sp-menu-full.container {
        width:540px
    }
}

@media (min-width: 768px) {
    .sp-menu-full.container {
        width:720px
    }
}

@media (min-width: 992px) {
    .sp-menu-full.container {
        width:960px
    }
}

@media (min-width: 1200px) {
    .sp-menu-full.container {
        width:1140px
    }
}

@media (min-width: 1400px) {
    .sp-menu-full.container {
        width:1320px
    }
}

#offcanvas-toggler {
    display: inline-flex;
    align-items: center;
    height: 80px;
    line-height: 80px;
    font-size: 20px
}

#offcanvas-toggler>span:hover {
    color: #fff
}

.offcanvas-init {
    overflow-x: hidden;
    position: relative
}

.offcanvas-active .offcanvas-overlay {
    visibility: visible;
    opacity: 1
}

.offcanvas-overlay {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 9999;
    visibility: hidden;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px)
}

.body-wrapper {
    position: relative;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    backface-visibility: hidden
}

.close-offcanvas {
    position: absolute;
    top: 25px;
    z-index: 1
}

.offcanvas-menu {
    width: inherit;
    height: 100%;
    position: fixed;
    /* top: 60px; */
    overflow: inherit;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    z-index: 10000
}

.offcanvas-menu #offcanvas-toggler {
    display: none !important
}

.offcanvas-menu .offcanvas-inner {
    padding: 25px
}

.offcanvas-menu .offcanvas-inner .header-modules {
    margin-left: 0px
}

.offcanvas-menu .offcanvas-inner .finder {
    margin-bottom: 10px
}

.offcanvas-menu .offcanvas-inner .sp-sign-in {
    position: relative;
    top: 10px
}

.offcanvas-menu .offcanvas-inner .sp-sign-in .signin-text {
    display: none !important
}

.offcanvas-menu .offcanvas-inner .sp-profile-wrapper .user-text {
    display: none !important
}

.offcanvas-menu .offcanvas-inner .sp-contact-info,.offcanvas-menu .offcanvas-inner .social-icons {
    font-size: 14px
}

.offcanvas-menu .offcanvas-inner .sp-contact-info>li>a,.offcanvas-menu .offcanvas-inner .social-icons>li>a {
    opacity: 0.7
}

.offcanvas-menu .offcanvas-inner .sp-contact-info>li>a:hover,.offcanvas-menu .offcanvas-inner .sp-contact-info>li>a:focus,.offcanvas-menu .offcanvas-inner .social-icons>li>a:hover,.offcanvas-menu .offcanvas-inner .social-icons>li>a:focus {
    opacity: 1
}

.offcanvas-menu .offcanvas-inner .sp-contact-info {
    margin-top: 20px
}

.offcanvas-menu .offcanvas-inner .sp-contact-info>li:not(:last-child) {
    margin-bottom: 10px
}

.offcanvas-menu .offcanvas-inner .sp-module {
    padding: 0;
    margin: 0
}

.offcanvas-menu .offcanvas-inner .sp-module:not(:last-child) {
    margin-right: 20px
}

.offcanvas-menu .offcanvas-inner .sp-module .sp-module-title {
    font-size: 1rem;
    font-weight: bold
}

.offcanvas-menu .offcanvas-inner .sp-module:not(:last-child) {
    margin-bottom: 20px
}

.offcanvas-menu .offcanvas-inner .sp-module .awesomplete {
    width: 210px
}

.offcanvas-menu .offcanvas-inner ul.menu,.offcanvas-menu .offcanvas-inner ul.menu ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    margin: 0;
    margin: 0 0 15px 0;
    list-style: none
}

.offcanvas-menu .offcanvas-inner ul.menu>li {
    border: 0;
    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    display: block;
    font-weight: 600
}

.offcanvas-menu .offcanvas-inner ul.menu>li>a,.offcanvas-menu .offcanvas-inner ul.menu>li>span {
    display: block;
    font-size: 18px;
    padding: 10px 0px;
    position: relative;
    line-height: 18px;
    opacity: 0.7;
    transition: 0.3s
}

.offcanvas-menu .offcanvas-inner ul.menu>li>a:hover,.offcanvas-menu .offcanvas-inner ul.menu>li>span:hover {
    opacity: 1
}

.offcanvas-menu .offcanvas-inner ul.menu>li ul.menu-child {
    display: none
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>a>.menu-toggler,.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>.menu-separator>.menu-toggler {
    display: block;
    position: absolute;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    padding: 10px
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>a>.menu-toggler:after,.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>.menu-separator>.menu-toggler:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    font-weight: 900
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent li.menu-parent>a {
    position: relative;
    display: block
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent li.menu-parent .menu-toggler {
    right: -10px;
    display: block;
    position: absolute;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    padding: 2px 10px
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent li.menu-parent .menu-toggler:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    font-weight: 900
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent li.menu-parent.menu-parent-open .menu-toggler:after {
    content: ""
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent.menu-parent-open>a>.menu-toggler:after,.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent.menu-parent-open>.menu-separator>.menu-toggler:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    font-weight: 900
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent.menu-parent-open>.nav-header>.menu-toggler:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    font-weight: 900
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>.nav-header>.menu-toggler {
    display: block;
    position: absolute;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%)
}

.offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>.nav-header>.menu-toggler:after {
    font-family: "Font Awesome 5 Free";
    content: "";
    font-weight: 900
}

.offcanvas-menu .offcanvas-inner ul.menu>li>ul {
    display: none
}

.offcanvas-menu .offcanvas-inner ul.menu>li>ul li a {
    font-size: 15px;
    padding: 4px 0px;
    line-height: 18px;
    font-weight: 400
}

.offcanvas-menu.border-menu .offcanvas-inner ul.menu>li {
    border-bottom: 1px solid rgba(32, 31, 31, 0.1);
    margin-bottom: 0
}

.offcanvas-menu.border-menu .offcanvas-inner ul.menu>li>a {
    padding: 15px 30px 15px 10px
}

.offcanvas-menu.border-menu .offcanvas-inner ul.menu ul {
    padding-bottom: 15px
}

.offcanvas-menu.center-alignment .offcanvas-inner {
    padding: 0px 15px
}

body.ltr #offcanvas-toggler.offcanvas-toggler-right {
    float: right;
    margin-left: 20px
}

body.ltr #offcanvas-toggler.offcanvas-toggler-left {
    float: left;
    margin-right: 20px
}

body.ltr.offcanvs-position-left .body-wrapper {
    left: 0
}

body.ltr.offcanvs-position-left .offcanvas-menu {
    left: -300px;
    top: 0
}

body.ltr.offcanvs-position-left.offcanvas-active .offcanvas-menu {
    left: 0;
    top: 0
}

body.ltr.offcanvs-position-right .body-wrapper {
    right: 0
}

body.ltr.offcanvs-position-right .offcanvas-menu {
    right: -300px;
    top: 0
}

body.ltr.offcanvs-position-right.offcanvas-active .offcanvas-menu {
    right: 0;
    top: 0
}

body.ltr .close-offcanvas {
    right: 15px
}

body.ltr .offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>a>.menu-toggler,body.ltr .offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>.menu-separator>.menu-toggler {
    right: -10px
}

body.ltr .offcanvas-menu .offcanvas-inner ul.menu>li.menu-parent>.nav-header>.menu-toggler {
    right: 0
}

body.ltr .offcanvas-menu .offcanvas-inner ul.menu>li ul {
    margin-left: 10px
}

@keyframes spFadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes spFadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes spFadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px)
    }

    100% {
        opacity: 1;
        transform: translateY(0)
    }
}

@keyframes spZoomIn {
    0% {
        opacity: 0;
        transform: scale3d(0.3, 0.3, 0.3)
    }

    100% {
        opacity: 1
    }
}

@keyframes spRotateIn {
    from {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, -45deg);
        opacity: 0
    }

    to {
        transform-origin: center;
        transform: none;
        opacity: 1
    }
}

@keyframes spPulse {
    from {
        opacity: 0;
        transform: scale3d(1, 1, 1)
    }

    50% {
        opacity: 0.5;
        transform: scale3d(1.05, 1.05, 1.05)
    }

    to {
        opacity: 1;
        transform: scale3d(1, 1, 1)
    }
}

@keyframes spSpin {
    to {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes grdAiguille {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes grdAiguille {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes ptAiguille {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes ptAiguille {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader1 {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes loader1 {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader6 {
    0% {
        -webkit-transform: rotate(0deg)
    }

    50% {
        -webkit-transform: rotate(180deg)
    }

    100% {
        -webkit-transform: rotate(180deg)
    }
}

@keyframes loader6 {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(180deg)
    }

    100% {
        transform: rotate(180deg)
    }
}

@keyframes rotate-360 {
    from {
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    to {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes audioWave {
    25% {
        background: linear-gradient(#ec430f, #ec430f) 0 50%, linear-gradient(#ec430f, #ec430f) 0.625em 50%, linear-gradient(#ec430f, #ec430f) 1.25em 50%, linear-gradient(#ec430f, #ec430f) 1.875em 50%, linear-gradient(#ec430f, #ec430f) 2.5em 50%;
        background-repeat: no-repeat;
        background-size: 0.5em 2em, 0.5em 0.25em, 0.5em 0.25em, 0.5em 0.25em, 0.5em 0.25em
    }

    37.5% {
        background: linear-gradient(#ec430f, #ec430f) 0 50%, linear-gradient(#ec430f, #ec430f) 0.625em 50%, linear-gradient(#ec430f, #ec430f) 1.25em 50%, linear-gradient(#ec430f, #ec430f) 1.875em 50%, linear-gradient(#ec430f, #ec430f) 2.5em 50%;
        background-repeat: no-repeat;
        background-size: 0.5em 0.25em, 0.5em 2em, 0.5em 0.25em, 0.5em 0.25em, 0.5em 0.25em
    }

    50% {
        background: linear-gradient(#ec430f, #ec430f) 0 50%, linear-gradient(#ec430f, #ec430f) 0.625em 50%, linear-gradient(#ec430f, #ec430f) 1.25em 50%, linear-gradient(#ec430f, #ec430f) 1.875em 50%, linear-gradient(#ec430f, #ec430f) 2.5em 50%;
        background-repeat: no-repeat;
        background-size: 0.5em 0.25em, 0.5em 0.25em, 0.5em 2em, 0.5em 0.25em, 0.5em 0.25em
    }

    62.5% {
        background: linear-gradient(#ec430f, #ec430f) 0 50%, linear-gradient(#ec430f, #ec430f) 0.625em 50%, linear-gradient(#ec430f, #ec430f) 1.25em 50%, linear-gradient(#ec430f, #ec430f) 1.875em 50%, linear-gradient(#ec430f, #ec430f) 2.5em 50%;
        background-repeat: no-repeat;
        background-size: 0.5em 0.25em, 0.5em 0.25em, 0.5em 0.25em, 0.5em 2em, 0.5em 0.25em
    }

    75% {
        background: linear-gradient(#ec430f, #ec430f) 0 50%, linear-gradient(#ec430f, #ec430f) 0.625em 50%, linear-gradient(#ec430f, #ec430f) 1.25em 50%, linear-gradient(#ec430f, #ec430f) 1.875em 50%, linear-gradient(#ec430f, #ec430f) 2.5em 50%;
        background-repeat: no-repeat;
        background-size: 0.5em 0.25em, 0.5em 0.25em, 0.5em 0.25em, 0.5em 0.25em, 0.5em 2em
    }
}

@-webkit-keyframes effect-2 {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes effect-2 {
    from {
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes sequence1 {
    0% {
        height: 10px
    }

    50% {
        height: 50px
    }

    100% {
        height: 10px
    }
}

@keyframes sequence2 {
    0% {
        height: 20px
    }

    50% {
        height: 65px
    }

    100% {
        height: 20px
    }
}

@keyframes rot1 {
    100% {
        transform: skew(-10deg) translateX(50px) rotate(405deg)
    }
}

@-webkit-keyframes rot1 {
    100% {
        -webkit-transform: skew(-10deg) translateX(50px) rotate(405deg)
    }
}

@keyframes rot2 {
    100% {
        transform: skew(-10deg) rotate(525deg)
    }
}

@-webkit-keyframes rot2 {
    100% {
        -webkit-transform: skew(-10deg) rotate(525deg)
    }
}

@keyframes rot3 {
    100% {
        transform: skew(-10deg) translateX(20px) translateY(-50px) rotate(645deg)
    }
}

@-webkit-keyframes rot3 {
    100% {
        -webkit-transform: skew(-10deg) translateX(20px) translateY(-50px) rotate(645deg)
    }
}

@keyframes width {
    10% {
        width: 10%
    }

    20% {
        width: 20%
    }

    30% {
        width: 30%
    }

    40% {
        width: 40%
    }

    50% {
        width: 50%
    }

    60% {
        width: 60%
    }

    70% {
        width: 70%
    }

    80% {
        width: 80%
    }

    90% {
        width: 90%
    }

    100% {
        width: 100%
    }
}

.sp-pre-loader {
    background: #FFFFFF;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999
}

.sp-pre-loader .sp-loader-clock {
    border: 3px solid #ec430f;
    border-radius: 60px;
    bottom: 0;
    height: 80px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 80px
}

.sp-pre-loader .sp-loader-clock:after {
    content: "";
    position: absolute;
    background-color: #ec430f;
    top: 2px;
    left: 48%;
    height: 38px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 97%;
    transform-origin: 50% 97%;
    -webkit-animation: grdAiguille 2s linear infinite;
    animation: grdAiguille 2s linear infinite
}

.sp-pre-loader .sp-loader-clock:before {
    content: "";
    position: absolute;
    background-color: #ec430f;
    top: 6px;
    left: 48%;
    height: 35px;
    width: 4px;
    border-radius: 5px;
    -webkit-transform-origin: 50% 94%;
    transform-origin: 50% 94%;
    -webkit-animation: ptAiguille 12s linear infinite;
    animation: ptAiguille 12s linear infinite
}

.sp-pre-loader .sp-loader-circle {
    position: absolute;
    height: 80px;
    width: 80px;
    border-radius: 80px;
    border: 3px solid fade(#ec430f, 70%);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: loader1 3s linear infinite;
    animation: loader1 3s linear infinite
}

.sp-pre-loader .sp-loader-circle:after {
    content: "";
    position: absolute;
    top: -5px;
    left: 20px;
    width: 11px;
    height: 11px;
    border-radius: 10px;
    background-color: #ec430f
}

.sp-pre-loader .sp-loader-bubble-loop {
    position: absolute;
    width: 12px;
    height: 12px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 12px;
    background-color: #ec430f;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-animation: loader6 1s ease-in-out infinite;
    animation: loader6 1s ease-in-out infinite
}

.sp-pre-loader .sp-loader-bubble-loop:before {
    content: "";
    position: absolute;
    background-color: rgba(236, 67, 15, 0.5);
    top: 0px;
    left: -25px;
    height: 12px;
    width: 12px;
    border-radius: 12px
}

.sp-pre-loader .sp-loader-bubble-loop:after {
    content: "";
    position: absolute;
    background-color: rgba(236, 67, 15, 0.5);
    top: 0px;
    left: 25px;
    height: 12px;
    width: 12px;
    border-radius: 12px
}

.sp-pre-loader .circle-two {
    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px
}

.sp-pre-loader .circle-two>span,.sp-pre-loader .circle-two>span:before,.sp-pre-loader .circle-two>span:after {
    content: "";
    display: block;
    border-radius: 50%;
    border: 2px solid #ec430f;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.sp-pre-loader .circle-two>span {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-left-color: transparent;
    -webkit-animation: effect-2 2s infinite linear;
    -moz-animation: effect-2 2s infinite linear;
    -ms-animation: effect-2 2s infinite linear;
    -o-animation: effect-2 2s infinite linear;
    animation: effect-2 2s infinite linear
}

.sp-pre-loader .circle-two>span:before {
    width: 75%;
    height: 75%;
    border-right-color: transparent
}

.sp-pre-loader .circle-two>span:after {
    width: 50%;
    height: 50%;
    border-bottom-color: transparent
}

.sp-pre-loader .wave-two-wrap {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 50%;
    width: 90px
}

.sp-pre-loader .wave-two {
    margin: 0;
    list-style: none;
    width: 90px;
    position: relative;
    padding: 0;
    height: 10px
}

.sp-pre-loader .wave-two li {
    position: absolute;
    width: 2px;
    height: 0;
    background-color: #ec430f;
    bottom: 0
}

.sp-pre-loader .wave-two li:nth-child(1) {
    left: 0;
    -webkit-animation: sequence1 1s ease infinite 0;
    animation: sequence1 1s ease infinite 0
}

.sp-pre-loader .wave-two li:nth-child(2) {
    left: 15px;
    -webkit-animation: sequence2 1s ease infinite 0.1s;
    animation: sequence2 1s ease infinite 0.1s
}

.sp-pre-loader .wave-two li:nth-child(3) {
    left: 30px;
    -webkit-animation: sequence1 1s ease-in-out infinite 0.2s;
    animation: sequence1 1s ease-in-out infinite 0.2s
}

.sp-pre-loader .wave-two li:nth-child(4) {
    left: 45px;
    -webkit-animation: sequence2 1s ease-in infinite 0.3s;
    animation: sequence2 1s ease-in infinite 0.3s
}

.sp-pre-loader .wave-two li:nth-child(5) {
    left: 60px;
    -webkit-animation: sequence1 1s ease-in-out infinite 0.4s;
    animation: sequence1 1s ease-in-out infinite 0.4s
}

.sp-pre-loader .wave-two li:nth-child(6) {
    left: 75px;
    -webkit-animation: sequence2 1s ease infinite 0.5s;
    animation: sequence2 1s ease infinite 0.5s
}

.sp-pre-loader .sp-loader-audio-wave {
    width: 3em;
    height: 2em;
    background: linear-gradient(#ec430f, #ec430f) 0 50%, linear-gradient(#ec430f, #ec430f) 0.625em 50%, linear-gradient(#ec430f, #ec430f) 1.25em 50%, linear-gradient(#ec430f, #ec430f) 1.875em 50%, linear-gradient(#ec430f, #ec430f) 2.5em 50%;
    background-repeat: no-repeat;
    background-size: 0.5em 0.25em, 0.5em 0.25em, 0.5em 0.25em, 0.5em 0.25em, 0.5em 0.25em;
    animation: audioWave 1.5s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

.sp-pre-loader .sp-loader-with-logo {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center
}

.sp-pre-loader .sp-loader-with-logo .logo {
    display: inline-block;
    width: auto
}

.sp-pre-loader .sp-loader-with-logo .line {
    background: #ec430f;
    height: 5px;
    left: 0;
    position: absolute;
    top: 0
}

@media (max-width: 1199px) {
    #sp-header.full-header {
        padding-left:15px;
        padding-right: 15px
    }

    #sp-header.header-with-social #sp-menu .social-wrap {
        padding-left: 0px
    }

    .sp-megamenu-parent>li>a,.sp-megamenu-parent>li span {
        padding: 0 10px
    }
}

@media (min-width: 992px) {
    #sp-header.header-with-modal-menu #offcanvas-toggler.offcanvas {
        display:none !important
    }
}

@media (max-width: 991px) {
    :root {
        --header_height: 70px;
        /* $header_height_sm */
    }

    .sp-megamenu-parent>li>a,.sp-megamenu-parent>li>span {
        line-height: 60px
    }

    #sp-header {
        height: 60px
    }

    #sp-header .logo {
        height: 60px
    }

    #sp-header.header-with-modal-menu #offcanvas-toggler {
        display: flex !important
    }

    #sp-header.header-with-modal-menu #modal-menu-toggler {
        display: none
    }

    #sp-header.full-header-center #offcanvas-toggler.mega,#sp-header.header-with-modal-menu #offcanvas-toggler.mega,#sp-header.full-header-left #offcanvas-toggler.mega,#sp-header.lg-header #offcanvas-toggler.mega,#sp-header.header-with-social #offcanvas-toggler.mega {
        display: flex !important
    }

    #sp-header.lg-header #offcanvas-toggler.mega {
        display: none !important
    }

    #sp-header.lg-header .offcanvas-toggler-left.offcanvas {
        display: flex !important
    }

    #sp-header-topbar .container-inner>.row {
        min-height: 60px
    }

    #modal-menu {
        top: 60px
    }

    #offcanvas-toggler {
        height: 60px;
        line-height: 60px
    }

    #sp-logo.has-border>.sp-column {
        border-right: none;
        padding-right: 0px
    }

    #sp-menu .social-wrap:after,#sp-logo.has-border:after {
        display: none
    }

    #sp-logo.has-border {
        padding-right: 15px
    }

    #sp-menu .social-wrap {
        padding-left: 15px
    }

    #sp-header.header-with-modal-menu.center-layout .sp-contact-info,#sp-header.header-with-modal-menu.classic-layout .sp-contact-info {
        display: none
    }

    #sp-header-topbar {
        margin: 10px 0px
    }

    #sp-header-topbar .container-inner {
        padding-bottom: 10px
    }

    .hu-media-modal .modal-content {
        height: 80vh
    }
}

@media (max-width: 767px) {
    #sp-menu .social-wrap {
        padding-left:10px
    }

    #sp-menu .social-wrap:after {
        display: none
    }

    #sp-header .menu-wrap {
        display: none
    }

    .hu-media-modal .modal-content {
        height: 85vh
    }

    .modal-dialog.jviewport-width80 {
        width: auto
    }
}

@media (max-width: 575px) {
    :root {
        --header_height: 70px;
        /* $header_height_xs */
    }

    .sp-megamenu-parent>li>a,.sp-megamenu-parent>li>span {
        line-height: 60px
    }

    #sp-header {
        height: 60px
    }

    #sp-header .logo {
        height: 60px
    }

    #sp-header-topbar .container-inner>.row {
        min-height: 60px
    }

    #modal-menu {
        top: 60px
    }

    #offcanvas-toggler {
        height: 60px;
        line-height: 60px
    }

    #sp-header .sp-module {
        margin-left: 10px
    }

    #sp-header .sp-module .sp-sign-in .text {
        display: none
    }

    #sp-menu ul.social-icons>li:not(:last-child) {
        margin-right: 10px
    }

    #sp-header.lg-header .top-part {
        border-bottom: none
    }

    #sp-header.lg-header #sp-contact {
        order: 1
    }

    #sp-header.lg-header #sp-logo {
        order: 3
    }

    #sp-header.lg-header #sp-social {
        order: 2
    }

    #sp-header.lg-header #sp-social .sp-column {
        justify-content: center !important
    }

    #sp-header.lg-header #sp-social .social-icons {
        margin: 0
    }

    #sp-header.lg-header #sp-social .social-icons>li:not(:last-child) {
        margin-right: 10px
    }

    #sp-header.lg-header .sp-contact-info {
        text-align: center;
        margin: 5px 0
    }

    .header-has-modules .container-inner>.row {
        flex-wrap: wrap !important
    }

    .header-has-modules #offcanvas-toggler {
        height: auto;
        line-height: 1.2
    }

    html.coming-soon #coming-soon-countdown .coming-soon-days,html.coming-soon #coming-soon-countdown .coming-soon-hours,html.coming-soon #coming-soon-countdown .coming-soon-minutes,html.coming-soon #coming-soon-countdown .coming-soon-seconds {
        width: 50%;
        margin-bottom: 15px
    }

    html.coming-soon #coming-soon-countdown .coming-soon-days .coming-soon-number,html.coming-soon #coming-soon-countdown .coming-soon-hours .coming-soon-number,html.coming-soon #coming-soon-countdown .coming-soon-minutes .coming-soon-number,html.coming-soon #coming-soon-countdown .coming-soon-seconds .coming-soon-number {
        font-size: 2.5rem
    }

    html.coming-soon #coming-soon-countdown .coming-soon-days .coming-soon-string,html.coming-soon #coming-soon-countdown .coming-soon-hours .coming-soon-string,html.coming-soon #coming-soon-countdown .coming-soon-minutes .coming-soon-string,html.coming-soon #coming-soon-countdown .coming-soon-seconds .coming-soon-string {
        font-size: 14px
    }

    .view-search #search-form .btn-group {
        flex-wrap: wrap
    }

    .view-search #search-form button,.view-search #search-form .btn {
        border-radius: 3px
    }
}
